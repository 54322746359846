import { useIntercom } from 'react-use-intercom';
import AccButton, { Props } from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';

type ContactSupportButtonProps = Omit<Props, 'onClick'>;

const ContactSupportButton = (props: ContactSupportButtonProps) => {
  const { variant, ...rest } = props;
  const { hide, show, isOpen } = useIntercom();

  const onSupportClick = () => {
    isOpen ? hide() : show();
  };
  return (
    <AccButton variant={variant || 'primary'} {...rest} onClick={onSupportClick}>
      {isOpen ? t('Close support') : t('Contact support')}
    </AccButton>
  );
};

export default ContactSupportButton;
