import AccButton from 'Components/AccButton/AccButton';
import ConnectedLabel from 'Components/ConnectedLabel';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type Props = {
  onNextStep: (...args: Array<any>) => any;
  onClose: (...args: Array<any>) => any;
};

const AddedBigQueryAccount = ({ onNextStep, onClose }: Props) => {
  const { domainInfo } = useQueryDomainInfo();
  const isBackfilled = !!domainInfo?.isBigQueryBackfilled;
  return (
    <div>
      <ConnectedLabel
        isConnected={true}
        description={t('Your Google BigQuery account has been connected.')}
      />
      <AccText my="md" ml={30}>
        {isBackfilled
          ? t('You can now close this window. Data is backfilled.')
          : t('You can now close this window or go to next step to backfill data.')}
      </AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton variant="primary" onClick={onClose}>
            {t('Close')}
          </AccButton>
        }
        secondaryButtonSlot={
          !isBackfilled ? (
            <AccButton
              variant="secondary"
              onClick={onNextStep}
              trackingKey={TrackingKey.BigQueryBackfillOpenModal}
            >
              {t('Backfill data now')}
            </AccButton>
          ) : undefined
        }
      />
    </div>
  );
};

export default AddedBigQueryAccount;
