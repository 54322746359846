import { memo } from 'react';
import { Checkbox, Flex, Skeleton } from '@mantine/core';
import cn from 'classnames';
import { RowIndentations } from 'Components/Table/TreeTable';
import virtualizedTableStyles from 'Components/Table/VirtualizedTable/virtualizedTable.module.scss';
import { CellRendererProps } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import styles from 'Pages/Keywords/Groupings/ViewMode/components/cells/cells.module.scss';
import { getIndentClassNames } from 'Utilities/Table/Tree/treeUtils';
import { propsIsEqualComparison } from 'Utilities/compare';

export const CellSkeleton = () => {
  return (
    <Flex align="center" px="md">
      <Skeleton height={18} />
    </Flex>
  );
};

const RowLoadingCell = (props: CellRendererProps) => {
  const { rootNode, classes } = props;
  return (
    <Flex
      align="center"
      className={cn(
        classes.noWrap,
        styles.pathCell,
        virtualizedTableStyles.tableScrollShadowLeftItem,
      )}
    >
      <Checkbox size="16px" px={8} disabled={true} readOnly={true} />
      <RowIndentations
        depth={rootNode.deepness}
        expandIconWidth="24px"
        className={getIndentClassNames(rootNode, false)}
        hideConnectors={rootNode.hiddenConnectors}
      />
      <Skeleton height={18} mx="md" />
    </Flex>
  );
};

export default memo(RowLoadingCell, propsIsEqualComparison);
