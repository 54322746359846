import { useField } from 'react-final-form';
import { Box, Flex, Stack, Stepper } from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';
import moment from 'moment';
import AccButton from 'Components/AccButton/AccButton';
import { Form } from 'Components/Fields';
import { FormErrors } from 'Components/Forms/FormErrors';
import HelpGuideButton from 'Components/HelpGuideButton';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useLanguage, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import CalendarIcon from 'icons/calendar.svg?inline';

type FormValues = {
  fromDate: string;
};

type Props = {
  onSubmit: (values: FormValues) => any;
  onCancel: () => void;
  submitting?: boolean;
};

const BackfillInput = ({ maxDate, disabled }: { maxDate: Date; disabled?: boolean }) => {
  const { domainInfo } = useQueryDomainInfo();
  const min = domainInfo?.firstRefreshAt ? new Date(domainInfo.firstRefreshAt) : new Date(0);
  const language = useLanguage();
  const { input } = useField('fromDate', {
    initialValue: min,
  });

  const handleChange = (val: DateValue) => {
    input.onChange(val);
  };

  return (
    <Flex direction="column" rowGap="xs" align="flex-start">
      <Box pos="relative">
        <DatePicker
          size="sm"
          value={input.value}
          minDate={min}
          maxDate={maxDate}
          onChange={handleChange}
          locale={language || 'en'}
        />
        {disabled && (
          <Box
            p="xl"
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="white"
            opacity={0.4}
            style={{ cursor: 'not-allowed' }}
          />
        )}
      </Box>
    </Flex>
  );
};

const formatDate = (date: string | Date, fallback: string): string => {
  return date ? moment(date).format('YYYY-MM-DD') : fallback;
};

const BackfillData = ({ onSubmit, onCancel, submitting }: Props) => {
  const backFillMaxDate = moment().subtract(1, 'days').toDate();
  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      subscription={{ values: true, invalid: true, submitErrors: true }}
    >
      {({ invalid, submitErrors, values }) => (
        <Stack gap="lg">
          <FormErrors errors={submitErrors} />
          {tct(
            'Copy historic keyword data to BigQuery.[br][i:Note:] You can only backfill data once per domain.',
            {
              br: <br />,
              i: <i />,
            },
          )}
          <Stepper active={1} completedIcon={<CalendarIcon width={'16px'} height={'16px'} />}>
            <Stepper.Step
              label="From date"
              description={formatDate(values.fromDate, t('Select below'))}
              icon={<CalendarIcon width={'16px'} height={'16px'} />}
            >
              Start of historic rank data to backfill to BigQuery.
            </Stepper.Step>
            <Stepper.Step
              label="To date"
              description={formatDate(backFillMaxDate, t('Yesterday'))}
              icon={<CalendarIcon width={'16px'} height={'16px'} />}
            />
          </Stepper>
          <BackfillInput maxDate={backFillMaxDate} disabled={submitting} />
          <ModalFooter
            primaryButtonSlot={
              <AccButton
                type="submit"
                variant="primary"
                disabled={invalid || submitting}
                trackingKey={TrackingKey.BigQueryBackfillStart}
                loading={submitting}
              >
                {t('Start backfill')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={onCancel}>
                {t('Cancel')}
              </AccButton>
            }
            textSlot={
              <HelpGuideButton helpguideLink={'https://www.accuranker.com/help/integrations/'} />
            }
          />
        </Stack>
      )}
    </Form>
  );
};

export default BackfillData;
