import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import Loader from 'Components/Loader';
import { TreeTable } from 'Components/Table/TreeTable';
import { HeaderProps } from 'Components/Table/TreeTable/support/types';
import { STICKY_FILTER_HEIGHT } from 'Constants/sticky';
import { useOrderBy } from 'Hooks/useOrderBy';
import { useClearSelectedOnUnmount, useSelectedNodes } from 'Hooks/useSelectedNodes';
import styles from 'Pages/Keywords/Groupings/ViewMode/viewMode.module.scss';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import { MaxSelectableNodes } from '../support/const';
import { TreeViewEmptyContent } from './Components/TreeViewContent';
import TreeViewToolbar from './Components/TreeViewToolbar';
import { useFetchTreeView } from './support/useFetchTreeView';
import { TreeViewRootNode, useTreeViewCompareTableConfig } from './useTreeViewTableConfig';

const useHandleSetSelectedNodes = () => {
  const { setSelectedNodes } = useSelectedNodes();

  const handleSetSelectedNodes = useCallback(
    (node: TreeViewRootNode, _?: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent) => {
      setSelectedNodes((prevSelectedNodes: TreeViewRootNode[]) => {
        const nodeIndex = prevSelectedNodes.findIndex(({ path }) => path === node.path);
        if (nodeIndex !== -1) {
          return prevSelectedNodes.filter((_node, index) => index !== nodeIndex);
        }
        return [...prevSelectedNodes, node];
      });
    },
    [setSelectedNodes],
  );

  return { handleSetSelectedNodes };
};

const TreeView = observer(() => {
  const { orderBy, order, setOrderBy } = useOrderBy('group');
  const { handleSetSelectedNodes } = useHandleSetSelectedNodes();
  const { Header, RowTemplate, gridTableRows } = useTreeViewCompareTableConfig();
  const { data, loading, expandedNodes, setExpandedNodes } = useFetchTreeView(
    {
      orderBy,
      order,
    },
    'children_loading',
  );

  useClearSelectedOnUnmount();

  const headerProps = useMemo(
    () => ({
      orderBy,
      order,
      setOrderBy,
    }),
    [orderBy, order, setOrderBy],
  );

  const loadingElement = useMemo(
    () => (
      <Loader
        style={{
          height: '400px',
        }}
        loadingText={t('Loading Groups and Domains…')}
      />
    ),
    [],
  );
  return (
    <>
      <div className={reusableStyles.paper}>
        <TreeTable<TreeViewRootNode>
          emptyContentComponent={TreeViewEmptyContent}
          disableRowSelect
          data={data}
          saveExpandedNodes={setExpandedNodes}
          headerProps={headerProps}
          expandedNodes={expandedNodes}
          expandDataKey="path"
          searchKey="searchKey"
          childrenLoadingKey="children_loading"
          onNodeSelect={handleSetSelectedNodes}
          loading={loading}
          gridTableRows={gridTableRows}
          rowComponent={RowTemplate}
          headerComponent={Header as (props: HeaderProps) => JSX.Element}
          loadingElement={loadingElement}
          cellProps={{
            classes: {
              checkbox: styles.checkbox,
              noWrap: styles.noWrap,
              rightTextAlign: styles.rightTextAlign,
            },
          }}
          key={`treetable-${gridTableRows}`}
          topOffset={`${STICKY_FILTER_HEIGHT}px`}
          disableExpandCollapse={true}
          selectLimit={MaxSelectableNodes}
        />
      </div>
      <TreeViewToolbar />
    </>
  );
});

export default TreeView;
