import AccSegmentedControl, {
  AccSegmentedControlProps,
} from 'Components/AccSegmentedControl/AccSegmentedControl';
import { AccMultiSelect, AccSelect, AccSelectProps } from 'Components/AccSelect';
import { CountrySelectField } from 'Components/Controls/Dropdowns/CountrySelect';
import { LocaleSelectField } from 'Components/Controls/Dropdowns/LocaleSelect';
import { FolderInput } from 'Components/Controls/FolderInput';
import LocationInput from 'Components/Controls/LocationInput';
import PasswordInput, { PasswordProps } from 'Components/Controls/PasswordInput';
import { SearchEngineSettingsField } from 'Components/Controls/SearchEngineSettings';
import { SearchSettingsInput } from 'Components/Controls/SearchSettingsField';
import { SingleTagInput, TagsInput } from 'Components/Controls/TagsInput';
import TextArea from 'Components/Controls/TextArea';
import TextInput, { TextInputProps } from 'Components/Controls/TextInput';
import { DatePickerAdapter } from 'Components/Fields/Field/DatePicker';
import { FieldCheckbox } from 'Components/Fields/Field/FieldCheckbox';
import {
  FormComponentProps,
  withFieldForm,
} from 'Components/Fields/hooks/WithFieldForm/WithFieldForm';
import KeywordsInput from 'Components/Filters/Editors/KeywordsList/KeywordsInput';
import PhoneInput from 'Components/Forms/PhoneInput';
import SimpleSelect from 'Components/SimpleSelect/SimpleSelect';
import EmailsInput from 'Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/EmailsInput';
import FieldSwitch from './Field/FieldSwitch';
import ImagePicker from './Field/ImagePicker';
import JsonPicker from './Field/JsonPicker';
import { RadioGroup } from './Field/RadioGroup';
import { RichTextEditorAdapter, RichTextInputProps } from './LazyLoadedFields/RichTextEditor';

/**
 * Group of fields pre-integrated with react final form
 */
export const Field = {
  TextInput: withFieldForm<TextInputProps>(TextInput),
  Checkbox: FieldCheckbox,
  Password: withFieldForm<PasswordProps>(PasswordInput),
  TagsInput: withFieldForm(TagsInput),
  FolderInput: withFieldForm(FolderInput),
  PhoneInput: withFieldForm(PhoneInput),
  SingleTagInput: withFieldForm(SingleTagInput),
  EmailsInput: withFieldForm(EmailsInput),
  MultiSelect: withFieldForm(AccMultiSelect),
  DateInput: ({
    initialValue,
    ...props
  }: FormComponentProps & {
    initialValue?: Date;
    excludeDate?: (date: Date) => boolean;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
  }) => withFieldForm(DatePickerAdapter, { initialValue })(props),
  TextAreaInput: withFieldForm(TextArea),
  KeywordsInput: withFieldForm(KeywordsInput),
  Select: withFieldForm<AccSelectProps>(AccSelect),
  SelectValue: withFieldForm(AccSelect),
  SimpleSelect,
  ImagePicker,
  JsonPicker: withFieldForm(JsonPicker),
  LocaleSelect: withFieldForm(LocaleSelectField),
  CountrySelect: withFieldForm(CountrySelectField),
  SwitchInput: FieldSwitch,
  RadioGroup,
  SegmentedControl: withFieldForm<AccSegmentedControlProps>(AccSegmentedControl),
  RichTextEditor: withFieldForm<RichTextInputProps>(RichTextEditorAdapter),
  LocationField: withFieldForm(LocationInput),
  SearchSettingsField: withFieldForm(SearchSettingsInput),
  SearchEngineSettings: withFieldForm(SearchEngineSettingsField),
} as const;

export { Form } from './Form/Form';
export { Label } from './Label';
