import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import styles from './actionsCell.module.scss';

type ActionsCellBaseItem = {
  disabled?: boolean | string;
  label?: any;
  onSelect?: (...args: Array<any>) => any;
  className?: string;
};

export type ActionsCellItem = ActionsCellBaseItem & { fastIcon: JSX.Element };

type Props = {
  actions: Array<ActionsCellItem>;
  shouldUpdateIndicator?: any;
};

const ActionsCell = ({ actions }: Props) => (
  <div className={cn(styles.actionsCell, 'icon-button-wrapper', 'inline')}>
    {actions.map((action) => (
      <AccTooltip
        tooltip={typeof action.disabled === 'string' ? action.disabled : action.label}
        key={action.label}
        disable={!!action.disabled}
      >
        <button
          className={styles.iconButton}
          disabled={!!action.disabled || false}
          onClick={action.onSelect}
          aria-label={action.label}
        >
          {action.fastIcon}
        </button>
      </AccTooltip>
    ))}
  </div>
);

export default ActionsCell;
