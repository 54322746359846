import AccButton from 'Components/AccButton/AccButton';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

type Props = {
  goToFirstStep: (...args: Array<any>) => any;
  onClose: (...args: Array<any>) => any;
};

const FailedAddingBigQueryAccount = ({ goToFirstStep, onClose }: Props) => {
  return (
    <div>
      <AccText mb="md">{t('Failed to connect to Google BigQuery.')}</AccText>
      <AccText mb="md">{t('You can go to first step to try again or contact support.')}</AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton variant="primary" onClick={goToFirstStep}>
            {t('Go to first step')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={onClose}>
            {t('Cancel')}
          </AccButton>
        }
      />
    </div>
  );
};

export default FailedAddingBigQueryAccount;
