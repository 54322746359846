import { useDispatch } from 'react-redux';
import { Flex } from '@mantine/core';
import { deselectAllDomains } from 'Actions/DomainsPageActions';
import { ViewMode } from 'Components/ViewMode';
import { TableID } from 'Types/Table';

type Props = {
  tableName: TableID;
  isSimple: boolean;
};

const DomainsViewMode = ({ tableName, isSimple }: Props) => {
  const dispatch = useDispatch();
  const onChange = () => {
    dispatch(deselectAllDomains());
  };

  return (
    <Flex direction="column">
      <ViewMode tableName={tableName} simple={isSimple} onChange={onChange} />
    </Flex>
  );
};
export default DomainsViewMode;
