import { useCallback } from 'react';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useModal } from 'Hooks/base/useModal';
import useHasBigQueryFeature from 'Hooks/data/feature/useHasBigQueryFeature';
import {
  IntegrationOAuthProvider,
  IntegrationSimpleProvider,
  Integrations,
} from 'Types/Integration';
import { t } from 'Utilities/i18n/index';
import SelectProvider from './SelectProvider';

type AddAccountProps = {
  refresh?: () => void;
};

const options = [
  {
    provider: Integrations.GOOGLE_ACCOUNT,
    modal: 'ConnectToGA' as const,
  },
  {
    provider: Integrations.ADOBE,
    modal: 'ConnectToAdobe' as const,
  },
  {
    provider: Integrations.BIGQUERY,
    modal: 'ConnectToBigQuery' as const,
  },
  // {
  //   provider: Integrations.HUBSPOT,
  //   modal: 'ConnectToOAuth',
  // },
];

const AddAccount = ({ refresh }: AddAccountProps) => {
  const { showModal, hideModal } = useModal();
  const hasBigQueryFeature = useHasBigQueryFeature();
  const opts = hasBigQueryFeature
    ? options
    : options.filter((item) => item.provider !== Integrations.BIGQUERY);
  const handleSelect = useCallback(
    (provider: IntegrationOAuthProvider | IntegrationSimpleProvider) => {
      const option = opts.find((item) => item.provider === provider);
      showModal({
        modalType: option?.modal || 'ConnectToGA',
        modalTheme: 'light',
        modalProps: {
          refresh,
          integration: provider,
          isAdding: true,
        },
      });
    },
    [opts, refresh, showModal],
  );

  return (
    <ModalBorder title={t('Select Connection Provider')} onClose={hideModal}>
      <Flex gap={'xl'} align={'center'} justify={'center'}>
        <SelectProvider providers={opts.map((option) => option.provider)} onSelect={handleSelect} />
      </Flex>
      <ModalFooter
        primaryButtonSlot={
          <AccButton variant="tertiary" onClick={hideModal}>
            {t('Close this window')}
          </AccButton>
        }
      />
    </ModalBorder>
  );
};

export default AddAccount;
