import AccountWizard, { STEPS } from 'Components/Modal/Wizard/Base/Account';
import {
  useAddGoogleBigQueryAccountMutation,
  useConnectedToBigQueryQuery,
  useRemoveGoogleBigQueryAccountMutation,
  useStartGoogleBigqueryBackfillMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { formatDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { graphqlOK } from 'Utilities/underdash';
import ConnectedAccount from '../Common/ConnectedAccount';
import AddedBigQueryAccount from './AddedBigQueryAccount';
import BackfillData from './BackfillData';
import ConnectBigQuery from './ConnectBigQuery';
import FailedAddingBigQueryAccount from './FailedAddingBigQueryAccount';
import SelectBigQueryAccount from './SelectBigQueryAccount';

type Props = {
  domainId?: string;
  refresh?: (...args: Array<any>) => any;
  isBackfilling?: boolean;
};

const BACKFILL_STEP = 'backfillStep';
const FAILED_STEP = 'failedStep';
const ADDED_STEP = 'addedStep';

const ConnectToBigQuery = ({ domainId, refresh, isBackfilling }: Props) => {
  const { hideModal } = useModal();

  const { data: domainData } = useConnectedToBigQueryQuery({
    variables: {
      id: domainId!,
    },
    skip: !domainId,
  });

  const [removeAccount] = useRemoveGoogleBigQueryAccountMutation();
  const [addAccount, { loading: addingAccount }] = useAddGoogleBigQueryAccountMutation();
  const [backfillBigQuery, { loading: backfilling }] = useStartGoogleBigqueryBackfillMutation();

  const handleAddBigQueryAccount = (stepTo: (step: string) => void) => () => {
    if (domainId) {
      stepTo(STEPS.SELECT);
    } else {
      hideModal();
      refresh?.();
    }
  };

  const handleRemoveAccount = () => {
    if (!domainId) {
      return;
    }
    return removeAccount({
      variables: {
        input: {
          domainId,
        },
      },
    }).then(
      () => {
        toast.success(t('Account removed'));
        hideModal();
        refresh?.();
      },
      () => {
        toast.error(t('Failed to remove account'));
      },
    );
  };
  const handleAddAccount =
    (stepTo: (step: string) => void) =>
    ({ connectionId, datasetName }: { connectionId: string; datasetName: string }) => {
      if (!domainId) {
        return;
      }
      return addAccount({
        variables: {
          input: {
            domainId,
            connectionId,
            datasetName,
          },
        },
      }).then(
        () => {
          toast.success(t('Domain connected to Google BigQuery'));
          stepTo(ADDED_STEP);
          refresh?.();
        },
        () => {
          stepTo(FAILED_STEP);
          toast.error(t('Failed to connect to Google BigQuery.'));
        },
      );
    };
  const handleBackfillData = ({ fromDate }: { fromDate: string }) => {
    if (!domainId) {
      return;
    }
    const formattedFromDate = formatDate(new Date(fromDate));
    return backfillBigQuery({
      variables: {
        input: {
          domainId,
          fromDate: formattedFromDate,
        },
      },
    }).then(
      () => {
        toast.success(t('Started backfilling data from Google BigQuery'));
        hideModal();
        refresh?.();
      },
      () => {
        toast.error(t('Failed to start backfilling data from Google BigQuery'));
      },
    );
  };

  if (!graphqlOK({ domainData })) {
    return null;
  }

  const account =
    domainData && domainData.domain ? domainData.domain.googleBigqueryConnection : null;

  const getStep = () => {
    if (domainId) {
      if (isBackfilling) {
        return BACKFILL_STEP;
      }
      return STEPS.SELECT;
    }
    return STEPS.CONNECT;
  };

  return (
    <AccountWizard
      className="connect-to-adobe"
      step={getStep()}
      selectStep={{
        title: t('Select Google BigQuery Account'),
        component: ({ stepTo }) => (
          <SelectBigQueryAccount
            accountId={account?.id}
            onAdd={() => stepTo(STEPS.CONNECT)}
            onRemove={handleRemoveAccount}
            onSubmit={handleAddAccount(stepTo)}
            onCancel={hideModal}
            submitting={addingAccount}
          />
        ),
      }}
      connectStep={{
        title: t('Add BigQuery Connection'),
        component: ({ stepTo }) => (
          <ConnectBigQuery onSubmit={handleAddBigQueryAccount(stepTo)} onCancel={hideModal} />
        ),
      }}
      connectedStep={{
        title: t('Connected Google BigQuery Account'),
        component: () => (
          <ConnectedAccount
            onSubmit={hideModal}
            message={t('Your Google BigQuery account has been connected.')}
          />
        ),
      }}
      postSteps={[
        {
          name: FAILED_STEP,
          title: t('Failed to connect to Google BigQuery'),
          component: ({ stepTo }) => (
            <FailedAddingBigQueryAccount
              goToFirstStep={() => stepTo(STEPS.CONNECT)}
              onClose={hideModal}
            />
          ),
        },
        {
          name: ADDED_STEP,
          title: t('Connected domain with Google BigQuery account'),
          component: ({ stepTo }) => (
            <AddedBigQueryAccount onNextStep={() => stepTo(BACKFILL_STEP)} onClose={hideModal} />
          ),
        },
        {
          name: BACKFILL_STEP,
          title: t('Backfill data to Google BigQuery'),
          component: () => (
            <BackfillData
              onSubmit={handleBackfillData}
              onCancel={hideModal}
              submitting={backfilling}
            />
          ),
        },
      ]}
    />
  );
};

export default ConnectToBigQuery;
