import config from 'config';
import attempt from 'lodash/attempt';
import isArray from 'lodash/isArray';
import isError from 'lodash/isError';
import last from 'lodash/last';
import { SnakeCase } from 'Components/AccuTable/CellRenderer/helpers/serp-features';
import { decompressDecodeFiltersForUrl } from '../Components/Filters/serialization';

const typeStr = (val) => Object.prototype.toString.call(val).slice(8, -1).toLowerCase();
const stringToPascalCase = (val) =>
  val
    .replace(/^([a-z])/, (_, letter) => letter.toUpperCase())
    .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

export const graphqlError = (props, exceptions: any[] = []) => {
  let error = false;

  // eslint-disable-next-line no-unused-vars
  for (const prop in props) {
    // eslint-disable-next-line no-prototype-builtins
    if (props.hasOwnProperty(prop) && typeStr(props[prop]) === 'object') {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-prototype-builtins
      if (!~exceptions.indexOf(prop) && props[prop].hasOwnProperty('error') && props[prop].error) {
        error = true;
      }
    }
  }

  return error;
};
export const graphqlLoading = (props: any, exceptions: any[] = []) => {
  let loading = false;

  for (const prop in props) {
    // eslint-disable-next-line no-prototype-builtins
    if (props?.hasOwnProperty(prop) && typeStr(props[prop]) === 'object') {
      if (
        //TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !~exceptions.indexOf(prop) &&
        // eslint-disable-next-line no-prototype-builtins
        props[prop]?.hasOwnProperty('loading') &&
        props[prop].loading
      ) {
        loading = true;
      }
    }
  }

  return loading;
};
export const graphqlOK = (
  props: any,
  loadingExceptions: string[] = [],
  errorExceptions: string[] = [],
) => !graphqlLoading(props, loadingExceptions) && !graphqlError(props, errorExceptions);

// TODO we should avoid using non SPA redirects - bad UX.
// we use it only as hack to reload user session but since we can reload it without reloading page, we should remove it
// Proper ways:
// - in case we don't need reload user session - use useNavigate()
// - in case we need to reload user session - use same logic as in `onSuccessLogin` (initUser + navigate)
export const redirectToRoot = () => {
  window.location.href = `${config.basename}/`;
};

export const redirectToExternalUrl = (url = '/') => {
  if (url && url !== window.location.pathname) {
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location = `${config.baseUrl}${url}`;
  }
};
export const capitalizeFirstChar = (string) => string.charAt(0).toUpperCase() + string.slice(1);
//Encoding / Decoding stuff
const utf8ToBinaryString = (str) => encodeURIComponent(str);
const utf8ToBase64 = (str) => btoa(utf8ToBinaryString(str));
const binaryStringToUtf8 = (binstr) => decodeURIComponent(binstr);
const base64ToUtf8 = (b64) => binaryStringToUtf8(atob(b64));
const bufferToBinaryString = (buf) =>
  Array.prototype.map.call(buf, (ch) => String.fromCharCode(ch)).join('');
const bufferToBase64 = (arr) => btoa(bufferToBinaryString(arr));
const bufferToUtf8 = (buf) => binaryStringToUtf8(bufferToBinaryString(buf));
const binaryStringToBuffer = (binstr) => {
  let buf;

  if ('undefined' !== typeof Uint8Array) {
    buf = new Uint8Array(binstr.length);
  } else {
    buf = [];
  }

  Array.prototype.forEach.call(binstr, (ch, i) => {
    buf[i] = ch.charCodeAt(0);
  });
  return buf;
};
const utf8ToBuffer = (str) => binaryStringToBuffer(utf8ToBinaryString(str));
const base64ToBuffer = (base64) => binaryStringToBuffer(atob(base64));
const encodeBase64 = (toEncode) => bufferToBase64(utf8ToBuffer(toEncode));
export const decodeBase64 = (toDecode) => bufferToUtf8(base64ToBuffer(toDecode));
export const isValidJSON = (toCheck) => {
  if (typeStr(toCheck) === 'string') return !isError(attempt(JSON.parse, toCheck));
  return !isError(attempt(JSON.stringify, toCheck));
};

const base64IsValidJSON = (str) => {
  try {
    const decoded = decompressDecodeFiltersForUrl(str);
    return isValidJSON(decoded);
  } catch (err) {
    return false;
  }
};

export const downloadFile = (url: string, name?: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = name || last(url.split('/')) || '';
  a.target = '_self';
  a.click();
};
// Some of the links refer to internal resources and work in production since we have everything on same origin
// for development we need to add
export const formatInternalLink = (url: string) => {
  return url?.startsWith('/') ? config.baseUrl.concat(url) : url;
};

// compared to `singleQuotesStringsToArray` this function can throw error
// that we can handle in try/catch
export const parseSingleQuoteArray = <ReturnType = any>(str: string) => {
  const doubleQuoted = JSON.parse(str).replace(/'/g, '"');
  return JSON.parse(doubleQuoted) as ReturnType;
};

/**
 * @param obj: string
 * @returns {null|any}
 */
export const parseObjectStr = (obj) => {
  try {
    // single quote
    return JSON.parse(obj.replace(/'/g, '"'));
  } catch (e) {
    try {
      // double quote
      return JSON.parse(obj);
    } catch (err) {
      console.error('Failed to parse', err);
    }
  }

  return null;
};
export const toArray = (value) => (isArray(value) ? value : [value]);

export const toArrayOrEmpty = <T = unknown>(value: T): T[] | T => (value ? toArray(value) : value);
export const isMac = () => ~navigator.platform.toLowerCase().indexOf('mac');
export const removeInitialLoader = () => {
  const loaderEl = document.getElementById('start-loader');

  if (loaderEl) {
    loaderEl.innerHTML = '';
  }
};

// add this method as copy-to-clipboard cannot copy multiline properly
// see https://github.com/sudodoki/copy-to-clipboard/issues/56
export const copyToClipboard = (text?: string) => {
  // TODO FixTSignore
  if ((window as any).clipboardData?.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return (window as any).clipboardData?.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text ?? '';
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.

    document.body.appendChild(textarea);
    textarea.select();

    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

/**
 * Type predicate to filter out empty values from an array.
 * Source: {@link https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array StackOverflow}
 * @example
 * ```ts
 * const filteredArray: string[] = array.filter(notEmpty);
 * ```
 */
export const notEmpty = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;

export const camelToSnakeCase = <T extends string>(str: T) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) as SnakeCase<T>;

/**
 * Removes the trailing slash from a given URL if it exists.
 */
export const trimTrailingSlash = (string: string) => {
  if (string?.endsWith('/')) {
    // Sometimes the backend will send a trailing slash in the url, but not in the pathSegment.
    // so for now we just compare pathSegment to url without trailing slash when deleting filters.
    string = string?.slice(0, -1);
  }
  return string;
};

export default {
  notEmpty,
  camelToSnakeCase,
  typeStr,
  stringToPascalCase,
  graphqlError,
  graphqlLoading,
  redirectToExternalUrl,
  capitalizeFirstChar,
  utf8ToBinaryString,
  utf8ToBase64,
  binaryStringToUtf8,
  base64ToUtf8,
  bufferToBinaryString,
  bufferToBase64,
  bufferToUtf8,
  binaryStringToBuffer,
  utf8ToBuffer,
  base64ToBuffer,
  encodeBase64,
  decodeBase64,
  isValidJSON,
  base64IsValidJSON,
};
