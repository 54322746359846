import { useMantineTheme } from '@mantine/core';
import { IconDeviceDesktopAnalytics, IconListSearch, IconWorldPlus } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import LinkToDomain, { PageIdToLink } from 'Components/Filters/LinkToDomain';
import styles from 'Components/Filters/filters.module.scss';
import { ProcessedFolderTreeNode } from 'Pages/Domains/TreeView/support/types';
import { getDomainId } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { IconEdit, IconTrash } from 'icons/tag-cloud';
import { useGroupActions } from './useGroupActions';

type GroupActionsProps = {
  rootNode: ProcessedFolderTreeNode;
  showAction: boolean;
  isDemoDomain: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export const GroupActions = ({ rootNode, showAction, isDemoDomain }: GroupActionsProps) => {
  const theme = useMantineTheme();
  const iconColor = theme.colors.snorlax[4];
  const iconBlue = theme.colors.blue[4];
  const { handleAddDomain, handleEditGroup, handleDeleteGroup } = useGroupActions(rootNode);
  const domains = rootNode.children?.map((domainNode) => getDomainId(domainNode.path));
  if (!showAction) return null;

  const groupHasDomains = !!rootNode.children?.length;

  return (
    <>
      {groupHasDomains && (
        <LinkToDomain
          className={styles.link}
          domainId={domains}
          reset={false}
          navigateTo={PageIdToLink.overview}
        >
          <AccTooltip tooltip={t('Go to dashboard')}>
            <AccActionIcon variant="subtle" tabIndex={-1}>
              <IconDeviceDesktopAnalytics size={16} color={iconBlue} />
            </AccActionIcon>
          </AccTooltip>
        </LinkToDomain>
      )}
      {groupHasDomains && (
        <LinkToDomain
          className={styles.link}
          domainId={domains}
          reset={false}
          navigateTo={PageIdToLink.keywords}
        >
          <AccTooltip tooltip={t('Go to keywords list')}>
            <AccActionIcon variant="subtle" tabIndex={-1}>
              <IconListSearch size={16} color={iconBlue} />
            </AccActionIcon>
          </AccTooltip>
        </LinkToDomain>
      )}
      <AccessControl>
        {!isDemoDomain && (
          <>
            <AccTooltip tooltip={t('Create domain')} old>
              <AccActionIcon variant="subtle" onClick={handleAddDomain}>
                <IconWorldPlus size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>

            <AccTooltip tooltip={t('Edit group')} old>
              <AccActionIcon variant="subtle" onClick={handleEditGroup}>
                <AccFastIcon src={IconEdit} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>

            <AccTooltip tooltip={t('Delete group')} old>
              <AccActionIcon variant="subtle" onClick={handleDeleteGroup}>
                <AccFastIcon src={IconTrash} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
          </>
        )}
      </AccessControl>
    </>
  );
};
