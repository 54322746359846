import { memo, useState } from 'react';
import { Box, Center, Checkbox, Flex, Group, useMantineTheme } from '@mantine/core';
import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTag from 'Components/AccTag';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import DemoContentBadge from 'Components/DemoContent/DemoContentBadge';
import { DomainFavicon } from 'Components/DomainFavicon';
import LinkToDomain from 'Components/Filters/LinkToDomain';
import { RowIndentations } from 'Components/Table/TreeTable';
import virtualizedTableStyles from 'Components/Table/VirtualizedTable/virtualizedTable.module.scss';
import AccText from 'Components/Text/AccText';
import { useIsSelected } from 'Hooks/useSelectedNodes';
import { useTreeViewStore } from 'Pages/Domains/TreeView/support/treeViewStore';
import { CellRendererProps, TreeViewRootNode } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import styles from 'Pages/Keywords/Groupings/ViewMode/components/cells/cells.module.scss';
import { getIndentClassNames } from 'Utilities/Table/Tree/treeUtils';
import { propsIsEqualComparison } from 'Utilities/compare';
import { folderSeparator, getDomainId } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { IconChevronDown, IconChevronRight, IconFolder } from 'icons/tag-cloud';
import { DomainActions } from './DomainActions';
import { FolderActions } from './FolderActions';
import { GroupActions } from './GroupActions';
import { SERPAction } from './SERPAction';
import { TagActions } from './TagActions';

const ROW_INDENTATION_WIDTH = 24;

const IconAndPath = ({
  type,
  rootNode,
}: {
  type: 'group' | 'domain' | 'folder' | 'tag';
  rootNode: TreeViewRootNode;
}) => {
  const gray = useMantineTheme().other.iconInactive;
  switch (type) {
    case 'group':
      return (
        <>
          {'is_group' in rootNode && (
            <AccText size="sm" fw={400} overflowEllipsis>
              {rootNode.client}{' '}
              <AccText as="span" inline c={gray}>
                ({'children' in rootNode ? `${rootNode?.children.length}` : 0})
              </AccText>
            </AccText>
          )}
        </>
      );
    case 'domain':
      return (
        <>
          {'is_domain' in rootNode && (
            <Group
              gap={8}
              wrap="nowrap"
              mih={42}
              miw={0}
              styles={{ root: { alignItems: 'center' } }}
            >
              <DomainFavicon domain={rootNode.domain} size="lg" />
              <Flex wrap="nowrap" gap="xxs" direction="column" miw={0}>
                <LinkToDomain
                  domainId={getDomainId(rootNode.path)}
                  reset
                  className={styles.linkToDomain}
                >
                  {rootNode.display_name || rootNode.domain}
                </LinkToDomain>
                {rootNode.display_name && (
                  <AccText className={styles.domainText} size="sm" c="gray.5">
                    {rootNode.domain}&lrm;
                  </AccText>
                )}
              </Flex>
            </Group>
          )}
        </>
      );
    case 'folder':
      return (
        <>
          <AccFastIcon src={IconFolder} size={26} color={gray} />
          <AccText size="sm" fw={400} overflowEllipsis>
            {'name' in rootNode && rootNode.name}{' '}
            <AccText as="span" inline c={gray}>
              ({('n_children' in rootNode && rootNode.n_children) ?? 0})
            </AccText>
          </AccText>
        </>
      );
    case 'tag':
      return (
        <AccTag
          tag={'name' in rootNode ? rootNode.name : undefined}
          dynamic={'is_dynamic' in rootNode && rootNode.is_dynamic}
          maxWidth="100%"
        />
      );
    default:
      return null;
  }
};

const PathCell = (props: CellRendererProps) => {
  const { handleExpandDomain } = useTreeViewStore();
  const [serpCellOpened, setSerpCellOpened] = useState(false);

  const {
    rootNode,
    isOpen,
    onToggle,
    classes,
    isHovered: hovered,
    isSelectLimitReached,
    selectLimit,
  } = props;

  const isGroup = 'is_group' in rootNode && !!rootNode.is_group;

  const DEMO_DOMAINS = ['467170', '467175', '467176'];

  const isDemoDomain = isGroup
    ? !!rootNode.is_demo_domain
    : DEMO_DOMAINS.includes(getDomainId(rootNode.path));

  const showActions = hovered || serpCellOpened;
  const isDomain = 'is_domain' in rootNode && !!rootNode.is_domain;
  const isFolder = 'is_folder' in rootNode && !!rootNode.is_folder;
  const isTag = !isFolder && !isDomain && !isGroup;
  const type = isGroup ? 'group' : isDomain ? 'domain' : isFolder ? 'folder' : 'tag';

  const isSelected = useIsSelected(rootNode[props.expandDataKey], props.expandDataKey);
  const indentClassNames = getIndentClassNames(rootNode, isOpen);
  const hasCollapse =
    (isGroup && !!rootNode.children.length) ||
    isFolder ||
    (isDomain && 'has_tags' in rootNode && !!rootNode.has_tags);

  const selectDisabledMaxReached = isSelectLimitReached && !isSelected;
  const isEmptyGroup = !hasCollapse && isGroup;
  return (
    <Flex
      className={cn(
        virtualizedTableStyles.tableScrollShadowLeftItem,
        classes.noWrap,
        styles.pathCell,
        {
          [styles.clickable]: hasCollapse,
        },
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (!hasCollapse) {
          return;
        }
        if (isGroup || isDomain || isFolder) {
          onToggle(rootNode);
          isDomain && handleExpandDomain(rootNode.id.split(folderSeparator)?.[1]);
        }
      }}
    >
      <AccTooltip
        tooltip={t('Can not select more than %s items.', selectLimit)}
        disable={!selectDisabledMaxReached}
      >
        <Checkbox
          px={8}
          disabled={selectDisabledMaxReached}
          checked={isSelected}
          key={isSelected?.toString()}
          onChange={(event) => props.updateSelectedNode(props.rootNode, event)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </AccTooltip>
      <RowIndentations
        depth={rootNode.deepness}
        expandIconWidth={`${ROW_INDENTATION_WIDTH}px`}
        className={indentClassNames}
        hideConnectors={rootNode.hiddenConnectors}
        addEmptyConnector={!hasCollapse}
      />
      {hasCollapse && (
        <AccActionIcon c="gray.9" size="md">
          <AccFastIcon
            src={isOpen ? IconChevronDown : IconChevronRight}
            size={18}
            color="currentColor"
          />
        </AccActionIcon>
      )}
      {isEmptyGroup && <Box w={ROW_INDENTATION_WIDTH} />}
      <Flex
        className={styles.iconAndPathContainer}
        align="center"
        gap={6}
        ml={isTag ? 12 : 8}
        mr="auto"
        data-testid={`table-row-name-${rootNode.path}`}
      >
        <IconAndPath type={type} rootNode={rootNode} />
      </Flex>

      <Center>
        {showActions && (
          <Flex key="cell-actions" align="center">
            <FolderActions rootNode={rootNode} showAction={isFolder} isDemoDomain={isDemoDomain} />
            <TagActions rootNode={rootNode} showAction={isTag} isDemoDomain={isDemoDomain} />
            <DomainActions rootNode={rootNode} showAction={isDomain} isDemoDomain={isDemoDomain} />
            <GroupActions rootNode={rootNode} showAction={isGroup} isDemoDomain={isDemoDomain} />
            <SERPAction
              isOpen={serpCellOpened}
              setIsOpen={setSerpCellOpened}
              path={rootNode.path}
            />
          </Flex>
        )}
        {isDemoDomain && <DemoContentBadge variant="treeview" type={type} />}
      </Center>
    </Flex>
  );
};

export default memo(PathCell, propsIsEqualComparison);
