import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { FormSubmitButton } from 'Components/Fields/Form/FormSubmitButton';
import {
  useHandleChangeLocale,
  useSubmitAddDomainForm,
} from 'Components/Modal/Content/BuildDomain/BuildDomainForm/utils/useAddDomainForm';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { SoMePlatform } from 'Components/SoMeFormFields/SocialMediaIcons';
import {
  useAddDomainForm_ClientsQuery,
  useAddDomainForm_CountrylocalesQuery,
  useGetDomainInfoQuery,
} from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useUser } from 'Hooks/data/user/useUser';
import { FilterAttribute } from 'Types/Filter';
import { transformLocalesData } from 'Utilities/Common/search-engines';
import { t, tct } from 'Utilities/i18n/index';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import { DomainFormProps } from '..';
import Skeleton from '../Skeleton';
import { AdvancedSettingsFields } from './components/AdvancedSettings/AdvancedSettingsFields';
import { ConnectionSettingsProps } from './components/AdvancedSettings/ConnectionSettings';
import DomainFormAlerts from './components/DomainFormAlerts';
import { LocationFieldSection } from './components/LocationFieldSection';
import { getDefaultDomainSettings } from './utils/getDefaultDomainSettings';
import { useUpdateDomain } from './utils/useUpdateDomain';

// eslint-disable-next-line import/no-unused-modules
export enum DOMAIN_MODE {
  default = 'default',
  youtube = 'youtube',
  youtubeVideo = 'youtubevideo',
}

const BuildDomainForm = (props: DomainFormProps) => {
  const { tableStore, selectedCountry, domainId, onClose } = props;

  const isEditMode = !!domainId;

  const [mode, setMode] = useState<DOMAIN_MODE>(DOMAIN_MODE.default);

  const user = useUser();
  const { refetch: refetchDomainQuery } = useQueryDomainInfo();
  const { data: countrylocalesData } = useAddDomainForm_CountrylocalesQuery();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const { data: domainData, refetch: refetchDomain } = useGetDomainInfoQuery({
    variables: {
      id: domainId?.toString() || '',
    },
    skip: !isEditMode,
    fetchPolicy: 'network-only',
  });

  // safe refetch, will not refetch if domainId is not defined
  const refetchDomainData = useCallback(() => {
    if (domainId) refetchDomain();
  }, [refetchDomain, domainId]);

  const domainInfo = domainData?.domain;

  const connectionSettingsProps = useMemo((): ConnectionSettingsProps | undefined => {
    if (!isEditMode) return undefined;
    return {
      onClose,
    };
  }, [onClose]);

  const [primarySearchEngine, setPrimarySearchEngine] = useState<string | undefined>(undefined);

  const defaultDomainSettings = getDefaultDomainSettings(domainData);

  const {
    data: clientsData,
    error: clientsError,
    loading: clientsLoading,
    refetch: refetchClients,
  } = useAddDomainForm_ClientsQuery({ fetchPolicy: 'network-only' });

  const handleSetMode = useCallback(
    (domain?: string) => {
      if (domain && user?.organization?.activePlan?.featureYoutube) {
        setMode(
          domain.includes('youtube.com/channel/') ||
            domain.includes('youtube.com/user/') ||
            domain.includes('youtube.com/@') ||
            domain.includes('youtube.com/c/')
            ? DOMAIN_MODE.youtube
            : domain.includes('youtube.com/watch')
            ? DOMAIN_MODE.youtubeVideo
            : DOMAIN_MODE.default,
        );
      } else {
        setMode(DOMAIN_MODE.default);
      }
    },
    [user],
  );

  const clients = clientsData?.clients;

  const domainsFilter = useSpecificFilter(FilterAttribute.CLIENTS);

  const groupFromFilters =
    domainsFilter && domainsFilter.value.length
      ? domainsFilter.value[0]
      : clients?.length
      ? clients.filter((client) => !client.isDemoClient)[0]?.id || ''
      : '';

  const groupFromGroupId = props.groupId ? props.groupId.toString() : undefined;

  const belongsToGroupInitial = groupFromGroupId || groupFromFilters;

  const showPaused =
    isEditMode &&
    user.organization &&
    user.organization.activePlan &&
    user.organization.activePlan.featureCanPause;

  const groupOptions = clients
    ?.filter((client) => !client?.isDemoClient)
    .map((client) => ({
      label: client.name,
      value: client.id,
    }));

  const handleChangeLocale = useHandleChangeLocale({ mode, countrylocalesData, user });

  const locales = countrylocalesData?.countrylocales?.length
    ? transformLocalesData(
        countrylocalesData?.countrylocales.filter(notEmpty),
        !!user?.organization?.activePlan?.featureBaidu,
        !!user?.organization?.activePlan?.featureYoutube,
        mode === DOMAIN_MODE.youtube,
      )
    : [];

  // use domainInfo data if object exists (edit domain),
  // otherwise use empty values (add new domain)
  const initialValues = {
    domain: props.domain || domainInfo?.domain || '',
    displayName: domainInfo?.displayName || '',
    defaultSearchLocales: (defaultDomainSettings as any) || [],
    belongsToGroup: domainInfo?.client?.id || belongsToGroupInitial,
    googleBusinessNameList: domainInfo?.googleBusinessNameList?.filter(notEmpty) || [],
    [SoMePlatform.X]: domainInfo?.twitterHandle || undefined,
    [SoMePlatform.FACEBOOK]: domainInfo?.facebookProfile || undefined,
    [SoMePlatform.LINKEDIN]: domainInfo?.linkedinProfile || undefined,
    [SoMePlatform.TIKTOK]: domainInfo?.tiktokProfile || undefined,
    [SoMePlatform.INSTAGRAM]: domainInfo?.instagramProfile || undefined,
    shareOfVoicePercentage: domainInfo?.shareOfVoicePercentage ?? false,
    includeSubdomains: domainInfo?.includeSubdomains ?? true,
    exactMatch: domainInfo?.exactMatch ?? false,
    primarySearchEngine,
  };

  useEffect(() => {
    const defaultPrimarySearchEngine = (defaultDomainSettings as any)?.find(
      (domain) => domain.primary === true,
    )?.countrylocale;
    if (defaultPrimarySearchEngine) {
      setPrimarySearchEngine(defaultPrimarySearchEngine);
    }
  }, [JSON.stringify(defaultDomainSettings)]);

  useEffect(() => {
    handleSetMode(initialValues.domain);
  }, [initialValues.domain, handleSetMode]);

  const handleRefetch = useCallback(() => {
    refetchClients();
    refetchDomainData();
    refetchDomainQuery();
  }, [refetchClients, refetchDomainData, refetchDomainQuery]);

  const handleAddSubmit = useSubmitAddDomainForm({
    primarySearchEngine,
    tableStore,
    selectedCountry,
  });

  const handleUpdateSubmit = useUpdateDomain({
    domainId: domainId?.toString(),
    primarySearchEngine,
    onClose,
    onRefresh: handleRefetch,
  });

  const { hideModal } = useModal();

  if (clientsLoading || clientsError) {
    return <Skeleton />;
  }

  return (
    <Form
      initialValues={initialValues}
      keepDirtyOnReinitialize
      validateOnBlur={false}
      onSubmit={(values) => (isEditMode ? handleUpdateSubmit(values) : handleAddSubmit(values))}
    >
      <Stack justify="flex-start" gap="xl">
        <DomainFormAlerts mode={mode} />
        <Form.Errors />
        <Field.TextInput
          customOnChange={(changeEvent) => handleSetMode(changeEvent.target.value)}
          required
          autoFocus
          name="domain"
          label={t('Domain or YouTube Channel URL')}
          placeholder={
            mode === DOMAIN_MODE.default
              ? t('example.com')
              : t('youtube.com/channel/UCv8uTVRcwNF1JkZ1Z0d7WUg')
          }
          validate={[Validator.required]}
          helpTextPopover={
            mode === DOMAIN_MODE.default
              ? t('Please enter a valid domain or URL, e.g. example.com or example.com/path.')
              : t('Enter a YouTube channel url e.g. youtube.com/channel/UCv8uTVRcwNF1JkZ1Z0d7WUg')
          }
        />
        <Field.TextInput
          name="displayName"
          label={t('Display Name')}
          id="displayName"
          placeholder={t('My Project Name')}
          helpTextPopover={tct('[bold] If set this will display instead of the [mode] name.', {
            bold: <b>{t('Optional.')}</b>,
            mode: mode === DOMAIN_MODE.default ? t('domain') : t('channel'),
          })}
        />
        <LocationFieldSection
          locales={locales}
          handleChangeLocale={handleChangeLocale}
          mode={mode}
          primarySearchEngine={primarySearchEngine}
          setPrimarySearchEngine={setPrimarySearchEngine}
        />
        <Field.Select
          label={t('Belongs to Group')}
          name="belongsToGroup"
          placeholder={t('Belongs to group')}
          validate={Validator.required}
          options={groupOptions}
          withinPortal={false}
          groupHidden
          clearable
          required
          size="md"
        />
        {mode === DOMAIN_MODE.default && (
          <div>
            <Field.MultiSelect
              name="googleBusinessNameList"
              label={t('Google Business Names')}
              placeholder={t('Google Business Names')}
              noResultsText={t('Enter name of the business and hit enter')}
              helpTextPopover={t(
                'For some local results Google does not include a link to the website. ' +
                  'To make sure we can still find the domain on the search result page, ' +
                  'please enter the exact names of the Google Business page here.',
              )}
              size="md"
            />
          </div>
        )}
        <AccButton
          onClick={() => setShowAdvancedSettings((setting) => !setting)}
          variant="secondary"
          rightSection={
            showAdvancedSettings ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />
          }
        >
          {showAdvancedSettings ? t('Hide advanced settings') : t('Show advanced settings')}
        </AccButton>
        <AdvancedSettingsFields
          isYoutubeMode={mode === DOMAIN_MODE.youtube}
          showAdvancedSettings={showAdvancedSettings}
          showPaused={!!showPaused}
          connectionSettingsProps={connectionSettingsProps}
        />
      </Stack>
      <ModalFooter
        primaryButtonSlot={<FormSubmitButton />}
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={hideModal}>
            {t('Cancel')}
          </AccButton>
        }
      />
    </Form>
  );
};

export default BuildDomainForm;
