import { memo } from 'react';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import isNil from 'lodash/isNil';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TABLE_ICON_SIZE } from 'Components/AccuTable/constants';
import FormatNumber from 'Components/FormatNumber/new';
import Icon from 'Components/Icon/new';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import IconMobile from 'icons/mobile.svg?inline';
import IconDesktop from 'icons/monitor.svg?inline';
import { BaseCellProps } from '../../utils/useFoldersTableConfig';

// eslint-disable-next-line import/no-unused-modules
export const DeviceCell = memo(<T extends BaseCellProps>(props: T) => {
  const theme = useMantineTheme();
  const gray = theme.colors.gray[theme.primaryShade as number];
  return (
    <Flex gap={4} justify="end">
      <Icon width={TABLE_ICON_SIZE} height={TABLE_ICON_SIZE} color={gray}>
        <AccTooltip tooltip={t('Mobile')}>
          <IconMobile tabIndex={-1} />
        </AccTooltip>
      </Icon>
      <Box
        miw={24}
        style={{ textAlign: isNil(props.rootNode.mobile_percentage) ? 'center' : 'end' }}
      >
        <FormatNumber value={props.rootNode.mobile_percentage} style="percent" />
      </Box>
      <div />
      <Icon width={TABLE_ICON_SIZE} height={TABLE_ICON_SIZE} color={gray}>
        <AccTooltip tooltip={t('Desktop')}>
          <IconDesktop tabIndex={-1} />
        </AccTooltip>
      </Icon>
      <Box
        miw={24}
        style={{ textAlign: isNil(props.rootNode.mobile_percentage) ? 'center' : 'end' }}
      >
        <FormatNumber
          value={
            !isNil(props.rootNode.mobile_percentage)
              ? 1 - props.rootNode.mobile_percentage
              : undefined
          }
          style="percent"
        />
      </Box>
    </Flex>
  );
}, propsIsEqualComparison);
DeviceCell.displayName = 'DeviceCell';
