import { useRef, useState } from 'react';
import { FileRejection } from 'react-dropzone-esm';
import { ActionIcon, Group, Text, useMantineTheme } from '@mantine/core';
import { FileWithPath, Dropzone as MantineDropzone } from '@mantine/dropzone';
import {
  IconFileImport,
  IconPencilPlus,
  IconPhoto,
  IconTrash,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import JsonFormatter from 'Components/JsonFormatter';
import AccTitle from 'Components/Title/AccTitle';
import { getIsValidFile, getIsValidSize } from 'Utilities/Common/files';
import { t } from 'Utilities/i18n/index';
import styles from './dropzone.module.scss';

const DEFAULT_FILE_SIZE = 1024 * 1024 * 2; // 2MB image

type DropzoneProps = {
  onDropFile: (acceptedFiles: FileWithPath[]) => void;
  acceptFiles: string[];
  onDeleteFile?: () => void;
  header?: string;
  description?: string;
  subDescription?: string;
  preview?: string;
  previewType?: 'image' | 'json';
  multiple?: boolean;
  invalidFileMessage?: string;
  fileSize?: number;
  activateOnClick?: boolean;
  icon?: 'image' | 'file';
  disabled?: boolean;
};

const Dropzone = (props: DropzoneProps) => {
  const {
    multiple = true,
    preview,
    header,
    description,
    subDescription,
    onDropFile,
    onDeleteFile,
    acceptFiles,
    invalidFileMessage,
    fileSize = DEFAULT_FILE_SIZE,
    activateOnClick: propsActivateOnClick = false,
    icon = 'image',
    previewType = 'image',
    disabled = false,
    ...rest
  } = props;

  const [typeError, setTypeError] = useState<string | null>(null);
  const [sizeError, setSizeError] = useState<string | null>(null);

  const openRef = useRef<() => void>(null);

  const handleReject = (files: FileRejection[]) => {
    const invalidFileSizeError = getIsValidSize(files[0]?.file, fileSize);
    if (invalidFileSizeError) {
      setTypeError(invalidFileSizeError);
    } else {
      setTypeError(null);
    }
    const invalidFileError = getIsValidFile(files[0]?.file, acceptFiles, invalidFileMessage);
    if (invalidFileError) {
      setSizeError(invalidFileError);
    } else {
      setSizeError(null);
    }
  };

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    setTypeError(null);
    setSizeError(null);
    onDropFile(acceptedFiles);
  };

  const theme = useMantineTheme();

  return (
    <div>
      <div className={styles.header}>
        <div className="form-label">{header}</div>
        {preview && !disabled && (
          <div className={styles.headerButtons}>
            <AccTooltip old tooltip="Change file">
              <ActionIcon onClick={() => openRef.current?.()} variant="transparent">
                <IconPencilPlus color={theme.colors.gray[3]} />
              </ActionIcon>
            </AccTooltip>
            <AccTooltip old tooltip="Remove file">
              <ActionIcon onClick={onDeleteFile} variant="transparent">
                <IconTrash color={theme.colors.gray[3]} />
              </ActionIcon>
            </AccTooltip>
          </div>
        )}
      </div>
      <MantineDropzone
        openRef={openRef}
        onDrop={handleDrop}
        onReject={handleReject}
        maxSize={fileSize}
        accept={acceptFiles}
        activateOnClick={propsActivateOnClick}
        multiple={multiple}
        className={styles.drop}
        disabled={disabled}
        {...rest}
        data-activate-on-click={propsActivateOnClick || null}
        data-has-preview={!!preview || null}
      >
        {!preview ? (
          <Group justify="center" gap="lg" style={{ minHeight: 220, flexDirection: 'column' }}>
            <MantineDropzone.Accept>
              <IconUpload size={50} stroke={1.5} color={'orange'} />
            </MantineDropzone.Accept>
            <MantineDropzone.Reject>
              <IconX size={50} stroke={1.5} color={'red'} />
            </MantineDropzone.Reject>
            <MantineDropzone.Idle>
              {icon === 'image' ? (
                <IconPhoto size={50} stroke={1.5} />
              ) : (
                <IconFileImport size={50} stroke={1.5} />
              )}
            </MantineDropzone.Idle>

            <div>
              <AccTitle type="h5" fw={400}>
                {description ?? t('Drag and drop file to upload')}
              </AccTitle>
              <Text size="sm" c="dimmed" inline mt={7}>
                {subDescription}
              </Text>
            </div>
            <AccButton
              onClick={(event) => {
                event.stopPropagation(); // don't propagate to MantineDropzone activateOnClick, to prevent file dialog opening twice
                if (!openRef.current) {
                  return;
                }
                openRef.current();
              }}
              variant="secondary"
              data-center-align={true}
            >
              {t('Select file')}
            </AccButton>
          </Group>
        ) : (
          <div>
            {previewType === 'image' ? (
              <img className={styles.preview} src={preview} alt={t('Preview of uploaded file')} />
            ) : (
              <JsonFormatter data={JSON.parse(preview)} />
            )}
          </div>
        )}
      </MantineDropzone>

      {typeError && (
        <AccAlert my="sm" severity="error">
          {typeError}
        </AccAlert>
      )}
      {sizeError && (
        <AccAlert my="sm" severity="error">
          {sizeError}
        </AccAlert>
      )}
    </div>
  );
};

export default Dropzone;
