import Skeleton from 'Components/Skeleton';
import './edit-note-form.scss';

const NoteFormSkeleton = () => (
  <div className="edit-note-form">
    <Skeleton
      className="indented-form-group form-group"
      linesConfig={[
        {
          type: 'text',
          options: {
            width: '20%',
          },
        },
        {
          type: 'input',
        },
        {
          type: 'text',
          options: {
            width: '20%',
          },
        },
        {
          type: 'input',
          options: {
            height: '80px',
          },
        },
        {
          type: 'text',
          options: {
            width: '70%',
          },
        },
        {
          type: 'text',
          options: {
            width: '50%',
          },
        },
      ]}
    />
    <Skeleton
      className="footer"
      linesConfig={[
        {
          type: 'button',
          options: {
            display: 'inline-block',
            width: '15%',
          },
        },
        {
          type: 'button',
          options: {
            display: 'inline-block',
            width: '15%',
            marginLeft: '0.5rem',
          },
        },
      ]}
    />
  </div>
);

export default NoteFormSkeleton;
