import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Flex } from '@mantine/core';
import { observer } from 'mobx-react';
import { useFolderStructureQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useOrderBy } from 'Hooks/useOrderBy';
import useUserPermission from 'Hooks/useUserPermission';
import EditMode from 'Pages/Keywords/Groupings/EditMode';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import TableViewMode from './ViewMode/ViewMode';
import { useGroupingStore } from './support/groupingsStore';

/**
 * Tag Cloud Page
 */
export const Groupings = observer(() => {
  const { state: locationState } = useLocation();
  // open groupings page in edit mode if we come from the home dashboard tree view
  const [editMode, setEditMode] = useState(!!locationState?.editMode);
  const [key, setKey] = useState(0);
  const { orderBy, order, setOrderBy } = useOrderBy('keywords');
  const incrementKey = () => setKey(key + 1);
  const { userHasWriteAccess } = useUserPermission();

  // We store nodes in ref, to avoid re-renders with state change
  const expandedNodesRef = useRef<string[]>([]);
  const expandedNodes = expandedNodesRef.current;
  const setExpandedNodes = (nodes) => {
    expandedNodesRef.current = Array.isArray(nodes) ? nodes : [nodes].filter(Boolean);
  };

  const groupingStore = useGroupingStore();

  const filters = useFilters();

  const {
    data: nestedData,
    loading: loadingFolders,
    refetch: refetchNested,
  } = useFolderStructureQuery({
    variables: { filters, nested: true, orderBy, order },
    onCompleted: (data) => {
      incrementKey();
      // If there is only one folder, expand it
      if (data?.folderStructure?.folderStructure?.length === 1) {
        setExpandedNodes(data?.folderStructure?.folderStructure[0]?.name);
      }
    },
  });

  const {
    data: flatData,
    loading: loadingEditMode,
    refetch: refetchFlat,
  } = useFolderStructureQuery({
    variables: { filters, nested: false, orderBy, order },
    onCompleted: () => {
      incrementKey();
    },
  });

  const refetch = () => {
    refetchNested();
    refetchFlat();
  };

  useEffect(() => {
    refetch();
  }, [groupingStore.refetchListener]);

  return (
    <Flex direction="column">
      {userHasWriteAccess && editMode ? (
        <EditMode
          initialExpanded={expandedNodes}
          setInitialExpanded={setExpandedNodes}
          setEditMode={setEditMode}
          flatData={flatData?.folderStructure?.folderStructure ?? []}
          loading={loadingEditMode}
          refetch={refetch}
          key={key}
        />
      ) : (
        <TableViewMode
          setEditMode={setEditMode}
          saveExpandedNodes={setExpandedNodes}
          data={nestedData?.folderStructure?.folderStructure as FolderType[]}
          loading={loadingFolders || loadingEditMode}
          initialExpanded={expandedNodes}
          orderBy={orderBy}
          order={order}
          setOrderBy={setOrderBy}
          key={key}
        />
      )}
    </Flex>
  );
});
