import React, { useRef } from 'react';
import { Box, Flex } from '@mantine/core';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n/index';
import styles from './jsonFormatter.module.scss';

interface JsonFormatterProps {
  data: { [key: string]: any };
  indent?: number;
}

const JsonFormatter: React.FC<JsonFormatterProps> = ({ data, indent = 2 }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const ref = useRef<HTMLPreElement>(null);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (ref.current && !isCollapsed) {
      // reset scroll position
      ref.current.scrollTop = 0;
    }
  };

  const formattedJson = React.useMemo(() => {
    try {
      return JSON.stringify(data, null, indent);
    } catch (error) {
      return t('Invalid JSON data');
    }
  }, [data, indent]);

  return (
    <Flex
      direction="column"
      align="center"
      pt="xs"
      pb="xs"
      gap="xs"
      className={cn(styles.jsonFormatter, { [styles.jsonFormatterCollapsed]: isCollapsed })}
    >
      <Box
        ref={ref}
        maw="full"
        component="pre"
        px="md"
        pb="lg"
        className={cn(styles.jsonFormatterContent, {
          [styles.jsonFormatterContentCollapsed]: isCollapsed,
        })}
        mah="300px"
      >
        <code>{formattedJson}</code>
      </Box>
      <AccButton variant="tertiary" onClick={toggleCollapse}>
        {isCollapsed ? t('Expand') : t('Collapse')}
      </AccButton>
    </Flex>
  );
};

export default JsonFormatter;
