import { Flex } from '@mantine/core';
import {
  AnalyticsIntegrationButtons,
  BigQueryIntegrationButtons,
  SearchConsoleIntegrationButtons,
} from 'Components/IntegrationButtons';
import AccTitle from 'Components/Title/AccTitle';
import { useDomainId } from 'Hooks';
import { t } from 'Utilities/i18n';

export type ConnectionSettingsProps = {
  onClose?: () => void;
  isYoutubeMode?: boolean;
};

const ConnectionSettings = ({ onClose, isYoutubeMode }: ConnectionSettingsProps) => {
  const domainId = useDomainId();
  // if there is no domainId, then we cannot show the connection settings
  if (!domainId) return null;
  return (
    <div>
      <AccTitle type="h3">{t('Connections')}</AccTitle>
      <Flex gap="xs" wrap="wrap">
        {!isYoutubeMode && <AnalyticsIntegrationButtons refresh={onClose} />}
        {!isYoutubeMode && <SearchConsoleIntegrationButtons refresh={onClose} />}
        <BigQueryIntegrationButtons refresh={onClose} />
      </Flex>
    </div>
  );
};

export default ConnectionSettings;
