import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

type Props = {
  accountId: string;
  updateGoogleConnection: (...args: Array<any>) => any;
  googleConnections: Record<string, any>;
  handleSubmit: (...args: Array<any>) => any;
  submitting: boolean;
  refresh?: () => void;
  accountName: string;
};

const EditAccount = ({
  accountId,
  updateGoogleConnection,
  handleSubmit,
  submitting,
  refresh,
  googleConnections,
  accountName,
}: Props) => {
  const { hideModal } = useModal();

  const onSubmit = ({ description }) => {
    return updateGoogleConnection({
      variables: {
        input: {
          id: accountId,
          description,
          delete: false,
        },
      },
    })
      .then(
        ({
          data: {
            updateGoogleConnection: { errors },
          },
        }) => {
          if (errors && errors.length) {
            toast.error(t('Failed to update'));
            Validator.throwSubmissionError(errors);
          } else {
            console.log('refresh', refresh);
            hideModal();
            refresh?.();
            googleConnections?.refetch();
          }
        },
      )
      .catch(() => {
        toast.error(t('Failed to update'));
      });
  };

  return (
    <ModalBorder
      className="edit-account"
      onClose={submitting ? hideModal : undefined}
      title={t('Edit %s Account', accountName)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-label">{t('Description')}</div>
        <Field
          name="description"
          placeholder={t('Description')}
          component={TextField}
          disabled={submitting}
          validate={Validator.required}
          autoFocus={true}
        />
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" disabled={submitting} type="submit">
              {t('Edit connection')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" disabled={submitting} onClick={hideModal}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    </ModalBorder>
  );
};

const googleConnectionQuery = gql`
  query editAccount_googleConnections {
    user {
      id
      organization {
        id
        googleConnections {
          id
          description
        }
      }
    }
  }
`;
const updateGoogleConnectionQuery = gql`
  mutation editAccount_updateGoogleConnection($input: UpdateGoogleConnectionInput!) {
    updateGoogleConnection(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export default compose(
  graphql(googleConnectionQuery, {
    name: 'googleConnections',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({ ownProps, googleConnections }: any) => {
      const { accountId } = ownProps;
      let description = '';

      if (!googleConnections.loading && !googleConnections.error) {
        const connection =
          googleConnections.user &&
          googleConnections.user.organization.googleConnections.find(
            (conn) => conn.id === accountId,
          );
        description = connection ? connection.description : '';
      }
      return {
        ...ownProps,
        initialValues: {
          description,
        },
        googleConnections,
      };
    },
  }),
  graphql(updateGoogleConnectionQuery, {
    name: 'updateGoogleConnection',
  }),
)(
  reduxForm({
    form: 'EditAccountForm',
    enableReinitialize: true,
  })(EditAccount),
);
