import * as React from 'react';
import { useField } from 'react-final-form';
import type { FieldProps } from 'react-final-form';
import { PopoverProps, Transition } from '@mantine/core';
import cn from 'classnames';
import { Label } from 'Components/Fields/Label';
import * as helpers from './helpers';
import './form-field.scss';

export type FormComponentProps = {
  name: string;
  label?: React.ReactNode;
  className?: string;
  helpText?: string;
  helpTextPopover?: React.ReactNode;
  helpTextPopoverProps?: Omit<PopoverProps, 'children'>;
  required?: boolean;
  validate?: any;
  hideRequiredStar?: boolean;
  fieldFormOnlyLabel?: boolean;
  disableErrorText?: boolean;
  positionErrorBelow?: boolean;
};

/**
 * Wrapper that connect field to react-final-form and handle error display
 * in case part of the functionality need to be used separately, should be splitted into Field and FormBlock
 */
export const withFieldForm = <T extends object = any>(
  Component: React.ComponentType<React.PropsWithChildren<any>>,
  defaultFieldProps?: Partial<FieldProps<any, any>>,
): React.FC<Omit<T, 'value' | 'onChange' | 'name'> & FormComponentProps> => {
  const result = (props: any & FormComponentProps) => {
    const {
      className,
      label,
      helpText,
      helpTextPopover,
      helpTextPopoverProps,
      validate,
      required,
      hideRequiredStar = false,
      fieldFormOnlyLabel,
      disableErrorText,
      positionErrorBelow = true,
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...rest
    } = props;
    const { input, meta } = useField(props.name, {
      ...defaultFieldProps,
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validate: helpers.combineValidators(validate),
    });
    const errorInfo = helpers.extractErrorInfo(meta);
    const error = props?.externalError ?? errorInfo.error;
    const showError = props?.externalShowError ?? errorInfo.showError;

    const labelProps = {
      required,
      name: props.name,
      helpTextPopover,
      helpTextPopoverProps,
      hideRequiredStar,
      hideTextPopover: fieldFormOnlyLabel,
    };

    return fieldFormOnlyLabel ? (
      <div>
        <Label {...labelProps}>{label}</Label>
        {Component && <Component {...input} {...rest} id={props.name} showError={showError} />}
      </div>
    ) : (
      <div className={className}>
        <Label {...labelProps}>{label}</Label>
        <div
          className={cn('form-block', {
            error,
          })}
          data-input-name={props.name}
        >
          <Transition transition="fade" mounted={!!error && !disableErrorText} duration={300}>
            {(styles) => (
              <div
                style={styles}
                className={cn('form-block-error-details', {
                  'position-below': positionErrorBelow,
                })}
              >
                {error?.toString()}
              </div>
            )}
          </Transition>
          {Component && <Component {...input} {...rest} id={props.name} showError={showError} />}
          {helpText && <div className="form-block-help-text">{helpText}</div>}
        </div>
      </div>
    );
  };
  result.displayName = 'WithFieldForm';

  return result;
};
