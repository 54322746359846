import { PropsWithChildren } from 'react';
import { PopoverProps } from '@mantine/core';
import cn from 'classnames';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';

type LabelProps = {
  name?: string;
  className?: string;
  required?: boolean;
  helpTextPopover?: React.ReactNode;
  helpTextPopoverProps?: Omit<PopoverProps, 'children'>;
  hideRequiredStar?: boolean;
  hideTextPopover?: boolean;
};

export const Label = (props: PropsWithChildren<LabelProps>) => {
  const {
    children,
    name,
    required,
    className,
    hideRequiredStar = false,
    helpTextPopover,
    helpTextPopoverProps,
    hideTextPopover,
  } = props;
  if (!children) return null;
  return (
    <label className={cn('form-label', className)} htmlFor={name}>
      {children}
      {required && !hideRequiredStar ? <div className="required-star">*</div> : ''}
      {helpTextPopover && !hideTextPopover && (
        <HelpTextPopover text={helpTextPopover} {...helpTextPopoverProps} />
      )}
    </label>
  );
};
