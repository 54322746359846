import { FormattedNumber } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { clearEverything } from 'Actions/ResetAction';
import { AccSelect, SelectItem } from 'Components/AccSelect';
import {
  ImpersonateOrganizationsDocument,
  ImpersonateOrganizationsQuery,
  OrganizationNode,
} from 'Ghql';
import { useActions } from 'Hooks/redux/useActions';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import styles from './impersonate.module.scss';

type OrganizationOption = SelectItem<string> & Partial<OrganizationNode>;

const ImpersonateSelectOption = (props: OrganizationOption) => {
  const renderPrice = () => {
    if (!props.active || !props.activePlan || !props.activePlan?.hasOwnProperty('priceMonthly')) {
      return null;
    }
    return (
      <FormattedNumber
        style="currency"
        value={props.activePlan?.priceMonthly ?? 0}
        currency={props.activePlan?.currency ?? undefined}
        currencyDisplay="symbol"
      />
    );
  };

  return (
    <span>
      {props.label} {renderPrice()}
    </span>
  );
};

export const ImpersonateSelect = () => {
  const actions = useActions({ clearEverything });
  const client = useApolloClient();
  const handleChange = (_, selectedItem: OrganizationOption) => {
    actions.clearEverything();
    redirectToExternalUrl(`/accuranker_admin/impersonate_organization/${selectedItem.slug}/`);
  };

  const getOptions = async (query: string | undefined) => {
    return client
      .query<ImpersonateOrganizationsQuery>({
        query: ImpersonateOrganizationsDocument,
        variables: {
          searchQuery: query,
        },
      })
      .then(
        ({ data }) =>
          (data.organizations
            ?.map((organization) => ({
              value: organization?.id,
              label: `${organization?.name} (${organization?.id})`,
              ...organization,
            }))
            .filter((e) => !!e.value) as OrganizationOption[]) ?? [],
      );
  };

  return (
    <AccSelect<OrganizationOption>
      name="impersonate-account"
      className={styles.select}
      onChange={handleChange}
      clearable={false}
      searchable={true}
      loadOptions={getOptions}
      placeholder={t('Impersonate account')}
      nothingFoundText={t('No results found')}
      searchPromptText={t('Type to search')}
      itemComponent={ImpersonateSelectOption}
      dropdownPosition="top"
      bg="snorlax"
      size="sm"
      minQueryLength={0}
      rightSectionComponent={() => <></>}
      groupHidden
      showResultsForEmptySearch
    />
  );
};
