import { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { getChartPositionerTooltip } from 'Components/Chart/LineChart/support/helpers';
import { ChartButtonTheme } from 'Components/Chart/support/buttonStyleConfig';
import { DEFAULT_CHART_CONFIG } from 'Components/Chart/support/constants';
import { updateLoader } from 'Components/HighchartsLoader';
import { getSerpFeatureData } from 'Components/Table/TableRow/SERPOptions/data';
import { colorScheme } from 'Utilities/colors';
import { t, tn } from 'Utilities/i18n';
import {
  ChartCategory,
  SerpData,
  SerpStats,
  getSerpIconLabel,
} from '../../Chart/support/useChartConfig';

export type FlippedChartConfigProps = {
  data: Record<string, any> | undefined;
  keywordCount: number;
  domainId: string;
  onClick: (domainId: string, category: ChartCategory, name?: string) => void;
  history?: Record<string, any>;
  animate?: boolean;
  language?: string;
  loading?: boolean;
  period?: number;
  watermark?: boolean;
  watermarkBig?: boolean;
  watermarkSmall?: boolean;
  height?: number;
};

const countStats = (stats: SerpStats) => (stats.owned || 0) + (stats.notOwned || 0);

const renderLabel = (count: number, serpLabel: string, keywordCount: number) => {
  return (
    <>
      <strong>{serpLabel}</strong>
      <br />
      {tn(
        '%s keyword with %s (%s total keywords)',
        '%s keywords with %s (%s total keywords)',
        count,
        serpLabel,
        keywordCount,
      )}
    </>
  );
};

const generateData = (data: SerpData | undefined, keywordCount: number) => {
  if (!data) return [];
  return Object.entries(data)
    .filter(([, stats]) => countStats(stats) > 0)
    .map(([feature, stats]) => {
      const serpFeatureData = getSerpFeatureData(feature);
      const label = renderLabel(countStats(stats), serpFeatureData?.label || '', keywordCount);
      const htmlLabel = renderToString(<>{label}</>);
      return {
        serp: serpFeatureData,
        label: htmlLabel,
        stats,
        type: 'bar',
      };
    })
    .sort((a, b) => {
      return countStats(b.stats) - countStats(a.stats);
    });
};

type RawData = ReturnType<typeof generateData>;

const formatData = (data: RawData) => {
  const totals = data.map((d) => d.stats).map(countStats);
  return [
    {
      name: t('Total'),
      color: colorScheme.graphs.graphBlueColors[0],
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      type: 'bar',
      data: totals,
    },
  ];
};

export const useFlippedChartConfig = (props: FlippedChartConfigProps): Highcharts.Options => {
  const intl = useIntl();
  const { data, period, loading, height, keywordCount, domainId, onClick, animate } = props;

  const chart: Highcharts.ChartOptions = useMemo(
    () => ({
      inverted: true,
      height,
      type: 'bar',
      marginTop: 5,
      marginRight: 5,
      marginBottom: 75,
      marginLeft: 75,
      colorCount: 6,
      zoomType: 'x',
      zooming: {
        resetButton: {
          position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -5,
          },
          //style the button similar to AccButton secondary
          theme: ChartButtonTheme,
        },
      },
      events: {
        load() {
          updateLoader(this, period, loading);
        },
      },
    }),
    [period, loading, height],
  );

  const generatedData = generateData(data, keywordCount);
  const series = formatData(generatedData) as Highcharts.SeriesOptionsType[];

  const categories = generatedData ? generatedData : [];

  const plotOptions: Highcharts.PlotOptions = {
    line: {
      pointPlacement: 'between',
    },
    series: {
      stacking: 'normal',
      animation: animate,
      cursor: 'pointer',
      point: {
        events: {
          click: (evt) => {
            const {
              series: { name },
              category,
            } = evt.point;

            if (domainId == null) {
              return;
            }

            onClick && onClick(domainId, category as unknown as ChartCategory, name);
          },
        },
      },
    },
  };

  const yAxis: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] = [
    {
      title: {
        text: t('Amount of keywords with SERP'),
      },
      min: 0,
      top: '0%',
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
      },
    },
  ];

  const xAxis: Highcharts.XAxisOptions = {
    categories: categories as unknown as Highcharts.XAxisOptions['categories'],
    labels: {
      formatter: getSerpIconLabel,
      useHTML: true,
    },
  };

  const tooltip: Highcharts.TooltipOptions = {
    shared: true,
    shadow: false,
    backgroundColor: '#FFFFFF',
    borderColor: '#E0E0E7',
    borderRadius: 0,
    borderWidth: 1,
    padding: 1,
    useHTML: true,
    headerFormat:
      '<div class="chart-tooltip-table"><div class="chart-tooltip-table-tr"><div class="chart-tooltip-table-th">{point.key.label}</div></div>',

    pointFormatter() {
      return `<div class="chart-tooltip-table-tr">
                <div class="chart-tooltip-table-td">
                    <span class="chart-tooltip-bullet" style="color: ${
                      (this as any).color
                    };">●</span> ${(this as any).series.name}
                </div>
                <div class="chart-tooltip-table-td chart-tooltip-table-right" style="text-align: right;">
                    ${intl.formatNumber((this as any).y)} (${intl.formatNumber(
        (this as any).y / keywordCount,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'percent',
        },
      )})
                </div>
            </div>`;
    },

    footerFormat: '</div>',
    xDateFormat: '%b %e, %Y',
    hideDelay: 5,
    positioner(labelWidth, labelHeight, point) {
      return getChartPositionerTooltip(labelWidth, labelHeight, point, this as any);
    },
  };

  return {
    chart,
    plotOptions,
    series,
    xAxis,
    yAxis,
    tooltip,
    legend: {
      enabled: false,
    },
    lang: {
      noData: t('No SERP feature data for the selected filters'),
    },
    ...DEFAULT_CHART_CONFIG,
  };
};
