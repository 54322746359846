import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from '@mantine/hooks';
import { IconListSearch } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import FormatNumber from 'Components/FormatNumber/new';
import { FilterBase } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { EventName, FilterTrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';

type CellProps = {
  classes?: Record<string, string>;
};

const LinkToKeywordListCell = <T extends CellProps>({
  props,
  filters,
  value,
  tooltip,
}: {
  props: T;
  filters: FilterBase[];
  value: number | null;
  tooltip?: string;
}) => {
  const { hovered, ref } = useHover();
  const trackEvent = useMixpanel();

  const getLink = () =>
    linkWithFilters({
      to: '/keywords/list',
      overwriteFilters: filters,
      filterSet: KEYWORDS_FILTER_SET,
    });

  const handleTrackFilterEvent = () => {
    filters.forEach((filter) => {
      trackEvent(EventName.AddFilter, {
        Attribute: `${filter.attribute}`,
        Type: filter.type,
        Comparison: filter.comparison,
        Value: `${filter.value}`,
        'Tracking Key': FilterTrackingKey.TagCloudToKeywords,
      });
    });
  };

  const hasValue = typeof value === 'number';

  return (
    <div ref={ref} className={props?.classes?.rightTextAlign} key="keywords-link">
      <AccTooltip tooltip={tooltip ? tooltip : t('Go to keywords list')}>
        {hasValue ? (
          <Link
            onClick={handleTrackFilterEvent}
            to={hovered ? getLink() : '/'}
            className="table-link"
          >
            {hovered && <IconListSearch size={16} width={22} />}
            <FormatNumber value={value} />
          </Link>
        ) : (
          <FormatNumber value={value} />
        )}
      </AccTooltip>
    </div>
  );
};

export default memo(LinkToKeywordListCell, propsIsEqualComparison);
