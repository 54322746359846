import { useState } from 'react';
import { IconChevronDown, IconChevronUp, IconPlugConnected } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { AccDropdownMenu, DropdownItem } from 'Components/AccDropdownMenu';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type Props = {
  label?: string;
  disabled?: boolean;
  tooltip?: string;
  items: DropdownItem[];
};

const IntegrationsMenu = (props: Props) => {
  const { disabled, tooltip, label, items } = props;
  const [menuOpened, setMenuOpened] = useState(false);
  const ChevronIcon = menuOpened ? IconChevronUp : IconChevronDown;
  // If all items are disabled or hidden, don't show the menu
  if (items.every((item) => item.disabled || item.hidden)) {
    return null;
  }
  return (
    <AccDropdownMenu
      items={items}
      onChange={() => !disabled && setMenuOpened(!menuOpened)}
      opened={menuOpened}
      width="auto"
    >
      <AccButton
        variant="secondary"
        disabled={disabled}
        tooltip={tooltip}
        leftSection={<IconPlugConnected size={18} />}
        rightSection={<ChevronIcon size={18} />}
        trackingKey={TrackingKey.IntegrationsMenu}
      >
        {label || t('Integrations')}
      </AccButton>
    </AccDropdownMenu>
  );
};

export default IntegrationsMenu;
