import { Flex, Group } from '@mantine/core';
import ConnectedLabel from 'Components/ConnectedLabel';
import { AnalyticsIntegrationButtons } from 'Components/IntegrationButtons';
import AccTitle from 'Components/Title/AccTitle';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import styles from './menu.module.scss';

const ConnectionStatusLabel = ({
  isConnected,
  isAdobeMarketingConnected,
  isGAConnected,
}): JSX.Element => {
  const connAdobeTxt = t('Connected to Adobe Analytics');
  const connGATxt = t('Connected to Google Analytics');
  const notConnTxt = t('This domain is not connected to an analytics service');

  return (
    <ConnectedLabel
      isConnected={isConnected}
      description={
        isAdobeMarketingConnected ? connAdobeTxt : isGAConnected ? connGATxt : notConnTxt
      }
    />
  );
};

const ConnectionMetaData = ({ descriptionType, description }) => (
  <div className={styles.meta}>
    <div key="description">
      <h6>{descriptionType}:</h6> <span>{description}</span>
    </div>
  </div>
);

export const ConnectToAnalytics = (): JSX.Element => {
  const { domainInfo } = useQueryDomainInfo();
  const isConnected = Boolean(domainInfo?.isGAConnected || domainInfo?.isAdobeMarketingConnected);

  let description = domainInfo?.googleOauthConnectionGa?.description ?? '';
  let descriptionType = t('Google OAuth Connection');
  if (domainInfo?.isAdobeMarketingConnected) {
    description = domainInfo?.adobeMarketingConnection?.description ?? '';
    descriptionType = t('Adobe Marketing Connection');
  }

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('Analytics')}</AccTitle>
      <ConnectionStatusLabel
        isConnected={isConnected}
        isAdobeMarketingConnected={domainInfo?.isAdobeMarketingConnected}
        isGAConnected={domainInfo?.isGAConnected}
      />
      {isConnected && (
        <ConnectionMetaData descriptionType={descriptionType} description={description} />
      )}
      <Group>
        <AnalyticsIntegrationButtons />
      </Group>
    </Flex>
  );
};
