import { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import RemoveIcon from 'icons/close-2.svg?inline';
import './location-field.scss';

const hasDuplicates = (array: (string | undefined)[]) => {
  const entries = array.map((item) => (item === undefined ? '' : item));
  return new Set(entries).size !== entries.length;
};

type AutoCompleteOptions = {
  componentRestrictions?: { country: string };
  types: string[];
};

type Props = {
  placeholder: string;
  value: string;
  onChange: (...args: Array<any>) => any;
  onDelete?: (...args: Array<any>) => any;
  canDelete?: boolean;
  selectedCountry?: string;
  disabled?: boolean;
  locations?: (string | undefined)[];
};

const LocationInput: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  onDelete,
  canDelete = false,
  selectedCountry,
  disabled = false,
  locations,
}) => {
  const DeleteButton = () => {
    if (!onDelete || !canDelete) return null;
    return <RemoveIcon className="remove-icon" onClick={onDelete} />;
  };

  const cssClasses = {
    input: 'text-input-control',
    autocompleteContainer: 'autocomplete-container',
    autocompleteItem: 'autocomplete-item',
    autocompleteItemActive: 'autocomplete-item-active',
  };

  const options: AutoCompleteOptions = {
    types: ['geocode'], // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
  };

  if (selectedCountry) {
    options.componentRestrictions = {
      country: selectedCountry,
    };
  }

  const [latestSelection, setLatestSelection] = useState(value || '');

  const handleSelect = (location: string) => {
    setLatestSelection(location);
    onChange(location);
  };

  return (
    <span>
      <PlacesAutocomplete
        options={options}
        inputProps={{
          placeholder,
          value,
          onChange,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          disabled,
          onBlur: () => {
            // When leaving the input, ensure the value is the latest selection, or remove the value
            if (value !== '' && value !== latestSelection) {
              onChange(latestSelection);
              toast.error(t('Please select a location from the suggestions'));
            }
            // Do not allow more than one empty location
            if (value === '' && locations && hasDuplicates(locations)) {
              toast.error(t('You cannot add more than one empty location.'));
              onChange(latestSelection);
              return;
            }
          },
        }}
        classNames={cssClasses}
        googleLogo={false}
        onSelect={handleSelect}
      />
      <DeleteButton />
    </span>
  );
};

export default LocationInput;
