import { FileWithPath } from '@mantine/dropzone';
import Dropzone from 'Components/Dropzone';
import { FormComponentProps } from 'Components/Fields/hooks/WithFieldForm/WithFieldForm';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';

type JsonPickerProps = FormComponentProps & {
  value: { [key: string]: string };
  onChange: (value: { [key: string]: string } | null) => void;
  processJson: (value: { [key: string]: string } | null) => { [key: string]: any } | null;
  description?: string;
  invalidFileMessage?: string;
  disabled?: boolean;
};

const JsonPicker = (props: JsonPickerProps) => {
  const { value, onChange, processJson, description, invalidFileMessage, disabled } = props;
  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const jsonConfig = JSON.parse(event.target?.result as string);
        const processedConfig = processJson?.(jsonConfig) ?? jsonConfig;
        onChange(processedConfig);
      } catch (error) {
        toast.error(t('Invalid JSON file format'));
      }
    };

    reader.readAsText(file);
  };

  const handleDelete = () => {
    onChange(null);
  };

  return (
    <Dropzone
      onDropFile={handleDrop}
      onDeleteFile={handleDelete}
      preview={value ? JSON.stringify(value) : undefined}
      acceptFiles={['application/json']}
      description={description}
      invalidFileMessage={invalidFileMessage}
      fileSize={1024 * 1024}
      multiple={false}
      icon="file"
      previewType="json"
      disabled={disabled}
    />
  );
};

export default JsonPicker;
