import AccAlert from 'Components/AccAlert/AccAlert';
import { tct } from 'Utilities/i18n';

type Props = {
  show: boolean;
};

const NoUntrackedKeywordsAlert = ({ show }: Props) => {
  if (!show) {
    return null;
  }

  return (
    <AccAlert severity={'warning'} w={'100%'}>
      {tct(
        '[b: No keywords found][br]We did not find any untracked keywords for the selected domain.',
        {
          b: <b />,
          br: <br />,
        },
      )}
    </AccAlert>
  );
};

export default NoUntrackedKeywordsAlert;
