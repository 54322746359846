import { AddGoogleBigQueryConnectionInput } from 'Ghql';

export const REQUIRED_CONFIG_FIELDS = [
  'type',
  'projectId',
  'privateKeyId',
  'privateKey',
  'clientEmail',
  'clientId',
  'authUri',
  'tokenUri',
  'authProviderX509CertUrl',
  'clientX509CertUrl',
  'universeDomain',
] as const;

const BIG_QUERY_KEY_MAPPING = {
  auth_provider_x509_cert_url: 'authProviderX509CertUrl',
  auth_uri: 'authUri',
  client_email: 'clientEmail',
  client_id: 'clientId',
  client_x509_cert_url: 'clientX509CertUrl',
  private_key: 'privateKey',
  private_key_id: 'privateKeyId',
  project_id: 'projectId',
  token_uri: 'tokenUri',
  type: 'type',
  universe_domain: 'universeDomain',
} as const;

export const mapBigQueryKeys = (
  jsonConfig: Record<string, unknown>,
): AddGoogleBigQueryConnectionInput => {
  const mappedConfig: Partial<AddGoogleBigQueryConnectionInput> = {};
  Object.entries(BIG_QUERY_KEY_MAPPING).forEach(([key, mappedKey]) => {
    mappedConfig[mappedKey] = jsonConfig[key]?.toString() || '';
  });
  return mappedConfig as AddGoogleBigQueryConnectionInput;
};
