import { Center, Group } from '@mantine/core';
import { IconCheck, IconHourglass, IconInfoTriangle } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

type BigQueryBackfillProps = {
  isConnected: boolean;
  isBackfilled: boolean;
  isPending: boolean;
};
const BigQueryBackfill = ({ isConnected, isBackfilled, isPending }: BigQueryBackfillProps) => {
  if (!isConnected) {
    return null;
  }
  const Icon = isBackfilled ? IconCheck : isPending ? IconHourglass : IconInfoTriangle;
  const color = isBackfilled ? 'green.4' : isPending ? 'gray.4' : 'yellow.9';
  return (
    <Group gap="xs">
      <Center c={color}>
        <Icon size={20} stroke={isBackfilled ? 4 : undefined} />
      </Center>
      <AccText fs="italic">
        {isBackfilled
          ? t('Data is backfilled')
          : isPending
          ? t('Backfilling in progress')
          : t('Data is not backfilled')}
      </AccText>
    </Group>
  );
};

export default BigQueryBackfill;
