import { Center } from '@mantine/core';
import AccAlert from 'Components/AccAlert/AccAlert';
import ContactSupportButton from 'Components/ContactSupportButton';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import IntercomIcon from 'icons/intercom-icon.svg?inline';
import styles from './requestBigQuery.module.scss';

const RequestBigQueryFeature = () => {
  return (
    <AccAlert
      title={t('Request access to Google BigQuery feature')}
      severity="info"
      variant="standard"
      mb="lg"
      className={styles.crossSellBanner}
    >
      <AccText size="sm" mb="0">
        {t('To request access to Google BigQuery feature, please contact support.')}
      </AccText>
      <ContactSupportButton
        className={styles.contactSupportBtn}
        leftSection={
          <Center w={14} h={14}>
            <IntercomIcon />
          </Center>
        }
      />
    </AccAlert>
  );
};

export default RequestBigQueryFeature;
