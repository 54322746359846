import cookie from 'react-cookies';
import { useIntercom } from 'react-use-intercom';
import cn from 'classnames';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import AccText from 'Components/Text/AccText';
import { t, tct } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import IntegrationCategory from './components/IntegrationCategory';
import Link from './components/Link';
import { useIntegrations } from './hooks/useIntegrations';
import './integrations.scss';

const IntegrationsPage = () => {
  const { show } = useIntercom();
  const { defaultIntegrations, customIntegrations, enterpriseIntegrations } = useIntegrations();

  const DefaultCategory = () => (
    <IntegrationCategory title={t('Standard integrations')} integrations={defaultIntegrations} />
  );

  const CustomCategory = () => (
    <IntegrationCategory
      title={t('Advanced integrations')}
      subtitle={tct(
        'These integrations utilize the [api:AccuRanker API]. API access is included in all plans.',
        {
          api: <Link href={'/api'} />,
        },
      )}
      integrations={customIntegrations}
    />
  );
  const EnterpriseCategory = () => {
    const isPresentMode = !!cookie.load('ar_present_mode');

    if (isPresentMode) {
      return null;
    }

    return (
      <IntegrationCategory
        title={t('Enterprise integrations')}
        subtitle={tct(
          'These integrations are only available on enterprise plans. Reach out to [link:customer support] for more information.',
          {
            link: <AccText as="span" size="sm" variant="link" onClick={() => show()} />,
          },
        )}
        integrations={enterpriseIntegrations}
      />
    );
  };

  return (
    <>
      <Breadcrumbs />
      <div className={cn('integrations', reusableStyles.paperContainer)}>
        <DefaultCategory />
        <CustomCategory />
        <EnterpriseCategory />
      </div>
    </>
  );
};

export default IntegrationsPage;
