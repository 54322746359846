import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { Group } from '@mantine/core';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import AccuCell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import { ColumnType, QueryOrder } from 'Components/DataTable';
import { FixedType } from 'Components/DataTable/table-core/interface';
import { Flag } from 'Components/Flag';
import FormatNumber from 'Components/FormatNumber/new';
import { SORTING_TYPE } from 'Constants';
import { GscKeywordsDocument, GscKeywordsQuery, GscKeywordsQueryVariables } from 'Ghql';
import { useDomainIds } from 'Hooks/data/domain/useQueryDomainInfo';
import { useMetaDataCountrylocales } from 'Hooks/data/user/useMetaData';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ColumnIDs } from './ColumnIDs';

export const GSC_KEYWORD_SEPARATOR = '¦';

type GscKeywordData = [number, string, number, boolean, number, string, number];

type GscKeyword = {
  clicks: number;
  countryCode: string;
  ctr: number;
  exists: boolean;
  impressions: number;
  keyword: string;
  position: number;
  countryName: string;
  id: string;
};

// eslint-disable-next-line import/no-unused-modules
export const useFetchGSCKeywords = ({ domainId }: { domainId: string | undefined }) => {
  const client = useApolloClient();
  const countryLocales = useMetaDataCountrylocales();

  const countryLocalesMap = {};
  countryLocales?.forEach((locale) => {
    countryLocalesMap[locale?.countryCode?.toLowerCase() || ''] = locale?.region;
  });

  return useCallback(async () => {
    try {
      if (!domainId) {
        return { data: [], length: 0 };
      }
      const response = await client.query<GscKeywordsQuery, GscKeywordsQueryVariables>({
        query: GscKeywordsDocument,
        variables: {
          domainId: domainId || '',
        },
      });
      const keywordsList: GscKeywordData[] = response?.data?.domain?.gscKeywords || [];
      const keywords: GscKeyword[] = [];
      keywordsList?.filter(notEmpty).forEach((row, index) =>
        keywords.push({
          clicks: row[0],
          countryCode: row[1],
          ctr: row[2],
          exists: row[3],
          impressions: row[4],
          keyword: row[5],
          position: row[6],
          countryName: countryLocalesMap[row[1]] || row[1],
          id: row[5] + GSC_KEYWORD_SEPARATOR + row[1] + index,
        }),
      );

      return { data: keywords, length: keywords.length };
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching keywords from Google Search console:', error.message);
      }
      return { data: [], length: 0 };
    }
  }, [client, domainId, isNil(countryLocales)]);
};

export const useGSCKeywordsColumns = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return useMemo(
    () =>
      [
        {
          id: ColumnIDs.CHECKBOX,
          title: t('Checkbox'),
          width: 40,
          fixed: 'left' as FixedType,
          onHeaderCell: () => ({
            type: 'checkbox',
          }),
          onCell: (record) => ({
            type: 'checkbox',
            data: record,
          }),
        },
        {
          id: ColumnIDs.KEYWORD,
          title: t('Keyword'),
          width: 300,
          flex: true,
          cellRenderer: (props) => {
            const { record } = props;
            return (
              <div
                className={cn({
                  exists: record.exists,
                })}
              >
                {record.keyword}
              </div>
            );
          },
          onHeaderCell: () => ({
            tooltip: t('Sort by Keyword'),
            filter: {
              filterAttributes: [FilterAttribute.KEYWORD],
              filterTooltip: t('Filter by Keyword…'),
            },
            ordering: {
              defaultOrder: QueryOrder.DESC,
              orderBy: ColumnIDs.KEYWORD,
              sortingKey: SORTING_TYPE.alphabetical,
            },
          }),
        },
        {
          id: ColumnIDs.COUNTRY_NAME,
          title: t('Country'),
          width: 200,
          cellRenderer: (props) => {
            const { record } = props;
            return (
              <Group wrap="nowrap" gap="xs">
                <Flag size="sm" country={record.countryCode || undefined} />
                {record.countryName}
              </Group>
            );
          },
          onHeaderCell: () => ({
            tooltip: t('Sort by Country'),
            filter: {
              filterAttributes: [FilterAttribute.COUNTRY_NAME],
              filterTooltip: t('Filter by Country'),
            },
            ordering: {
              defaultOrder: QueryOrder.DESC,
              orderBy: ColumnIDs.COUNTRY_NAME,
              sortingKey: SORTING_TYPE.alphabetical,
            },
          }),
        },
        {
          id: ColumnIDs.IMPRESSIONS,
          title: t('Impressions'),
          width: 140,
          cellRenderer: (props) => {
            const { record } = props;
            return <AccuCell rightAligned> {record.impressions}</AccuCell>;
          },
          onHeaderCell: () => ({
            tooltip: t('Sort by Impressions'),
            filter: {
              filterAttributes: [FilterAttribute.IMPRESSIONS],
              filterTooltip: t('Filter by Impressions'),
            },
            ordering: {
              defaultOrder: QueryOrder.DESC,
              orderBy: FilterAttribute.IMPRESSIONS,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
        },
        {
          id: ColumnIDs.CLICKS,
          title: t('Clicks'),
          width: 90,
          cellRenderer: (props) => {
            const { record } = props;
            return <AccuCell rightAligned> {record.clicks} </AccuCell>;
          },
          onHeaderCell: () => ({
            tooltip: t('Sort by Clicks'),
            filter: {
              filterAttributes: [FilterAttribute.CLICKS],
              filterTooltip: t('Filter by Clicks'),
            },
            ordering: {
              defaultOrder: QueryOrder.DESC,
              orderBy: FilterAttribute.CLICKS,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
        },
        {
          id: ColumnIDs.CTR,
          title: t('CTR'),
          width: 90,
          cellRenderer: (props) => {
            const { record } = props;
            return (
              <AccuCell rightAligned>
                <FormatNumber
                  value={record.ctr}
                  minimumFractionDigits={1}
                  maximumFractionDigits={1}
                />
                %
              </AccuCell>
            );
          },
          onHeaderCell: () => ({
            tooltip: t('Sort by CTR'),
            filter: {
              filterAttributes: [FilterAttribute.GSC_CTR],
              filterTooltip: t('Filter by CTR'),
            },
            ordering: {
              defaultOrder: QueryOrder.DESC,
              orderBy: FilterAttribute.GSC_CTR,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
        },
        {
          id: ColumnIDs.POSITION,
          title: t('Position'),
          width: 110,
          cellRenderer: (props) => {
            const { record } = props;

            return (
              <AccuCell rightAligned>
                <FormatNumber
                  value={record.position}
                  minimumFractionDigits={1}
                  maximumFractionDigits={1}
                />
              </AccuCell>
            );
          },
          onHeaderCell: () => ({
            tooltip: t('Sort by Position'),
            filter: {
              filterAttributes: [FilterAttribute.GSC_POSITION],
              filterTooltip: t('Filter by Position'),
            },
            reverseDirection: true,
            ordering: {
              defaultOrder: QueryOrder.ASC,
              orderBy: FilterAttribute.GSC_POSITION,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
        },
      ] as ColumnType[],
    [],
  );
};

export const useFilterVariables = () => {
  const domainIds = useDomainIds();
  const keywordFilter = useSelector(SpecificFilterSelector(FilterAttribute.KEYWORD));
  const countryNameFilter = useSelector(SpecificFilterSelector(FilterAttribute.COUNTRY_NAME));
  const impressionsFilter = useSelector(SpecificFilterSelector(FilterAttribute.IMPRESSIONS));
  const clicksFilter = useSelector(SpecificFilterSelector(FilterAttribute.CLICKS));
  const gscExistsFilter = useSelector(SpecificFilterSelector(FilterAttribute.GSC_EXISTS));
  const gscCtrFilter = useSelector(SpecificFilterSelector(FilterAttribute.GSC_CTR));
  const gscPositionFilter = useSelector(SpecificFilterSelector(FilterAttribute.GSC_POSITION));

  return {
    domainId: domainIds?.length === 1 ? domainIds[0] : undefined,
    keywordComparison: keywordFilter && keywordFilter.comparison,
    countryName: countryNameFilter && countryNameFilter.value,
    hideTrackedKeywords: gscExistsFilter && !gscExistsFilter.value,
    impressionsComparison: impressionsFilter && impressionsFilter.comparison,
    clicksComparison: clicksFilter && clicksFilter.comparison,
    gscCtrComparison: gscCtrFilter && gscCtrFilter.comparison,
    gscPositionComparison: gscPositionFilter && gscPositionFilter.comparison,
  };
};
