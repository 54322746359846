import { Stack } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { FormErrors } from 'Components/Forms/FormErrors';
import HelpGuideButton from 'Components/HelpGuideButton';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import RequestBigQueryFeature from 'Components/RequestBigQueryFeature';
import { AddGoogleBigQueryConnectionInput, useAddGoogleBigQueryConnectionMutation } from 'Ghql';
import useHasBigQueryFeature from 'Hooks/data/feature/useHasBigQueryFeature';
import toast from 'Hooks/useToast';
import { throwNetworkError } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import validation, { mapFinalFormErrors } from 'Utilities/validation';
import { REQUIRED_CONFIG_FIELDS, mapBigQueryKeys } from '../bigQueryUtils';
import InputLocation from './InputLocation';

type FormValues = {
  config: Omit<AddGoogleBigQueryConnectionInput, 'description' | 'datasetLocation'>;
  description: string;
  datasetLocation: string;
};

type Props = {
  onSubmit?: ({ connectionId }: { connectionId: string }) => any;
  onCancel: (...args: Array<any>) => any;
};

const ConnectBigQuery = ({ onSubmit, onCancel }: Props) => {
  const hasBigQueryFeature = useHasBigQueryFeature();
  const [addGoogleBigQueryConnection] = useAddGoogleBigQueryConnectionMutation();
  const onFormSubmit = (values: FormValues) => {
    return addGoogleBigQueryConnection({
      variables: {
        input: {
          ...values.config,
          description: values.description,
          datasetLocation: values.datasetLocation,
        },
      },
    })
      .then(({ data }) => {
        const errors = data?.addGoogleBigqueryConnection?.errors;
        if (errors && errors.length) {
          const errorObjects = errors
            .filter((err) => err)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((err) => ({ field: err!.field, messages: err!.messages }));
          return mapFinalFormErrors(errorObjects);
        }
        if (data?.addGoogleBigqueryConnection?.connection?.id) {
          onSubmit?.({
            connectionId: data.addGoogleBigqueryConnection.connection.id,
          });
        }
      }, throwNetworkError)
      .catch((error) => {
        toast.error(t('Failed to add connection'));
        return error;
      });
  };

  const validateConfig = (config: AddGoogleBigQueryConnectionInput): string | undefined => {
    const missingFields = REQUIRED_CONFIG_FIELDS.filter((field) => !config[field]);

    if (missingFields.length > 0) {
      return t('Invalid BigQuery configuration file - missing required fields: {{fields}}', {
        fields: missingFields.join(', '),
      });
    }

    return undefined;
  };

  if (!hasBigQueryFeature) {
    return <RequestBigQueryFeature />;
  }

  return (
    <Form onSubmit={onFormSubmit}>
      {({ submitting, invalid, submitErrors }) => {
        return (
          <>
            <Stack gap="lg">
              <FormErrors errors={submitErrors} />
              <Field.TextInput
                name="description"
                label={t('Connection name')}
                disabled={submitting}
                placeholder={t('Connection name')}
                helpText={t('The name is used to identify the connection in the integration list.')}
                validate={[validation.required]}
                required
              />
              <InputLocation disabled={submitting} />
              <Field.JsonPicker
                name="config"
                label={t('Upload BigQuery configuration')}
                disabled={submitting}
                helpTextPopover={t('Configuration file should be in JSON format.')}
                validate={[validation.required, validateConfig]}
                description={t('Drag and drop file to upload')}
                processJson={mapBigQueryKeys}
                invalidFileMessage={t('Invalid file type. Please provide a JSON type.')}
                required
              />
            </Stack>
            <ModalFooter
              primaryButtonSlot={
                <AccButton
                  type="submit"
                  variant="primary"
                  disabled={invalid || submitting}
                  loading={submitting}
                >
                  {t('Connect')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={onCancel}>
                  {t('Cancel')}
                </AccButton>
              }
              textSlot={
                <HelpGuideButton helpguideLink={'https://www.accuranker.com/help/integrations/'} />
              }
            />
          </>
        );
      }}
    </Form>
  );
};

export default ConnectBigQuery;
