import { useEffect, useRef } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { QueryOrder } from 'Constants';
import { useTreeDashboardQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useDeepEffect } from 'Hooks/useDeep';
import { isGroup, selectIdIsDomain } from 'Utilities/generateSelectId';
import { notEmpty } from 'Utilities/underdash';
import { useTreeViewStore } from './treeViewStore';
import { TreeDashboard } from './types';
import { useColumnsInput } from './useColumnsInput';
import { useTreeStructure } from './useTreeStructure';

export const useFetchTreeView = (
  ordering: { order: QueryOrder; orderBy: string },
  childrenLoadingKey: string,
) => {
  const expandedNodesRef = useRef<string[]>([]);
  const expandedNodes = expandedNodesRef.current;

  const filters = useFilters();
  const { displayCurrency } = useDisplayCurrency(true);
  const treeViewStore = useTreeViewStore();

  const setExpandNodes = (methodOrNodes?: string | string[] | ((prev: string[]) => string[])) => {
    if (typeof methodOrNodes === 'function') {
      expandedNodesRef.current = methodOrNodes(expandedNodesRef.current);
    } else {
      const expandedNodeslist = Array.isArray(methodOrNodes)
        ? methodOrNodes
        : typeof methodOrNodes === 'string'
        ? [methodOrNodes]
        : [];
      expandedNodesRef.current = expandedNodeslist;
    }
    return expandedNodesRef.current;
  };

  const {
    data,
    expandDomains,
    setTreeData,
    refetch: refetchTreeStructure,
  } = useTreeStructure(expandedNodes, filters, ordering, childrenLoadingKey, setExpandNodes);

  const setExpandedNodes = (nodes: string | string[] | undefined) => {
    const expandedNodeslist = setExpandNodes(nodes);
    const newExpandedDomains = [
      ...new Set(expandedNodeslist.filter(selectIdIsDomain).filter(notEmpty)),
    ];

    if (newExpandedDomains.length > 0) {
      expandDomains(newExpandedDomains);
    }
  };

  // Collapse all items except groups
  useDeepEffect(() => {
    expandDomains([]);
    expandedNodesRef.current = expandedNodesRef.current.filter((node) => isGroup(node));
  }, [ordering]);

  const columns = useColumnsInput();

  const {
    data: treeData,
    loading,
    refetch,
  } = useTreeDashboardQuery({
    variables: {
      ordering,
      filters,
      displayCurrency,
      columns,
    },
    onCompleted: (dataResponse) => {
      const treeResponseData: TreeDashboard['data'] = dataResponse?.treeDashboard?.data;
      const clients = treeResponseData?.clients;
      let nDomains = 0;
      treeResponseData?.clients?.map((client) => (nDomains += client.domains.length));
      // If there is only one group, expand it

      if (clients?.length === 1) {
        setExpandedNodes(clients?.[0]?.id.toString());
      } else if (nDomains < 20) {
        // If there are less than 20 domains expand all groups
        setExpandedNodes(clients?.map((client) => client.id.toString()));
      }
    },
    skip: !displayCurrency,
  });

  useDeepEffect(() => {
    if (treeData) {
      setTreeData(treeData);
    }
  }, [treeData]);

  useEffect(() => {
    refetch();
    refetchTreeStructure();
  }, [refetch, treeViewStore.refetchListener]);

  const [debouncedLoading] = useDebouncedValue(loading, 400);

  return {
    data,
    loading: debouncedLoading,
    expandedNodes,
    refetch,
    setExpandedNodes,
  };
};
