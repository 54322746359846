import { useCallback } from 'react';
import { IconPlugConnected } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { getDisabledDemoText } from 'Constants/messages';
import { useRemoveGoogleBigQueryAccountMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type BigQueryIntegrationButtonsProps = {
  refresh?: () => void;
};

export const BigQueryIntegrationButtons = ({
  refresh,
}: BigQueryIntegrationButtonsProps): JSX.Element | null => {
  const domainId = useDomainId();
  const { domainInfo, refetch: refreshDomainInfo, isDemoDomain } = useQueryDomainInfo();
  const disabledDemoText = getDisabledDemoText();
  const { showModal, hideModal } = useModal();
  const [removeAccount] = useRemoveGoogleBigQueryAccountMutation();
  const isConnected = !!domainInfo?.isBigQueryConnected;
  const isBackfilled = !!domainInfo?.isBigQueryBackfilled;
  const isPending = !!domainInfo?.isBigQueryPending;

  const handleRefresh = useCallback(() => {
    refresh?.();
    if (domainId) refreshDomainInfo();
  }, [refreshDomainInfo, refresh, domainId]);

  const handleConnectToBigQuery = () =>
    showModal({
      modalType: 'ConnectToBigQuery',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: handleRefresh,
      },
    });
  const handleBackfillData = () =>
    showModal({
      modalType: 'ConnectToBigQuery',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: handleRefresh,
        isBackfilling: true,
      },
    });

  const handleDisconnectFromBigQuery = () => {
    if (domainId) {
      removeAccount({ variables: { input: { domainId } } }).then(
        () => {
          toast.success(t('Account removed'));
          handleRefresh();
          hideModal();
        },
        () => {
          toast.error(t('Failed to remove account'));
        },
      );
    } else {
      toast.error(t('Failed to remove account'));
    }
  };

  if (!isConnected) {
    return (
      <AccButton
        onClick={handleConnectToBigQuery}
        leftSection={<IconPlugConnected size={18} />}
        variant="secondary"
        disabled={isDemoDomain}
        tooltip={isDemoDomain ? disabledDemoText : undefined}
        trackingKey={TrackingKey.BigQueryConnectOpenModal}
      >
        {t('Connect to BigQuery')}
      </AccButton>
    );
  }
  return (
    <>
      <AccButton
        onClick={handleBackfillData}
        variant="secondary"
        disabled={isDemoDomain || isBackfilled || isPending}
        tooltip={
          isBackfilled || isPending
            ? t('Data can only be backfilled once')
            : t('Push historic keyword data to BigQuery')
        }
        trackingKey={TrackingKey.BigQueryBackfillOpenModal}
      >
        {isPending
          ? t('Backfilling...')
          : isBackfilled
          ? t('Data is backfilled')
          : t('Backfill data')}
      </AccButton>
      <AccButton
        onClick={handleDisconnectFromBigQuery}
        leftSection={<IconPlugConnected size={18} />}
        variant="secondary"
        disabled={isDemoDomain}
        trackingKey={TrackingKey.BigQueryDisconnect}
      >
        {t('Disconnect from BigQuery')}
      </AccButton>
    </>
  );
};
