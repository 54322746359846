import { Flex, Group } from '@mantine/core';
import ConnectedLabel from 'Components/ConnectedLabel';
import { SearchConsoleIntegrationButtons } from 'Components/IntegrationButtons';
import AccTitle from 'Components/Title/AccTitle';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import styles from './menu.module.scss';

const ConnectionStatusLabel = ({ isConnected }) => {
  const connTxt = t('Connected to Google Search Console');
  const notConnTxt = t('This domain is not connected to Google Search Console');
  return (
    <ConnectedLabel isConnected={isConnected} description={isConnected ? connTxt : notConnTxt} />
  );
};

const ConnectionMetaData = ({ gscSiteId, description }) => (
  <div className={styles.meta}>
    <div key="description">
      <h6>{t('Google OAuth Connection')}:</h6> <span>{description}</span>
    </div>
    {gscSiteId && (
      <div key="siteID">
        <h6>{t('Website')}:</h6>
        <span>{gscSiteId}</span>
      </div>
    )}
  </div>
);

export const ConnectToGSC = (): JSX.Element | null => {
  const { domainInfo } = useQueryDomainInfo();
  const isConnected = Boolean(domainInfo?.isGscConnected);

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('Search Console')}</AccTitle>
      {<ConnectionStatusLabel isConnected={isConnected} />}
      {isConnected && (
        <ConnectionMetaData
          description={domainInfo?.googleOauthConnectionGsc?.description}
          gscSiteId={domainInfo?.gscSiteId}
        />
      )}
      <Group>
        <SearchConsoleIntegrationButtons />
      </Group>
    </Flex>
  );
};
