import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Box, Flex } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import { filterVisibility } from 'Components/Filters';
import IntegrationsMenu from 'Components/IntegrationsMenu';
import { ModalTypes } from 'Components/Modal/Content';
import { STEPS } from 'Components/Modal/Wizard/Base/Account';
import { ACCOUNT, PROPERTY } from 'Components/Modal/Wizard/ConnectToGA';
import { universalAnalyticsIdPrefix } from 'Constants';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import useHasBigQueryFeature from 'Hooks/data/feature/useHasBigQueryFeature';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { DomainInfo } from 'Query';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import SearchTypeSwitch from './SearchTypeSwitch';

type Props = {
  saveChartEdits: () => void;
  cancelChartEdits: () => void;
  restoreDefaultCharts: () => void;
  /** The group is empty when a user has selected a group without any domains */
  isEmptyGroup?: boolean;
  domainInfo?: DomainInfo;
  refresh?: Function;
};

export const OverviewActionBar = ({ isEmptyGroup, domainInfo, refresh: handleRefresh }: Props) => {
  const actions: any = [];
  const { showModal } = useModal();
  const trackEvent = useMixpanel();
  const hasBigQueryFeature = useHasBigQueryFeature();
  useEffect(() => () => filterVisibility.setVisibility(true), []);

  const location = useLocation();
  const selectedGroupId: string | undefined = location.state?.groupId;

  const domainId = domainInfo?.id;
  const deferredRefresh = (time: number = 100) => {
    setTimeout(() => {
      handleRefresh?.();
    }, time);
  };

  const handleEditDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId,
        groupId: domainInfo?.client?.id,
        onClose: deferredRefresh.bind(null, 3500),
      },
    });
  };

  const handleUpgradeToGa4 = () => {
    showModal({
      modalType: 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        domainId,
        skipToStep: PROPERTY,
        skippedData: {
          [ACCOUNT]: { accountId: domainInfo?.gaAccountId },
          [STEPS.SELECT]: { connectionId: domainInfo?.googleOauthConnectionGa?.id },
        },
      },
    });
  };

  const handleAddAction = () => {
    trackEvent(EventName.AddKeywordsModalOpen);
    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: () => {},
      },
    });
  };

  const handleConnect = (modalType: ModalTypes) => {
    showModal({
      modalType,
      modalTheme: 'light',
      modalProps: { domainId, refresh: deferredRefresh },
    });
  };

  const handleAddDomainAction = (_evt: unknown, groupId?: string) => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        groupId,
      },
    });
  };

  // only show if we don't have multiple domains
  if (domainInfo) {
    const {
      isGAConnected,
      isGscConnected,
      isAdobeMarketingConnected,
      isBigQueryConnected,
      youtubeChannelName,
      gaPropertyId,
      isDemoDomain,
    } = domainInfo;

    actions.push(
      <Flex gap="sm" wrap="wrap" key="singleDomainActions">
        <AccessControl>
          <Actions.AddAction
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
            key="add"
            label={t('Add keywords')}
            onClick={handleAddAction}
          />
          {gaPropertyId?.startsWith(universalAnalyticsIdPrefix) ? (
            <Actions.UpgadeUAToGA4AnalyticsAction
              disabled={isDemoDomain}
              tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
              key="upgradeAnalytics"
              onClick={handleUpgradeToGa4}
            />
          ) : null}
          <IntegrationsMenu
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
            items={[
              {
                label: t('Google Analytics'),
                onSelect: handleConnect.bind(null, 'ConnectToGA'),
                hidden: !!(isGAConnected || isAdobeMarketingConnected || youtubeChannelName),
              },
              {
                label: t('Adobe Analytics'),
                onSelect: handleConnect.bind(null, 'ConnectToAdobe'),
                hidden: !!(isGAConnected || isAdobeMarketingConnected || youtubeChannelName),
              },
              {
                label: t('Google Search Console'),
                onSelect: handleConnect.bind(null, 'ConnectToGSC'),
                hidden: !!(isGscConnected || youtubeChannelName),
              },
              {
                label: t('Google BigQuery'),
                onSelect: handleConnect.bind(null, 'ConnectToBigQuery'),
                hidden: !hasBigQueryFeature || !!isBigQueryConnected,
              },
            ]}
          />
          <Actions.EditDomainAction
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
            key="editDomain"
            onClick={handleEditDomain}
          />
        </AccessControl>
        <AccButton
          variant="tertiary"
          leftSection={<IconInfoCircle size={20} />}
          href={'https://www.accuranker.com/help/keywords/dashboard/'}
          target={'_blank'}
        >
          {t('Help guide')}
        </AccButton>
      </Flex>,
    );
  } else {
    actions.push(<Box key="box" />); // Add empty box to make margin-left auto work for actions below
  }

  if (isEmptyGroup) {
    actions.push(
      <Actions.AddAction
        key="addDomain"
        label={t('Add domain')}
        onClick={(evt) => handleAddDomainAction(evt, selectedGroupId)}
      />,
    );
  }

  const tmpPropsFix: any = {};

  actions.push(
    <ActionbarContainer.Sidebar key="overviewActions">
      <SearchTypeSwitch {...tmpPropsFix} key="searchTypeSwitch" />
    </ActionbarContainer.Sidebar>,
  );

  return <ActionbarContainer>{actions}</ActionbarContainer>;
};
