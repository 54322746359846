import { Center, Flex, Group, Space, Stack } from '@mantine/core';
import ConnectedLabel from 'Components/ConnectedLabel';
import { BigQueryIntegrationButtons } from 'Components/IntegrationButtons';
import RequestBigQueryFeature from 'Components/RequestBigQueryFeature';
import AccTitle from 'Components/Title/AccTitle';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import useHasBigQueryFeature from 'Hooks/data/feature/useHasBigQueryFeature';
import { formatDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import DatasetIcon from 'icons/bigquery_dataset.svg?inline';
import BigQueryBackfill from './BigQueryBackfill';
import styles from './menu.module.scss';

const ConnectionMetaData = ({ datasetName, backfilledAt }) => (
  <div className={styles.meta}>
    <div key="dataset">
      <Center inline>
        <h6>{t('Dataset name')}:</h6>
        <DatasetIcon width={'18px'} height={'18px'} fill={'currentColor'} />
        <Space w="xxs" />
        {datasetName}
      </Center>
    </div>
    {backfilledAt && (
      <div key="backfilledAt">
        <h6>{t('Backfill date')}:</h6>
        <span>{formatDate(backfilledAt)}</span>
      </div>
    )}
  </div>
);

export const ConnectToBigQuery = (): JSX.Element | null => {
  const hasBigQueryFeature = useHasBigQueryFeature();
  const { domainInfo } = useQueryDomainInfo();
  const isConnected = !!domainInfo?.isBigQueryConnected;
  const isBackfilled = !!domainInfo?.isBigQueryBackfilled;
  const isPending = !!domainInfo?.isBigQueryPending;

  if (!hasBigQueryFeature) {
    return (
      <Flex direction="column" rowGap="md">
        <AccTitle type="h3">{t('Google BigQuery')}</AccTitle>
        <RequestBigQueryFeature />
      </Flex>
    );
  }

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('Google BigQuery')}</AccTitle>
      <Stack>
        <ConnectedLabel
          isConnected={isConnected}
          description={
            isConnected ? t('Connected to BigQuery') : t('This domain is not connected to BigQuery')
          }
        />
        <BigQueryBackfill
          isConnected={isConnected}
          isBackfilled={isBackfilled}
          isPending={isPending}
        />
      </Stack>
      {isConnected ? (
        <ConnectionMetaData
          datasetName={domainInfo?.googleBigqueryDatasetName}
          backfilledAt={domainInfo?.googleBigqueryBackfilledAt}
        />
      ) : null}
      <Group>
        <BigQueryIntegrationButtons />
      </Group>
    </Flex>
  );
};
