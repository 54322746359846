import React from 'react';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { Group, Stack, useMantineTheme } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import { FormApi } from 'final-form';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form, Label } from 'Components/Fields';
import { OnSubmitCallback } from 'Components/Fields/Form/Form';
import { Flag } from 'Components/Flag';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { MetaDataSearchEngineNode } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import { useUser } from 'Hooks/data/user/useUser';
import { LocaleDataSearchEngine } from 'Utilities/Common/search-engines';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import CloseIcon from 'icons/close-2.svg?inline';
import { SEARCH_TYPE_BING, SEARCH_TYPE_GOOGLE, SEARCH_TYPE_YANDEX } from './types';
import './add-search-engine.scss';

type Props = {
  locale: Record<string, any>;
  onSubmit: OnSubmitCallback;
  editMode?: boolean;
  youTubeDomain?: string;
  initialValues: Omit<InitialEngineValues, 'countrylocale'>;
};

type Engine = MetaDataSearchEngineNode & { searchTypes: number[] };

type InitialEngineValues = {
  countrylocale: string;
  locations: string[];
  searchEngines: Engine[];
  settings: {
    ignoreLocalResults: boolean;
    ignoreFeaturedSnippet: boolean;
    enableAutocorrect: boolean;
  };
};

const hasEngine = (engines: Engine[], filterEngine: string): boolean =>
  engines?.some((engine) => engine.name === filterEngine && engine.searchTypes?.length) ?? false;

const AddSearchEngine: React.FC<Props> = ({
  locale,
  onSubmit,
  editMode,
  youTubeDomain,
  initialValues,
}) => {
  const { domainInfo } = useQueryDomainInfo();
  const mantineTheme = useMantineTheme();
  const { hideModal } = useModal();
  const { searchEngines = [] } = useMetaData();
  const engines = searchEngines?.filter(notEmpty) || [];

  const user = useUser();
  const { featureYandex, featureBaidu, featureYoutube, featureNaver } =
    user.organization?.activePlan || {};

  const validateSettings = (engineList: LocaleDataSearchEngine[]) => {
    const hasSelectedOption =
      engineList?.reduce((acc, searchEngine) => {
        acc = acc || ('searchTypes' in searchEngine && searchEngine.searchTypes.length > 0);
        return acc;
      }, false) ?? false;

    if (!hasSelectedOption) {
      return t('Please select at least one option');
    }
  };

  const Locations = ({
    fields,
    selectedCountry,
    searchEngineValues,
    locations,
  }: {
    fields: FieldArrayRenderProps<string, HTMLDivElement>['fields'];
    selectedCountry: string;
    searchEngineValues: Engine[];
    locations: string[];
  }) => {
    let locationEnabled = false;

    const isGoogle = hasEngine(searchEngineValues, SEARCH_TYPE_GOOGLE);
    const isBing = hasEngine(searchEngineValues, SEARCH_TYPE_BING);
    const isYandex = hasEngine(searchEngineValues, SEARCH_TYPE_YANDEX);

    if (isGoogle || isYandex || (isBing && selectedCountry === 'US')) {
      locationEnabled = true;
    }

    if (!locationEnabled) return null;

    return (
      <div>
        <Label>{t('Location')}</Label>
        <Stack gap="sm">
          {fields.map((name, index) => (
            <Field.LocationField
              name={name}
              key={name}
              disabled={!locationEnabled}
              selectedCountry={selectedCountry}
              canDelete={fields.length || 0 > 1}
              locations={locations}
              onDelete={() => fields.remove(index)}
              placeholder={
                locationEnabled
                  ? t('Enter location (e.g. New York, NY, USA)')
                  : t('Location not supported for selected search engine…')
              }
            />
          ))}

          {locationEnabled && (
            <AccButton
              variant="tertiary"
              disabled={locations.some((location) => !location)}
              leftSection={<IconCirclePlus size={18} />}
              onClick={() => fields.push('')}
              mx="auto"
            >
              {t('Add another location')}
            </AccButton>
          )}
        </Stack>
      </div>
    );
  };

  const handleSubmit = (
    values: InitialEngineValues,
    form: FormApi<InitialEngineValues, Partial<InitialEngineValues>>,
  ) => {
    const valuesWithNoDublicateLocations = {...values, locations: [...new Set(values.locations)] } satisfies InitialEngineValues
    onSubmit(valuesWithNoDublicateLocations, form);
    hideModal();
  };

  return (
    <ModalBorder
      className="add-search-engine"
      title="test"
      header={
        <div className="modal-header">
          <Group wrap="nowrap" gap={8} className="modal-title">
            <Flag size="sm" country={locale.countryCode} fadedColors />
            {locale.region} - {locale.locale}
          </Group>
          <AccText size="sm" ml="auto" c={mantineTheme.colors.gray[5]}>
            ({t('ESC to close')})
          </AccText>
          <AccActionIcon ml={4} onClick={hideModal}>
            <CloseIcon
              fill={mantineTheme.colors.gray[5]}
              width={16}
              height={16}
              className="close-icon"
            />
          </AccActionIcon>
        </div>
      }
      onClose={hideModal}
    >
      <Form<InitialEngineValues>
        onSubmit={handleSubmit}
        subscription={{
          values: true,
          submitting: true,
          submitSucceeded: true,
          submitError: true,
          submitErrors: true,
          submitFailed: true,
          hasSubmitErrors: true,
          pristine: true,
          error: true,
          errors: true,
        }}
        initialValues={{ ...initialValues, countrylocale: locale.id }}
      >
        {({ pristine, submitting, values }) => {
          const showGoogleOptions = hasEngine(values.searchEngines, SEARCH_TYPE_GOOGLE);
          const hasSelectedEngines = values.searchEngines?.length > 0;
          return (
            <>
              <Stack gap="md">
                <div>
                  <Label className="top-label">{t('Search Engines & Devices')}</Label>
                  <Field.SearchSettingsField
                    name="searchEngines"
                    validate={validateSettings}
                    locale={locale}
                    showYandex={featureYandex}
                    showBaidu={featureBaidu}
                    showYouTube={featureYoutube}
                    showNaver={featureNaver}
                    youTubeDomain={youTubeDomain}
                    domainType={domainInfo?.domainType}
                  />
                </div>
                {hasSelectedEngines && (
                  <FieldArray<string>
                    name="locations"
                    disabled={true}
                    placeholder={t('Enter location (e.g. New York, NY, USA)')}
                    engines={engines}
                  >
                    {({ fields }) => {
                      return (
                        <Locations
                          fields={fields}
                          locations={values.locations}
                          selectedCountry={locale.countryCode}
                          searchEngineValues={values.searchEngines}
                        />
                      );
                    }}
                  </FieldArray>
                )}
                {showGoogleOptions && (
                  <Field.SearchEngineSettings
                    name="settings"
                    label={t('Google Settings')}
                    showGoogleOptions={showGoogleOptions}
                  />
                )}
              </Stack>
              <ModalFooter
                primaryButtonSlot={
                  <AccButton type="submit" variant="primary" disabled={pristine || submitting}>
                    {editMode ? t('Edit locale') : t('Add locale')}
                  </AccButton>
                }
                secondaryButtonSlot={
                  <AccButton variant="tertiary" onClick={hideModal}>
                    {t('Cancel')}
                  </AccButton>
                }
              />
            </>
          );
        }}
      </Form>
    </ModalBorder>
  );
};

export default AddSearchEngine;
