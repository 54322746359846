import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

type Props = {
  accountId: string;
  updateConnection: (...args: Array<any>) => any;
  bigQueryConnections?: any;
  handleSubmit: (...args: Array<any>) => any;
  submitting: boolean;
  refresh?: () => void;
};

const EditBigQueryAccount = ({
  accountId,
  updateConnection,
  handleSubmit,
  submitting,
  refresh,
  bigQueryConnections,
}: Props) => {
  const { hideModal } = useModal();
  const onSubmit = ({ description }) => {
    return updateConnection({
      variables: {
        input: {
          id: accountId,
          description,
          delete: false,
        },
      },
    })
      .then(
        ({
          data: {
            updateGoogleBigqueryConnection: { errors },
          },
        }) => {
          if (errors && errors.length) {
            Validator.throwSubmissionError(errors);
          } else {
            hideModal();
            refresh?.();
            bigQueryConnections?.refetch();
          }
        },
      )
      .catch((error) => {
        toast.error(t('Failed to update'));
        throw error;
      });
  };

  return (
    <ModalBorder
      className="edit-adobe-account"
      onClose={submitting ? hideModal : undefined}
      title={t('Edit Adobe Account')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrors />
        <div className="form-label required">{t('Description')}</div>
        <Field
          name="description"
          placeholder={t('Description')}
          component={TextField}
          disabled={submitting}
          validate={Validator.required}
          autoFocus={true}
        />
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" disabled={submitting} type="submit">
              {t('Edit connection')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" disabled={submitting} onClick={hideModal}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    </ModalBorder>
  );
};

const bigQueryConnectionsQuery = gql`
  query bigQueryConnections {
    user {
      id
      organization {
        id
        googleBigqueryConnections {
          id
          description
        }
      }
    }
  }
`;
const updateConnectionQuery = gql`
  mutation updateBigQueryConnection($input: UpdateGoogleBigQueryConnectionInput!) {
    updateGoogleBigqueryConnection(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export default compose(
  graphql(bigQueryConnectionsQuery, {
    name: 'bigQueryConnections',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({ ownProps, bigQueryConnections }: any) => {
      const { accountId } = ownProps;
      let description = '';
      if (!bigQueryConnections.loading && !bigQueryConnections.error) {
        const connection =
          bigQueryConnections.user &&
          bigQueryConnections.user.organization.googleBigqueryConnections.find(
            (conn) => conn.id === accountId,
          );
        description = connection ? connection.description : '';
      }

      return {
        ...ownProps,
        initialValues: {
          description,
        },
        bigQueryConnections,
      };
    },
  }),
  graphql(updateConnectionQuery, {
    name: 'updateConnection',
  }),
)(
  reduxForm({
    form: 'EditBigQueryAccount',
    enableReinitialize: true,
  })(EditBigQueryAccount),
);
