import { Flex, Group } from '@mantine/core';
import { IconLeaf } from '@tabler/icons-react';
import { Field } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { useGetGoogleBigQueryLocationsQuery } from 'Ghql';
import { t } from 'Utilities/i18n';
import validation from 'Utilities/validation';

type InputLocationProps = {
  disabled?: boolean;
};
const InputLocation = (props: InputLocationProps) => {
  const { disabled } = props;
  const { data, loading } = useGetGoogleBigQueryLocationsQuery();
  const options =
    data?.bigqueryLocations?.map((location) => ({
      label: location?.locationName ?? '',
      value: location?.location ?? '',
      isEco: !!location?.details,
    })) || [];
  return (
    <Field.Select
      name="datasetLocation"
      label={t('Data location')}
      placeholder={t('Location')}
      options={options}
      searchable={false}
      validate={[validation.required]}
      isLoading={loading}
      disabled={disabled}
      itemComponent={({ label, value, ...rest }) => {
        const isEco = 'isEco' in rest ? (rest.isEco as boolean) : false;
        return (
          <Flex align="center" pos="relative">
            <AccText as="span" size="sm">
              {label}
              <AccText ml="xs" as="span" size="xs" c="gray.5">
                {value}
              </AccText>
            </AccText>

            {isEco && (
              <Group ml="auto" c="green.5" gap="xxs" align="center" h="full">
                <IconLeaf size={16} />
                <AccText size="xs" c="gray.5">
                  {t('Low CO₂')}
                </AccText>
              </Group>
            )}
          </Flex>
        );
      }}
      required
    />
  );
};

export default InputLocation;
