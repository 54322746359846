import React, { useMemo } from 'react';
import { Box, Group } from '@mantine/core';
import isNil from 'lodash/isNil';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber, { FormatNumberAuto } from 'Components/FormatNumber/new';
import { useTableSetting } from 'Components/Modal/Content/TableSettingsModal/support/config';
import { useStateColumns } from 'Components/Modal/Content/TableSettingsModal/support/hooks/useStateColumns';
import { TreeRowComponent } from 'Components/Table/TreeTable';
import { HeaderProps } from 'Components/Table/TreeTable/support/types';
import { withScrollOptimize } from 'Components/Table/VirtualizedTable';
import { FolderMetric } from 'Ghql';
import { useIsPercentage } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useSelectedNodes } from 'Hooks/useSelectedNodes';
import { HomeTreeViewColumnID } from 'Pages/Domains/TreeView/support/constants';
import HeaderColumn from 'Pages/Keywords/Groupings/ViewMode/components/HeaderColumn';
import { DeviceCell } from 'Pages/Keywords/Groupings/ViewMode/components/cells/DeviceCell';
import { DiscoveredKeywordsCell } from 'Pages/Keywords/Groupings/ViewMode/components/cells/DiscoveredKeywordsCell';
import KeywordsCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/KeywordsCell';
import ValueAndIndicatorCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/ValueAndIndicatorCell';
import {
  CellModalLink,
  TableColumnConfig,
} from 'Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import { TreeTableHeaderColumnWithSearch } from 'Pages/SiteMapping/components/TreeTable/Header';
import { TableIDs } from 'Types/Table';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import styles from 'css/modules/treeTableHeader.module.scss';
import AboveTheFoldIcon from 'icons/abovethefold.svg';
import { CountryCell } from './Components/Cells/CountryCell';
import MaxCtrCell from './Components/Cells/MaxCtrCell';
import PathCell from './Components/Cells/PathCell';
import RowLoadingCell, { CellSkeleton } from './Components/Cells/RowLoadingCell';
import {
  ProcessedDashboardTreeNode,
  ProcessedFolderTreeNode,
  ProcessedTreeDomainNode,
} from './support/types';

export type TreeViewRootNode =
  | ProcessedFolderTreeNode
  | ProcessedDashboardTreeNode
  | ProcessedTreeDomainNode;

type TreeViewClassNames = {
  checkbox: string;
  noWrap: string;
  rightTextAlign: string;
};

export type CellRendererProps = {
  classes: Partial<TreeViewClassNames>;
  rootNode: ProcessedFolderTreeNode;
  isOpen: boolean;
  isHovered?: boolean;
  isSelected?: boolean;
  isSelectLimitReached: boolean;
  onToggle: any;
  className?: string;
  hideConnectors?: string[];
  expandDataKey: string;
  isDemoDomain: boolean;
  selectLimit?: number;
  updateSelectedNode: (
    node: TreeViewRootNode,
    event: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent,
  ) => void;
  onDragEnd?: (event: any, text?) => void;
};

function useTableSettings() {
  const { selector, columns } = useTableSetting(TableIDs.HOME_TREE_VIEW);
  const tableSettings = useStateColumns(selector);

  return useMemo(() => {
    if (isNil(tableSettings)) {
      return columns
        ?.filter((column) => !column.tableSetting?.defaultHide)
        ?.map((column) => column.id) as string[];
    }
    return tableSettings;
  }, [tableSettings]);
}

const useLoadingStateColumns = (): TableColumnConfig<CellRendererProps>[] => {
  const tableSettings = useTableSettings();
  return [
    {
      id: HomeTreeViewColumnID.CHECKBOX,
      width: '45px',
      cellRenderer: () => null,
      sticky: true,
    },
    {
      id: 'row-loading-cell',
      title: t('Group'),
      orderBy: 'group',
      width: 'minmax(300px, 1.5fr)',
      cellRenderer: (props: CellRendererProps) => (
        <RowLoadingCell key="row-loading-cell" {...props} />
      ),
      sticky: true,
    },
    ...tableSettings.slice(2, tableSettings.length).map((column, idx) => ({
      id: column,
      cellRenderer: () => <CellSkeleton key={idx} />,
    })),
  ];
};

const useTableColumnsConfig = (): TableColumnConfig<CellRendererProps>[] => {
  const tableSettings = useTableSettings();
  const isPercentage = useIsPercentage();
  const { setSelectedNodes } = useSelectedNodes();

  const tableConfig: TableColumnConfig<CellRendererProps>[] = useMemo(
    () => [
      {
        id: HomeTreeViewColumnID.CHECKBOX,
        width: '45px',
        cellRenderer: () => null,
        sticky: true,
      },
      {
        id: HomeTreeViewColumnID.NAME,
        title: t('Group'),
        orderBy: 'name',
        width: 'minmax(360px, 1.5fr)',
        justify: 'start',
        cellRenderer: (props) => <PathCell key={HomeTreeViewColumnID.NAME} {...props} />,
        sticky: true,
      },
      {
        id: HomeTreeViewColumnID.COUNTRY,
        title: t('Country'),
        // orderBy: 'country',
        width: '136px',
        justify: 'start',
        cellRenderer: ({ rootNode }) => {
          const countryCodes =
            'country_code' in rootNode ? (rootNode.country_code as string | string[]) : undefined;
          return (
            <CountryCell countryCodes={countryCodes || []} key={HomeTreeViewColumnID.COUNTRY} />
          );
        },
      },
      {
        id: HomeTreeViewColumnID.DEVICE,
        title: t('Device'),
        // orderBy: 'mobile_percentage',
        width: 'minmax(142px, 0.7fr)',
        cellRenderer: (props) => {
          return <DeviceCell {...props} key={HomeTreeViewColumnID.DEVICE} />;
        },
      },
      {
        id: HomeTreeViewColumnID.KEYWORDS,
        title: t('Keywords'),
        orderBy: 'keywords',
        width: 'minmax(142px, 0.7fr)',
        cellRenderer: (props) => {
          return <KeywordsCell {...props} key={HomeTreeViewColumnID.KEYWORDS} />;
        },
      },
      {
        id: HomeTreeViewColumnID.DISCOVERED_KEYWORDS,
        title: t('Disc. keywords'),
        // orderBy: 'keydis_count',
        width: 'minmax(142px, 0.8fr)',
        cellRenderer: (props) => {
          return (
            <DiscoveredKeywordsCell {...props} key={HomeTreeViewColumnID.DISCOVERED_KEYWORDS} />
          );
        },
      },
      {
        id: HomeTreeViewColumnID.AI_SHARE_OF_VOICE,
        title: t('AI SoV'),
        orderBy: 'ai_share_of_voice',
        orderByChange: 'ai_share_of_voice_change',
        width: 'minmax(192px, 0.9fr)',
        cellRenderer: (props) => {
          return (
            <ValueAndIndicatorCell
              className={props.classes.rightTextAlign}
              key={HomeTreeViewColumnID.AI_SHARE_OF_VOICE}
              defaultTab={FolderMetric.EstimatedVisits}
              rootNode={props.rootNode}
              disabled={isNil(props.rootNode.ai_share_of_voice)}
              beforeValue={props.rootNode.ai_share_of_voice_compare}
              value={props.rootNode.ai_share_of_voice}
              maxValue={props.rootNode.max_ai_share_of_voice}
              onClick={() => setSelectedNodes?.([props.rootNode])}
              showProgessOnEmpty
            >
              <AccTooltip tooltip={t('Show history for AI Share of Voice')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.ai_share_of_voice}
                    maximumFractionDigits={1}
                    minimumFractionDigits={0}
                  />
                </span>
              </AccTooltip>
            </ValueAndIndicatorCell>
          );
        },
      },
      {
        id: HomeTreeViewColumnID.SHARE_OF_VOICE,
        title: t('SoV'),
        orderBy: 'share_of_voice_value',
        orderByChange: 'share_of_voice_change',
        width: 'minmax(192px, 0.9fr)',
        cellRenderer: ({
          rootNode,
          classes,
        }: {
          rootNode: FolderType | TreeViewRootNode;
          classes: Partial<TreeViewClassNames>;
        }) => {
          const value =
            'share_of_voice_value' in rootNode
              ? rootNode.share_of_voice_value
              : rootNode.share_of_voice;
          const beforeValue =
            'share_of_voice_value_compare' in rootNode
              ? rootNode.share_of_voice_value_compare
              : rootNode.share_of_voice_compare;
          const showPercentage =
            'share_of_voice_is_percentage' in rootNode
              ? rootNode.share_of_voice_is_percentage
              : isPercentage;
          return (
            <ValueAndIndicatorCell
              className={classes.rightTextAlign}
              key={HomeTreeViewColumnID.SHARE_OF_VOICE}
              defaultTab={FolderMetric.ShareOfVoice}
              rootNode={rootNode}
              disabled={isNil(value)}
              beforeValue={beforeValue}
              value={value}
              maxValue={rootNode.max_share_of_voice}
              indicatorStyle={showPercentage ? 'percent' : 'decimal'}
              onClick={() => setSelectedNodes?.([rootNode])}
              showProgessOnEmpty
            >
              <AccTooltip tooltip={t('Show history for Share of Voice')}>
                <span>
                  <FormatNumberAuto
                    value={value}
                    maximumFractionDigits={1}
                    minimumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            </ValueAndIndicatorCell>
          );
        },
      },
      {
        id: HomeTreeViewColumnID.AVG_RANK_VALUE,
        title: t('Avg. rank'),
        orderBy: 'avg_rank',
        orderByChange: 'avg_rank_change',
        width: 'minmax(148px, 0.7fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            key={HomeTreeViewColumnID.AVG_RANK_VALUE}
            defaultTab={FolderMetric.AvgRank}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            disabled={!props.rootNode.avg_rank}
            beforeValue={props.rootNode.avg_rank_compare || null}
            value={props.rootNode.avg_rank}
            negate={true}
            maxDecimals={1}
            onClick={() => setSelectedNodes?.([props.rootNode])}
          >
            {props.rootNode.avg_rank !== 0 ? (
              <AccTooltip tooltip={t('Show history for average rank')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.avg_rank}
                    minimumFractionDigits={1}
                    maximumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            ) : (
              t('Not in top 100')
            )}
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: HomeTreeViewColumnID.AVG_BASE_RANK,
        title: t('Avg. base rank'),
        orderBy: 'avg_base_rank',
        orderByChange: 'avg_base_rank_change',
        width: 'minmax(152px, 0.7fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            key={HomeTreeViewColumnID.AVG_BASE_RANK}
            defaultTab={FolderMetric.AvgBaseRank}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            disabled={!props.rootNode.avg_base_rank}
            beforeValue={props.rootNode.avg_base_rank_compare || null}
            value={props.rootNode.avg_base_rank}
            negate={true}
            maxDecimals={1}
            onClick={() => setSelectedNodes?.([props.rootNode])}
          >
            {props.rootNode.avg_base_rank !== 0 ? (
              <AccTooltip tooltip={t('Show history for average base rank')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.avg_base_rank}
                    minimumFractionDigits={1}
                    maximumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            ) : (
              t('Not in top 100')
            )}
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: HomeTreeViewColumnID.SEARCH_VOLUME,
        title: t('Searches'),
        orderBy: 'search_volume_value',
        width: 'minmax(120px, 1fr)',
        cellRenderer: (props) => {
          return (
            <ValueAndIndicatorCell
              value={
                props.rootNode.search_volume_value ||
                ('search_volume' in props.rootNode
                  ? (props.rootNode.search_volume as number)
                  : null)
              }
              beforeValue={
                'search_volume_compare' in props.rootNode
                  ? (props.rootNode.search_volume_compare as number)
                  : null
              }
              className={props.classes.rightTextAlign}
              key={HomeTreeViewColumnID.SEARCH_VOLUME}
              defaultTab={FolderMetric.SearchVolume}
              rootNode={props.rootNode}
              disabled={
                ('search_volume_value' in props.rootNode &&
                  isNil(props.rootNode.search_volume_value)) ||
                ('search_volume' in props.rootNode && isNil(props.rootNode.search_volume))
              }
              onClick={() => setSelectedNodes?.([props.rootNode])}
            >
              <AccTooltip tooltip={t('Show history for search volume')}>
                <span>
                  <FormatNumber
                    value={
                      props.rootNode.search_volume_value ||
                      ('search_volume' in props.rootNode
                        ? (props.rootNode.search_volume as number)
                        : null)
                    }
                    maximumFractionDigits={1}
                    minimumFractionDigits={0}
                  />
                </span>
              </AccTooltip>
            </ValueAndIndicatorCell>
          );
        },
      },
      {
        id: HomeTreeViewColumnID.AI_TRAFFIC_VALUE,
        title: t('AI Traf. val'),
        orderBy: 'ai_traffic_value',
        orderByChange: 'ai_traffic_value_change',
        withCurrency: true,
        width: 'minmax(180px, 0.9fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            defaultTab={FolderMetric.AiTrafficValue}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={HomeTreeViewColumnID.AI_TRAFFIC_VALUE}
            disabled={!props.rootNode.ai_traffic_value}
            beforeValue={props.rootNode.ai_traffic_value_compare}
            value={props.rootNode.ai_traffic_value}
            maxValue={props.rootNode.max_ai_traffic_value}
            onClick={() => setSelectedNodes?.([props.rootNode])}
            showProgessOnEmpty
          >
            <AccTooltip
              disable={!props.rootNode.ai_traffic_value}
              tooltip={t('Show history for AI Traffic value')}
            >
              <span>
                <FormatNumber
                  value={props.rootNode.ai_traffic_value}
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: HomeTreeViewColumnID.TRAFFIC_VALUE,
        title: t('Traf. val'),
        orderBy: 'traffic_value',
        orderByChange: 'traffic_value_change',
        withCurrency: true,
        width: 'minmax(180px, 0.9fr)',
        cellRenderer: (props) => (
          <ValueAndIndicatorCell
            defaultTab={FolderMetric.TrafficValue}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={HomeTreeViewColumnID.TRAFFIC_VALUE}
            disabled={!props.rootNode.traffic_value}
            beforeValue={props.rootNode.traffic_value_compare}
            value={props.rootNode.traffic_value}
            maxValue={props.rootNode.max_traffic_value}
            onClick={() => setSelectedNodes?.([props.rootNode])}
            showProgessOnEmpty
          >
            <FormatNumber
              value={props.rootNode.traffic_value}
              maximumFractionDigits={1}
              minimumFractionDigits={1}
            />
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: HomeTreeViewColumnID.DYNAMIC_CTR,
        title: t('Avg. CTR'),
        orderBy: 'dynamic_ctr_value',
        orderByChange: 'traffic_value_change',
        width: 'minmax(145px, 0.7fr)',
        cellRenderer: (props) => {
          const value =
            'ctr' in props.rootNode
              ? (props.rootNode.ctr as number)
              : props.rootNode.dynamic_ctr_value;

          const maxValue =
            'ctr_max' in props.rootNode
              ? (props.rootNode.ctr_max as number)
              : props.rootNode.dynamic_ctr_max_value;

          return (
            <ValueAndIndicatorCell
              className={props.classes.rightTextAlign}
              defaultTab={FolderMetric.AvgCtr}
              key={HomeTreeViewColumnID.DYNAMIC_CTR}
              rootNode={props.rootNode}
              disabled={isNil(value)}
              beforeValue={
                'ctr_compare' in props.rootNode
                  ? (props.rootNode.ctr_compare as number)
                  : props.rootNode.dynamic_ctr_compare
              }
              value={value}
              indicatorStyle="percent"
              maxValue={maxValue}
              onClick={() => setSelectedNodes?.([props.rootNode])}
              showProgessOnEmpty
            >
              <AccTooltip disable={isNil(value)} tooltip={t('Show history for average CTR')}>
                <span>
                  <FormatNumber
                    value={value}
                    style="percent"
                    maximumFractionDigits={1}
                    minimumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            </ValueAndIndicatorCell>
          );
        },
      },
      {
        id: HomeTreeViewColumnID.DYNAMIC_CTR_MAX,
        title: t('Avg. Max CTR'),
        orderBy: 'dynamic_ctr_max_value',
        width: 'minmax(145px, 0.7fr)',
        cellRenderer: ({ rootNode }) => {
          const isGroupOrDomainLevel =
            ('is_group' in rootNode && (rootNode.is_group as boolean)) ||
            ('is_domain' in rootNode && (rootNode.is_domain as boolean));
          return (
            <MaxCtrCell
              key={HomeTreeViewColumnID.DYNAMIC_CTR_MAX}
              maxCtr={
                'ctr_max' in rootNode
                  ? (rootNode.ctr_max as number)
                  : rootNode.dynamic_ctr_max_value
              }
              path={rootNode.path}
              groupOrDomainLevel={isGroupOrDomainLevel}
            />
          );
        },
      },
      {
        id: HomeTreeViewColumnID.ABOVE_THE_FOLD,
        title: t('Above the Fold'),
        orderBy: 'above_the_fold',
        width: 'minmax(145px, 0.8fr)',
        cellRenderer: (props) => {
          return (
            <Group gap="xxs" justify="flex-end">
              <CellModalLink
                defaultTab={FolderMetric.AboveTheFold}
                rootNode={props.rootNode}
                className={props.classes.rightTextAlign}
                key={HomeTreeViewColumnID.ABOVE_THE_FOLD}
                disabled={isNil(props.rootNode.above_the_fold)}
                tooltip={t('Show history for Above the fold')}
              >
                <FormatNumber value={props.rootNode.above_the_fold} />
              </CellModalLink>

              <AccFastIcon
                fit="contain"
                src={AboveTheFoldIcon}
                color="#f89537"
                tooltip={t('Above the fold')}
                size={16}
              />
            </Group>
          );
        },
      },
    ],
    [isPercentage],
  );
  return useMemo(() => {
    return tableConfig
      .filter((column) => (tableSettings as string[])?.includes(column.id))
      .map((e) => {
        // Always render these columns to avoid flickering
        if (['name', 'country', 'checkbox'].includes(e.id)) {
          return e;
        }
        return {
          ...e,
          cellRenderer: withScrollOptimize(e.cellRenderer),
        };
      });
  }, [tableSettings]);
};

const renderCell = ({ cellRenderer: CellRenderer, id }, props) => {
  if (!CellRenderer) return <React.Fragment key={id} />;
  return <CellRenderer key={id} {...props} />;
};

const RowTemplate: TreeRowComponent = (props: any) => {
  const columnConfigs = useTableColumnsConfig();
  const loadingStateColumns = useLoadingStateColumns();
  const render = (config) => renderCell(config, props);
  if (props?.rootNode?.state?.loading) {
    return <>{loadingStateColumns.map(render)}</>;
  }
  return <>{columnConfigs.map(render)}</>;
};

const Header = React.memo(
  ({ submitSearch, className, activeSearch, setOrderBy, order, orderBy }: HeaderProps) => {
    const { displayCurrency } = useDisplayCurrency();
    const columnConfigs = useTableColumnsConfig();
    const gridTableRows = `${columnConfigs.map((c) => c.width).join(' ')}`;
    return (
      <Box className={className} style={{ display: 'grid', gridTemplateColumns: gridTableRows }}>
        <div
          className={styles.headerCell}
          style={{ position: 'sticky', left: 0, zIndex: 3, width: '45px' }}
        />
        <TreeTableHeaderColumnWithSearch
          name={t('Group')}
          submitSearch={submitSearch}
          activeSearch={activeSearch || ''}
          currentOrder={order}
          currentOrderBy={orderBy}
          orderBy={'group'}
          setOrderBy={setOrderBy}
          left={45}
        />
        {columnConfigs.slice(2, columnConfigs.length + 1).map((header) => (
          <HeaderColumn
            key={`${header.title}-${header.id}`}
            header={header}
            content={`${header.title} ${
              header.withCurrency && displayCurrency ? `(${displayCurrency})` : ''
            }`}
            setOrderBy={setOrderBy}
            order={order}
            orderBy={orderBy}
          />
        ))}
      </Box>
    );
  },
  propsIsEqualComparison,
);
Header.displayName = 'Header';

export function useTreeViewCompareTableConfig() {
  const headerConfig = useTableColumnsConfig();

  const gridTableRows = `${headerConfig.map((c) => c.width).join(' ')}`;

  return {
    Header,
    RowTemplate,
    gridTableRows,
  };
}
