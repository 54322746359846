import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import config from 'config';
import AccButton from 'Components/AccButton/AccButton';
import IntercomButton from 'Components/Intercom/IntercomButton';
import SimpleModal from 'Components/SimpleModal';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useLoginTwoFactorMutation, useLoginUserMutation } from 'Ghql';
import { extractFormErrors } from 'Hooks/Graphql/helpers';
import { useActions } from 'Hooks/redux/useActions';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { extractNextPath } from 'Utilities/PrivateRoute/private-routes';
import { t, tct } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import AuthPageContainer from '../Shared/AuthPageContainer/AuthPageContainer';
import AuthTokenForm, { AuthTokenFormValues } from './components/AuthTokenForm';
import LoginForm, { LoginFormValues } from './components/LoginForm';
import { initUser } from './service';
import styles from './login-page.module.scss';

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const actions = useActions({ initUser });
  const trackEvent = useMixpanel();

  const [login] = useLoginUserMutation();
  const [loginTwoFactor] = useLoginTwoFactorMutation();
  const [[authTokenStep, initValues], setAuthStepInfo] = useState<
    [boolean, AuthTokenFormValues | null]
  >([false, null]);

  const [userNotFoundModalOpened, setUserNotFoundModalOpened] = useState(false);
  const [userNotFoundEmail, setUserNotFoundEmail] = useState<string | null>();
  useEffect(() => {
    // check if query params contains user-not-found key
    // and check if query params contains email, if so get its value
    const queryParams = new URLSearchParams(location.search);
    const userNotFound = queryParams.has('user-not-found');
    if (userNotFound) {
      setUserNotFoundModalOpened(true);
      const email = queryParams.get('email');
      if (email) {
        setUserNotFoundEmail(email);
      }
    }
  }, []);

  const onSuccessLogin = async () => {
    trackEvent(EventName.Login);
    const savedPath = extractNextPath(location);

    if (savedPath?.includes('/oauth/authorize/?')) {
      return redirectToExternalUrl(savedPath);
    }
    await actions.initUser(() => {
      navigate(savedPath || '/', { replace: true });
    });
  };

  const onLoginTwoFactor = async (form: AuthTokenFormValues) => {
    const { errors } = await loginTwoFactor({
      variables: {
        input: { ...form, ...initValues },
      },
    });

    if (errors) {
      return errors;
    }

    await onSuccessLogin();
  };

  const onLogin = async (form: LoginFormValues) => {
    const res = await login({ variables: { input: form } });
    const formErrors = extractFormErrors(res);

    if (res?.data?.userLogin?.otpRequired && !authTokenStep) {
      return setAuthStepInfo([true, form]);
    } else if (formErrors) {
      return formErrors;
    }

    await onSuccessLogin();
  };

  const onLoginBack = (event: React.MouseEvent) => {
    event.preventDefault();
    setAuthStepInfo([false, null]);
  };
  const {
    other: { gutterSm },
  } = useMantineTheme();
  return (
    <>
      <AuthPageContainer
        heroText={t('The World\'s Fastest & Most Accurate Rank Tracker')}
        formHeader={
          <AccTitle type="h2" ta="center">
            {t('Sign in to AccuRanker')}
          </AccTitle>
        }
      >
        <Flex gap="md" direction="column">
          {!authTokenStep ? (
            <LoginForm onSubmit={onLogin} />
          ) : (
            <AuthTokenForm onSubmit={onLoginTwoFactor} onBack={onLoginBack} />
          )}
        </Flex>

        <SimpleModal
          opened={userNotFoundModalOpened}
          setOpened={setUserNotFoundModalOpened}
          onClose={() => {
            // remove query params from url
            navigate(location.pathname, { replace: true });
          }}
          title={t('User not found')}
        >
          <Flex align="center" rowGap={gutterSm} direction="column">
            <AccText>
              {tct(
                'We\'re unable to find an account associated with the email address[userNotFoundEmail].' +
                  '[gutterSm]' +
                  'To create a new account, or to sign up with a different email address, please click the button below.',
                {
                  userNotFoundEmail: userNotFoundEmail ? <b>{` ${userNotFoundEmail}`}</b> : '',
                  gutterSm: <Box mt={gutterSm} />,
                },
              )}
            </AccText>
            <a href={config.registerUrl}>
              <AccButton variant="primary">{t('Sign up')}</AccButton>
            </a>
          </Flex>
        </SimpleModal>
        <div className={styles.intercomContainer}>
          <IntercomButton size="lg" />
        </div>
      </AuthPageContainer>
    </>
  );
}

export default LoginPage;
