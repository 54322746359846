import { useApolloClient } from '@apollo/client';
import { useGroupsListQuery, useQuickNavigationDataQuery } from 'Ghql';

export const useRefetchGroupData = () => {
  const { refetch: refetchGroupIds } = useGroupsListQuery({ skip: true });
  const { refetch: refetchQuickNavigationData } = useQuickNavigationDataQuery({ skip: true });
  const client = useApolloClient();
  const refetchGroupData = async () => {
    // Update groupdata for treeview
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'treeDashboard' });
    client.cache.gc();
    await refetchQuickNavigationData();
    const data = await refetchGroupIds();
    return data;
  };
  return refetchGroupData;
};
