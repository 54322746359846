import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { IconPlugConnected } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import { removeGSCAccountQuery } from 'Pages/DomainSettings/support/queries';
import { t } from 'Utilities/i18n';

type SearchConsoleIntegrationButtonsProps = {
  refresh?: () => void;
};

export const SearchConsoleIntegrationButtons = ({
  refresh,
}: SearchConsoleIntegrationButtonsProps): JSX.Element | null => {
  const domainId = useDomainId();
  const { domainInfo, refetch: refreshDomainInfo, isDemoDomain } = useQueryDomainInfo();
  const disabledDemoText = getDisabledDemoText();
  const { showModal, hideModal } = useModal();
  const isConnected = Boolean(domainInfo?.isGscConnected);
  const [removeGSCAccount] = useMutation(removeGSCAccountQuery);

  const handleRefresh = useCallback(() => {
    refresh?.();
    if (domainId) refreshDomainInfo();
  }, [refreshDomainInfo, refresh, domainId]);

  const handleConnectToGSC = () =>
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: handleRefresh,
      },
    });

  const handleDisconnectFromGSC = () => {
    removeGSCAccount({ variables: { input: { domainId } } }).then(
      () => {
        toast.success(t('Account removed'));
        handleRefresh();
        hideModal();
      },
      () => {
        toast.error(t('Failed to remove account'));
      },
    );
  };
  if (!isConnected) {
    return (
      <AccButton
        onClick={handleConnectToGSC}
        leftSection={<IconPlugConnected size={18} />}
        variant="secondary"
        disabled={isDemoDomain}
        tooltip={isDemoDomain ? disabledDemoText : undefined}
      >
        {t('Connect to Search Console')}
      </AccButton>
    );
  }
  return (
    <AccButton
      onClick={handleDisconnectFromGSC}
      leftSection={<IconPlugConnected size={18} />}
      variant="secondary"
      disabled={isDemoDomain}
      tooltip={isDemoDomain ? disabledDemoText : undefined}
    >
      {t('Disconnect from GSC')}
    </AccButton>
  );
};
