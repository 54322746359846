import { browserName, browserVersion, deviceType, osName } from 'react-device-detect';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { decodeFilters } from 'Components/Filters/serialization';
import {
  AccurankerAnalyticsInput,
  AccurankerAnalyticsOtherPropertyInput,
  MixpanelTrackEventDocument,
  useMixpanelTrackEventMutation,
} from 'Ghql';
import { useDomainId, useIsGroupDomains } from 'Hooks/data/domain/useQueryDomainInfo';
import { apolloClient } from 'Store';
import { FilterAttributetype } from 'Types/Filter';
import { getRoutePath } from 'Utilities/Router/helpers';
import { trimTrailingSlash } from 'Utilities/underdash';

export enum EventName {
  Login = 'Login',
  ButtonClick = 'Button Click',
  AddKeywordsModalOpen = 'Add Keywords modal open',
  KeywordOveruseShowConfirmation = 'Keyword Overuse show confirmation',
  AddKeywordsSuccess = 'Add Keywords success',
  AddDomainFailed = 'Add Domain failed',
  AddDomainSuccess = 'Add Domain success',
  AddFilter = 'Add Filter',
  ConfirmationModalOpen = 'Confirmation modal open',
  ConfirmationModalCancel = 'Confirmation modal cancel',
  ConfirmationModalOK = 'Confirmation modal OK',
  ModalSubmit = 'Modal Submit',
  EnableOveruseActiveLimitModalOpen = 'Enable Overuse Active Limit modal open',
  EnableOveruseTrackedLimitModalOpen = 'Enable Overuse Tracked Limit modal open',
  RequestEnableOveruseActiveLimitModalOpen = 'Request Enable Overuse Active Limit modal open',
  RequestEnableOveruseTrackedLimitModalOpen = 'Request Enable Overuse Tracked Limit modal open',
  UpgradePlanActiveLimitModalOpen = 'Upgrade Plan Active Limit modal open',
  UpgradePlanTrackedLimitModalOpen = 'Upgrade Plan Tracked Limit modal open',
  RequestUpgradePlanActiveLimitModalOpen = 'Request Upgrade Plan Active Limit modal open',
  RequestUpgradePlanTrackedLimitModalOpen = 'Request Upgrade Plan Tracked Limit modal open',
  DeleteDomainFailed = 'Delete Domain failed',
  DeleteDomainSuccess = 'Delete Domain success',
  PageView = 'Page View',
  TableSorting = 'Table Sorting',
  PaymentSuccess = 'Payment Successful',
  PaymentSuccessModal = 'Payment Success modal click',
  ShowSerpResult = 'Show SERP result',
  RefreshKeywordSingle = 'Refresh keyword single',
  RefreshKeywordsDomain = 'Refresh keywords domain',
  CreateDynamicTag = 'Create dynamic tag',
  EditDynamicTag = 'Edit dynamic tag',
  ChangeAccountDropdown = 'Change account dropdown',
  QuickNavigateOpened = 'Quick navigate opened',
  AddDomainFromQuickNavigate = 'Add domain from quick navigate',
  AddGroupFromQuickNavigate = 'Add group from quick navigate',
  ChangeDomainFromQuickNavigate = 'Change domain from quick navigate',
  PwnedPasswordFlagged = 'Password was flagged in pwned Passwords',
  PwnedPasswordError = 'Pwned password request error',
  DomainValidationFailed = 'Domain validation failed',
  DomainValidationSuccess = 'Domain validation success',
  IntegrationCardClick = 'Integration card click',
  RegisterFailed = 'Register failed',
  ConnectToGA = 'Connect to GA',
  ConnectToGSC = 'Connect to GSC',
  ConnectToBigQuery = 'Connect to BigQuery',
  AddNote = 'Add note',
  CustomizeDashboardDrag = 'Customize dashboard drag',
  FeatureCallout = 'Feature callout',
  OpenQuickNavigation = 'Open quick navigation',
  EditKPI = 'Edit KPI',
  EditWidget = 'Edit widget',
  UsersPage = 'Users page',
  WorkspacePage = 'Workspace page',
  HomeCardviewMaxCompareReached = 'Home cardview max compare reached',
  DomainsComparisonMaxCompareReached = 'Domains comparison max compare reached',
  RegisterPagePhoneVerificationFailed = 'Register page phone verification failed',
  RegisterPagePhoneVerificationSuccess = 'Register page phone verification success',
}

// For AccButton. Tracking key send to mixPanel.
export enum TrackingKey {
  InboxViewReadMessages = 'Inbox - View read messages',
  SelectPlan = 'Select Plan',
  Checkout = 'Checkout',
  AddAction = 'Add action',
  EditAction = 'Edit action',
  RefreshAction = 'Refresh action',
  CopyAction = 'Copy action',
  TableSettings = 'Table settings',
  DownloadReport = 'Download report',
  HelpGuide = 'Help guide',
  CompareSelected = 'Compare selected',
  EditFolders = 'Edit folders',
  AddDomainFromKeywordDiscovery = 'Add domain from keyword discovery',
  AddKeywordsFromKeywordDiscovery = 'Add keywords from keyword discovery',
  NavigateToDomainFromBubbleChart = 'Navigate to domain from bubblechart',
  ChangePassword = 'Change Password',
  AddStaticCompetitor = 'Add static competitor',
  GSCWebsite = 'GSC Website',
  SelectBigQueryAccount = 'Select BigQuery Account',
  SaveBigQueryAccount = 'Save BigQuery Account',
  SelectGoogleAccount = 'Select Google Account',
  ConnectOAuthAccount = 'Connect OAuth Account',
  SaveGoogleAccount = 'Save Google Account',
  BigQueryConnectOpenModal = 'BigQuery Connect Open Modal',
  BigQueryDisconnect = 'BigQuery Disconnect',
  BigQueryBackfillOpenModal = 'BigQuery Backfill Open Modal',
  BigQueryBackfillStart = 'BigQuery Backfill Start',
  OpenBreadcrumbPopover = 'Open breadcrumb popover',
  OpenCardviewSelectGroupPopover = 'Open cardview select group popover',
  OpenCardviewSelectDomainPopover = 'Open cardview select domain popover',
  OpenAddModal = 'Open "Add" modal',
  OpenEditModal = 'Open "Edit" modal',
  InlineEditSuccess = 'Inline edit success',
  InlineEditFail = 'Inline edit fail',
  AddSuccess = 'Add success',
  AddFail = 'Add fail',
  EditSuccess = 'Edit success',
  EditFail = 'Edit fail',
  SaveSegment = 'Save segment',
  UpdateSegment = 'Update segment',
  ClearFilters = 'Clear filters',
  PublicReportCustomizeReport = 'Public report customize report',
  PublicReportResetLink = 'Public report reset link',
  PublicReportCopyLink = 'Public report copy link',
  PublicReportCopyOldVersionLink = 'Public report copy old version link',
  OpenDomainsComparisonModal = 'Open "DomainsComparison" modal',
  RegisterPageVerifyPhone = 'Register page verify phone',
  RegisterPageActivate = 'Register page activate',
  IntegrationsMenu = 'Integrations menu toggle',
}

// For Filter events. Tracking key send to mixPanel.
export enum FilterTrackingKey {
  DiscoveryQuickAction = 'Discovery quick action',
  TableHeader = 'Table header',
  FilterBar = 'Filter bar',
  SerpPopover = 'SERP popover',
  SearchIntentChart = 'Search intent chart',
  ShareOfVoiceChart = 'Share of voice chart',
  GSCKeywordsTable = 'GSCKeywordsTable',
  WordCloud = 'Word cloud',
  SiteExplorerToKeywords = 'Navigating from site explorer to keywords',
  SiteExplorerToDiscovery = 'Navigating from site explorer to discovery',
  TagCloudToKeywords = 'Navigating from tag cloud to keywords',
  LandingPagesToKeywords = 'Navigating from landing pages to keywords',
  LandingPagesToDiscovery = 'Navigating from landing pages to keywords',
  PublicReport = 'Public report',
  PublicReportKeywordsSearch = 'Public report keywords search',
}

export interface ExtraProps {
  [key: string]: string | boolean | number;
}

const convertProps = (properties?: ExtraProps): AccurankerAnalyticsOtherPropertyInput[] =>
  Object.entries(properties ?? {}).map(([key, value]) =>
    (['string', 'number', 'boolean'].includes(typeof value)
      ? { key, value }
      : { key, value: `${value}` }),
  );

export const useMixpanel = () => {
  const [mixpanelTrackEventMutation] = useMixpanelTrackEventMutation();
  const location = useLocation();
  const params = useParams();
  const routePath = getRoutePath(location?.pathname, params);
  const domainId = useDomainId();
  const isGroupView = useIsGroupDomains();

  const trackEvent = (eventName: string, properties?: ExtraProps) => {
    try {
      const input: AccurankerAnalyticsInput = {
        eventName,
        browser: browserName,
        browserVersion,
        deviceType,
        landingPage: routePath ?? '',
        os: osName,
        screenHeight: window?.window?.screen?.height ?? -1,
        screenWidth: window?.window?.screen?.width ?? -1,
        windowHeight: window?.innerHeight ?? -1,
        windowWidth: window?.innerWidth ?? -1,
        fp: window?.fp,
        ...(domainId ? { domainId: `${domainId}` } : {}),
        isGroupView,
        otherProperties: convertProps(properties),
      };
      mixpanelTrackEventMutation({ variables: { input } });
    } catch (err) {
      // noop
    }
  };

  return trackEvent;
};

// Non-functional version
// eslint-disable-next-line import/no-unused-modules
export const trackEventMixpanel = (
  eventName: EventName,
  routePath?: string,
  properties?: ExtraProps,
): void => {
  try {
    const input: AccurankerAnalyticsInput = {
      eventName,
      browser: browserName,
      browserVersion,
      deviceType,
      landingPage: routePath ?? '',
      os: osName,
      screenHeight: window?.window?.screen?.height ?? -1,
      screenWidth: window?.window?.screen?.width ?? -1,
      windowHeight: window?.innerHeight ?? -1,
      windowWidth: window?.innerWidth ?? -1,
      domainId: properties?.domainId ? `${properties?.domainId}` : '?',
      otherProperties: convertProps(properties),
    };
    apolloClient.mutate({ mutation: MixpanelTrackEventDocument, variables: { input } });
  } catch (err) {
    // noop
  }
};

/**
 * Utility method to track filter event when clicking a `<Link>` with a specific filter in the `to` path.
 *
 * @param path - Absolute path including filters
 * @param trackingKey - FilterTrackingKey
 * @param filterToTrack - FilterAttribute of the filter to track for this event
 */
export const trackFilterEventWithNavigation = (
  path: string,
  trackingKey: FilterTrackingKey,
  filterToTrack: FilterAttributetype,
) => {
  const slugArray = trimTrailingSlash(path).split('/');
  const filterSlug = slugArray[slugArray.length - 1];
  const filtersList = decodeFilters(filterSlug);
  //get the filter we want to track in the path
  const filter = filtersList.filters.find((f) => f.attribute === filterToTrack);
  filter &&
    trackEventMixpanel(EventName.AddFilter, undefined, {
      Attribute: `${filter.attribute}`,
      Type: filter.type,
      Comparison: filter.comparison,
      Value: `${filter.value}`,
      'Tracking Key': trackingKey,
    });
};
