import { IconDeviceDesktop, IconDeviceMobile } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { DomainTypeChoices } from 'Query';
import { t } from 'Utilities/i18n';
import type { SearchEngine } from '../../Modal/Content/AddSearchEngine/types';
import {
  SEARCH_TYPE_BAIDU,
  SEARCH_TYPE_NAVER,
  SEARCH_TYPE_YANDEX,
  SEARCH_TYPE_YOUTUBE,
  SearchEngineTypes,
} from '../../Modal/Content/AddSearchEngine/types';
import SearchEngineInput from './SearchEngineInput';
import './search-settings-input.scss';

type Props = {
  searchEngines?: SearchEngine[];
  value: SearchEngine[];
  onChange: (...args: Array<any>) => any;
  showBaidu?: boolean;
  showYouTube?: boolean;
  showNaver?: boolean;
  locale?: any;
  youTubeDomain: boolean;
  hideMode?: boolean;
  domainType?: DomainTypeChoices;
};

const getDisabledReason = (
  key: string,
  isYouTubeDomain: boolean,
  domainTypeViolation: boolean,
  disabledSinceNaverIsChosen: boolean | undefined,
) => {
  if (key === SEARCH_TYPE_YANDEX) {
    return t('This search engine is no longer supported.');
  }
  if (key === SEARCH_TYPE_YOUTUBE && !isYouTubeDomain) {
    return t('To add YouTube as a search engine you need to add the channel as a domain.');
  }
  if (domainTypeViolation) {
    return t('Mixed search engines are not supported for this domain.');
  }
  if (disabledSinceNaverIsChosen) {
    return t('Mixed search engines are not supported for Naver.');
  }
  return t('This search engine is not supported for the selected locale.');
};

export const getSearchEngines = (
  isShowBaidu: boolean,
  isShowYouTube: boolean,
  isShowNaver: boolean,
): string[] =>
  Object.keys(SearchEngineTypes)
    .filter((key) => key !== SEARCH_TYPE_YANDEX) // never show Yandex
    .filter((key) => {
      if (!isShowBaidu && key === SEARCH_TYPE_BAIDU) {
        return false;
      } else if (!isShowYouTube && key === SEARCH_TYPE_YOUTUBE) {
        return false;
      } else if (!isShowNaver && key === SEARCH_TYPE_NAVER) {
        return false;
      }
      return true;
    });

// eslint-disable-next-line import/no-unused-modules
export const SearchSettingsInput = (props: Props) => {
  const {
    value,
    locale,
    hideMode = false,
    showBaidu = false,
    showNaver = false,
    showYouTube = false,
    youTubeDomain = false,
    domainType,
  } = props;

  const handleSearchEngineChange = (newSearchEngine: SearchEngine) => {
    props.onChange(
      props.value?.map((searchEngine) => {
        if (newSearchEngine.name === SEARCH_TYPE_NAVER && searchEngine.name !== SEARCH_TYPE_NAVER) {
          searchEngine.searchTypes = [];
          return searchEngine;
        }

        return searchEngine.name === newSearchEngine.name ? newSearchEngine : searchEngine;
      }),
    );
  };

  return (
    <div className="search-settings-input">
      <div className="search-type-icons">
        <AccTooltip tooltip={t('Desktop')}>
          <IconDeviceDesktop className="icon" />
        </AccTooltip>
        <AccTooltip tooltip={t('Mobile')}>
          <IconDeviceMobile className="icon" />
        </AccTooltip>
      </div>
      {getSearchEngines(showBaidu, showYouTube, showNaver).map((key) => {
        const index = value.findIndex((se) => se.name === key);
        let isSupport = locale.searchEngines?.findIndex((v) => v.name === key) > -1;
        // "temp" Yandex ban - ARR-2242, ARR-4049
        isSupport = isSupport && key !== SEARCH_TYPE_YANDEX;
        if (!isSupport && hideMode) {
          return null;
        }

        const disabledSinceNaverIsChosen =
          value.find((x) => x.name === SEARCH_TYPE_NAVER && x.searchTypes.length > 0) &&
          key !== SEARCH_TYPE_NAVER;

        const domainTypeViolation =
          key !== SEARCH_TYPE_NAVER && domainType === DomainTypeChoices.A_7;

        return (
          <SearchEngineInput
            disabled={!isSupport || domainTypeViolation || disabledSinceNaverIsChosen}
            disabledReason={getDisabledReason(
              key,
              youTubeDomain,
              domainTypeViolation,
              disabledSinceNaverIsChosen,
            )}
            key={key}
            value={
              index > -1
                ? value[index]
                : ({
                    id: SearchEngineTypes[key],
                    name: key,
                    searchTypes: [],
                  } as SearchEngine)
            }
            onChange={handleSearchEngineChange}
          />
        );
      })}
    </div>
  );
};
