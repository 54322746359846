import { MouseEvent, useEffect, useRef, useState } from 'react';
import { ApolloCache, useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import AccTitle from 'Components/Title/AccTitle';
import {
  ImportValidationPageImportDocument,
  ImporterImportStatusChoices,
  useImportValidationPageClientsQuery,
  useImportValidationPageImportQuery,
  useValidationImportRedirectLazyQuery,
} from 'Ghql';
import toast from 'Hooks/useToast';
import {
  SubscriptionDataImportOtherType,
  SubscriptionDataType,
  IMPORT as importSubscriptionObject,
} from 'Types/Subscription';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { t, tct } from 'Utilities/i18n/index';
import { SubscriptionHandle, subscribeToImport } from 'Utilities/websocket';
import reusableStyles from 'css/reusable-styles.module.scss';
import {
  ImportValidationStatusCompleted,
  ImportValidationStatusError,
  ImportValidationStatusNew,
  ImportValidationStatusProcessing,
  ImportValidationStatusValidating,
} from './Status';
import { UploadImport } from './types';

enum Constants {
  DAYS_BEFORE_FILE_EXPIRES = 14,
  STATUS_NEW = ImporterImportStatusChoices.A_0,
  STATUS_VALIDATING = ImporterImportStatusChoices.A_1,
  STATUS_VALIDATION_ERROR = ImporterImportStatusChoices.A_2,
  STATUS_PROCESSING = ImporterImportStatusChoices.A_3,
  STATUS_COMPLETED = ImporterImportStatusChoices.A_4,
  NUMBER_OF_STEPS = 4,
}

const ImportTitle = (props: { uploadImport: UploadImport }) => {
  const { uploadImport } = props;

  const id = uploadImport.id;
  const fileHref = uploadImport.file;

  const [importRedirect] = useValidationImportRedirectLazyQuery();

  const fileName = decodeURIComponent(fileHref.split('/').pop() ?? '');

  const fileAge = moment().diff(moment(uploadImport.createdAt), 'days');
  const fileExpired = fileAge > Constants.DAYS_BEFORE_FILE_EXPIRES;

  const onClick = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    const result = await importRedirect({
      variables: {
        id: uploadImport.id,
      },
    });

    if (result.error) {
      toast.error('Something went wrong. Please try again later.');
      Sentry.captureException(result.error);
      return;
    }

    const redirectUrl = result.data?.importRedirect?.redirectUrl || null;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return fileExpired ? (
    <AccTooltip tooltip={t('Files are stored for 14 days')}>
      <AccTitle type="h1">
        #{id} ({fileName})
      </AccTitle>
    </AccTooltip>
  ) : (
    <>
      <AccTitle type="h1" inline>
        #{id}&nbsp;
      </AccTitle>
      <AccTitle type="h1" variant="link" onClick={onClick} inline>
        ({fileName})
      </AccTitle>
    </>
  );
};
1;
const CurrentStep = (props: { uploadImport: UploadImport }) => {
  const textByStatus = new Map([
    [Constants.STATUS_NEW.toString(), t('Setup')],
    [Constants.STATUS_VALIDATING.toString(), t('Validating')],
    [Constants.STATUS_VALIDATION_ERROR.toString(), t('Failed')],
    [Constants.STATUS_PROCESSING.toString(), t('Importing')],
    [Constants.STATUS_COMPLETED.toString(), t('Completed')],
  ]);

  const stepByStatus = new Map([
    [Constants.STATUS_NEW.toString(), 1],
    [Constants.STATUS_VALIDATING.toString(), 2],
    [Constants.STATUS_PROCESSING.toString(), 3],
    [Constants.STATUS_COMPLETED.toString(), 4],
  ]);

  const {
    uploadImport: { status, progress },
  } = props;

  const currentStep = stepByStatus.get(status);
  let statusText = textByStatus.get(status);
  if (
    progress === 0 &&
    (status === Constants.STATUS_VALIDATING.toString() ||
      status === Constants.STATUS_PROCESSING.toString())
  ) {
    statusText = tct('In queue ([statusText])', { statusText });
  }

  return (
    <AccTitle type="h2">
      {status === Constants.STATUS_VALIDATION_ERROR.toString()
        ? statusText
        : tct('Step [currentStep] of [steps] - [statusText]', {
            currentStep,
            steps: Constants.NUMBER_OF_STEPS,
            statusText,
          })}
    </AccTitle>
  );
};

const useUploadImport = () => {
  const match = useRouteMatch();
  const importID = match.params?.id;
  const { data, refetch } = useImportValidationPageImportQuery({
    variables: { id: String(match.params?.id) },
    skip: !importID || !match.params?.id,
  });
  return { uploadImport: data?.uploadImport, refetch };
};

const useClients = () => {
  const { data } = useImportValidationPageClientsQuery();
  return data?.clients;
};

const handleImportSubscriptionData = (
  subscriptionData: SubscriptionDataType,
  uploadImport: UploadImport,
  cache: ApolloCache<object>,
  refetchUploadImport: () => void,
  setSubscriptionDataTime: (time: string) => void,
) => {
  const statusByInt = new Map([
    [0, Constants.STATUS_NEW],
    [1, Constants.STATUS_VALIDATING],
    [2, Constants.STATUS_VALIDATION_ERROR],
    [3, Constants.STATUS_PROCESSING],
    [4, Constants.STATUS_COMPLETED],
  ]);

  const { data } = subscriptionData;
  if (data.obj === importSubscriptionObject && uploadImport?.id === data.id && data.other) {
    const {
      time,
      progress,
      status: statusInt,
      rankProcessingStartedAt,
      rankProcessingLastRowIdx,
      rankProcessingResumedAt,
      rankProcessingResumedRankIdx,
      rankProcessingTotalRows,
    } = data.other as SubscriptionDataImportOtherType;
    const status = statusByInt.get(statusInt);
    cache.writeQuery({
      query: ImportValidationPageImportDocument,
      data: {
        uploadImport: {
          ...uploadImport,
          progress,
          status,
          rankProcessingStartedAt,
          rankProcessingLastRowIdx,
          rankProcessingResumedAt,
          rankProcessingResumedRankIdx,
          rankProcessingTotalRows,
        },
      },
    });
    setSubscriptionDataTime(time);
    if (status === Constants.STATUS_VALIDATION_ERROR) {
      refetchUploadImport(); // To get validation errors.
    }
  }
};

const ValidationComponent = () => {
  const { uploadImport, refetch } = useUploadImport();
  const clients = useClients();

  const _subHandler = useRef<SubscriptionHandle>();

  const { cache } = useApolloClient();

  const [subscriptionDataTime, setSubscriptionDataTime] = useState<string | null>(null);

  useEffect(() => {
    _subHandler.current = subscribeToImport(
      (subscriptionData) =>
        uploadImport &&
        handleImportSubscriptionData(
          subscriptionData,
          uploadImport,
          cache,
          refetch,
          setSubscriptionDataTime,
        ),
    );
    return () => _subHandler.current?.unsubscribe();
  }, [uploadImport, cache, refetch]);

  const status = uploadImport?.status;
  return (
    <>
      <Breadcrumbs
        customParentTitle={t('Import')}
        customParentLink="/import"
        customTitle={t('File Import')}
      />
      <div className={reusableStyles.paperContainer}>
        {uploadImport && clients && (
          <>
            <ImportTitle uploadImport={uploadImport} />
            <CurrentStep uploadImport={uploadImport} />
            {(status === Constants.STATUS_NEW.toString() && (
              <ImportValidationStatusNew uploadImport={uploadImport} clients={clients} />
            )) ||
              (status === Constants.STATUS_VALIDATING.toString() && (
                <ImportValidationStatusValidating uploadImport={uploadImport} />
              )) ||
              (status === Constants.STATUS_VALIDATION_ERROR.toString() && (
                <ImportValidationStatusError uploadImport={uploadImport} />
              )) ||
              (status === Constants.STATUS_PROCESSING.toString() && (
                <ImportValidationStatusProcessing
                  uploadImport={uploadImport}
                  subscriptionDataTime={subscriptionDataTime}
                />
              )) ||
              (status === Constants.STATUS_COMPLETED.toString() && (
                <ImportValidationStatusCompleted />
              ))}
          </>
        )}
      </div>
    </>
  );
};

export default ValidationComponent;
