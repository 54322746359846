import { Center, Group } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';

type Props = {
  isConnected: boolean;
  description: string;
};

const ConnectedLabel = ({ isConnected, description }: Props) => (
  <Group gap="xs">
    {isConnected && (
      <Center c="green.4">
        <IconCheck size={20} stroke={4} />
      </Center>
    )}
    <AccText fs="italic">{description}</AccText>
  </Group>
);

export default ConnectedLabel;
