import { useMemo } from 'react';
import CheckboxGroup, { ValueLabel } from 'Components/Controls/CheckboxGroup';
import { t } from 'Utilities/i18n';

type SearchEngineSettings = {
  ignoreLocalResults: boolean;
  ignoreFeaturedSnippet: boolean;
  enableAutocorrect: boolean;
};

type Props = {
  value: SearchEngineSettings;
  onChange: (...args: Array<any>) => any;
  disabled?: boolean;
  showGoogleOptions: boolean;
};

const getItems = (): ValueLabel[] => [
  {
    value: 'ignoreLocalResults',
    label: t('Ignore local pack'),
    hint: t(
      'If checked, any local pack found on the SERP will not be included in the rank position.',
    ),
  },
  {
    value: 'ignoreFeaturedSnippet',
    label: t('Ignore featured snippet'),
    hint: t('Ignore the featured snippet that is often shown on how/what searches.'),
  },
  {
    value: 'enableAutocorrect',
    label: t('Enable autocorrect'),
    hint: t('Enable Google Autocorrect for this keyword.'),
  },
];

const DEFAULT_SETTINGS = {
  ignoreLocalResults: false,
  ignoreFeaturedSnippet: false,
  enableAutocorrect: false,
};

export const SearchEngineSettingsField = ({
  value,
  onChange,
  disabled = false,
  showGoogleOptions,
}: Props) => {
  const handleChange = (properties: string[]) => {
    const newValue = properties.reduce(
      (accumulator, property) => {
        accumulator[property] = true;
        return accumulator;
      },
      { ...DEFAULT_SETTINGS },
    );
    onChange(newValue);
  };

  const items = useMemo(getItems, []);

  const selectedProperties = Object.keys(value).filter((field) => value[field]);

  return (
    <CheckboxGroup
      disabled={!showGoogleOptions || disabled}
      className="add-keywords-settings"
      value={selectedProperties}
      onChange={handleChange}
      items={items}
    />
  );
};
