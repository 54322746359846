import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { IconChevronsUp, IconPlugConnected } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { STEPS } from 'Components/Modal/Wizard/Base/Account';
import { ACCOUNT, PROPERTY } from 'Components/Modal/Wizard/ConnectToGA';
import { universalAnalyticsIdPrefix } from 'Constants';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import {
  removeAdobeAccountQuery,
  removeGoogleAccountQuery,
} from 'Pages/DomainSettings/support/queries';
import { t } from 'Utilities/i18n';

type AnalyticsIntegrationButtonsProps = {
  refresh?: () => void;
};

export const AnalyticsIntegrationButtons = ({
  refresh,
}: AnalyticsIntegrationButtonsProps): JSX.Element => {
  const domainId = useDomainId();
  const { domainInfo, refetch: refreshDomainInfo, isDemoDomain } = useQueryDomainInfo();
  const disabledDemoText = getDisabledDemoText();
  const { showModal, hideModal } = useModal();
  const isConnected = Boolean(domainInfo?.isGAConnected || domainInfo?.isAdobeMarketingConnected);

  const [removeAdobeAccount] = useMutation(removeAdobeAccountQuery);
  const [removeGAAccount] = useMutation(removeGoogleAccountQuery);

  const handleRefresh = useCallback(() => {
    refresh?.();
    if (domainId) refreshDomainInfo();
  }, [refreshDomainInfo, refresh, domainId]);

  const handleConnectToAnalytics = () =>
    showModal({
      modalType: 'ConnectToAnalytics',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: handleRefresh,
      },
    });

  const handleUpgradeToGA4 = () => {
    showModal({
      modalType: 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        domainId,
        skipToStep: PROPERTY,
        skippedData: {
          [ACCOUNT]: { accountId: domainInfo?.gaAccountId },
          [STEPS.SELECT]: { connectionId: domainInfo?.googleOauthConnectionGa?.id },
        },
      },
    });
  };

  const handleDisconnectFromAnalytics = () => {
    let removeAccount;
    if (domainInfo?.isAdobeMarketingConnected) {
      removeAccount = removeAdobeAccount;
    } else if (domainInfo?.isGAConnected) {
      removeAccount = removeGAAccount;
    }

    removeAccount &&
      removeAccount({ variables: { input: { domainId } } }).then(
        () => {
          toast.success(t('Account removed'));
          handleRefresh();
          hideModal();
        },
        () => {
          toast.error(t('Failed to remove account'));
        },
      );
  };

  return (
    <>
      {domainInfo?.gaPropertyId?.startsWith(universalAnalyticsIdPrefix) && (
        <AccButton
          onClick={handleUpgradeToGA4}
          leftSection={<IconChevronsUp size={18} />}
          variant="secondary"
          mr="xxs"
          disabled={isDemoDomain}
          tooltip={isDemoDomain ? disabledDemoText : undefined}
        >
          {t('Upgrade to GA4')}
        </AccButton>
      )}
      {!isConnected ? (
        <AccButton
          onClick={handleConnectToAnalytics}
          leftSection={<IconPlugConnected size={18} />}
          variant="secondary"
          disabled={isDemoDomain}
          tooltip={isDemoDomain ? disabledDemoText : undefined}
        >
          {t('Connect to Analytics')}
        </AccButton>
      ) : (
        <AccButton
          onClick={handleDisconnectFromAnalytics}
          leftSection={<IconPlugConnected size={18} />}
          variant="secondary"
          disabled={isDemoDomain}
          tooltip={isDemoDomain ? disabledDemoText : undefined}
        >
          {t('Disconnect from Analytics')}
        </AccButton>
      )}
    </>
  );
};
