import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';

type Props = {
  message: string;
  onSubmit: (...args: Array<any>) => any;
};

const ConnectedAccount = ({ message, onSubmit }: Props) => {
  return (
    <div>
      <p>{message}</p>
      <div className="form-actions">
        <AccButton variant="primary" onClick={onSubmit}>
          {t('Close')}
        </AccButton>
      </div>
    </div>
  );
};

export default ConnectedAccount;
