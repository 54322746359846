import * as React from 'react';
import CheckBox from 'Components/Controls/Checkbox';
import { DESKTOP, MOBILE, SearchType } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Baidu from 'icons/baidu.svg?inline';
import Bing from 'icons/bing.svg?inline';
import Google from 'icons/google.svg?inline';
import Naver from 'icons/naver.svg?inline';
import Yandex from 'icons/yandex.svg?inline';
import YouTube from 'icons/youtube.svg?inline';
import type { SearchEngine } from '../../../Modal/Content/AddSearchEngine/types';
import {
  SEARCH_TYPE_BAIDU,
  SEARCH_TYPE_BING,
  SEARCH_TYPE_GOOGLE,
  SEARCH_TYPE_NAVER,
  SEARCH_TYPE_YANDEX,
  SEARCH_TYPE_YOUTUBE,
} from '../../../Modal/Content/AddSearchEngine/types';
import './search-type-input.scss';

type Props = {
  value: SearchEngine;
  onChange: (se: SearchEngine) => void;
  disabled?: boolean;
  disabledReason?: string;
};
const icons = {
  [SEARCH_TYPE_GOOGLE]: Google,
  [SEARCH_TYPE_BING]: Bing,
  [SEARCH_TYPE_BAIDU]: Baidu,
  [SEARCH_TYPE_YANDEX]: Yandex,
  [SEARCH_TYPE_YOUTUBE]: YouTube,
  [SEARCH_TYPE_NAVER]: Naver,
};

const SearchTypeInput = ({ value, disabled, disabledReason, onChange }: Props) => {
  const handleDesktopChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    const searchTypes = value.searchTypes;
    let newSearchTypes: SearchType[];

    if (checked) {
      newSearchTypes = [...searchTypes, DESKTOP];
    } else {
      newSearchTypes = searchTypes.filter((searchType) => searchType !== DESKTOP);
    }

    onChange({ ...value, searchTypes: newSearchTypes });
  };

  const handleMobileChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    const searchTypes = value.searchTypes;
    let newSearchTypes;

    if (checked) {
      newSearchTypes = [...searchTypes, MOBILE];
    } else {
      newSearchTypes = searchTypes.filter((searchType) => searchType !== MOBILE);
    }

    onChange({ ...value, searchTypes: newSearchTypes });
  };

  const isMobile = value.searchTypes.indexOf(MOBILE) !== -1;
  const isDesktop = value.searchTypes.indexOf(DESKTOP) !== -1;
  const mobileSupport = true;
  const desktopSupport = true;
  const Icon = icons[value.name];

  return (
    <div className="search-type-input">
      <span className="search-engine-name">
        <Icon className="icon" /> {value.name.replace('youtube', 'youTube')}
      </span>
      <div className="search-engine-checkboxes">
        <CheckBox
          disabled={desktopSupport ? disabled : true}
          checked={isDesktop}
          onChange={handleDesktopChange}
          name={`search-engine-${value.name}-${isMobile ? 'mobile' : 'desktop'}`}
          tooltip={
            disabled
              ? disabledReason
              : desktopSupport
              ? undefined
              : t('Desktop is not supported by this search engine')
          }
        />
        <CheckBox
          disabled={mobileSupport ? disabled : true}
          checked={isMobile}
          onChange={handleMobileChange}
          tooltip={
            disabled
              ? disabledReason
              : mobileSupport
              ? undefined
              : t('Mobile is not supported by this search engine')
          }
        />
      </div>
    </div>
  );
};

export default SearchTypeInput;
