import { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Flex, Group } from '@mantine/core';
import { updateDefaultCompareTo } from 'Actions/FilterAction';
import { updateUserSettings } from 'Actions/UserAction';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import { Field, Form } from 'Components/Fields';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';
import { getButtonsConfigs } from 'Components/PeriodFilter/buttons';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useEditProfileUserSocialConnectionsQuery, useEditUserProfileMutation } from 'Ghql';
import { useIs2faEligibleUser } from 'Hooks/data/organization/useOrganizationInfo';
import toast from 'Hooks/useToast';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n/index';
import Validator, { Errors } from 'Utilities/validation';
import { getUserTypeDescription, getUserTypeLabel } from 'Utilities/workspaces';
import GoogleDriveAccount from '../GoogleDriveAccount';
import IntegrationsApiToken from '../IntegrationsApiToken';
import TwoFactor from './two-factor';
import { SocialButtons } from './utils';

export const PRESENT_MODE_COOKIE = 'ar_present_mode';
export const ENABLE_NEW_FEATURES_COOKIE = 'ar_dev';

const EditProfile = () => {
  const languageOptions = [
    {
      value: 'da',
      label: t('Danish'),
    },
    {
      value: 'en',
      label: t('English'),
    },
  ];
  const defaultCompareToOptions = getButtonsConfigs().map(({ id, label }) => ({
    value: id,
    label,
  }));
  const defaultKeywordsPageOptions = [
    {
      value: 'overview',
      label: t('Overview'),
    },
    {
      value: 'keywords',
      label: t('Keywords'),
    },
    {
      value: 'competitors',
      label: t('Competitors'),
    },
    {
      value: 'competitors_ranking',
      label: t('Competitors ranking'),
    },
    {
      value: 'landing_pages',
      label: t('Landing pages'),
    },
    {
      value: 'tags',
      label: t('Tags'),
    },
    {
      value: 'notes',
      label: t('Notes'),
    },
  ];

  const {
    data: userProfileData,
    loading,
    refetch,
  } = useEditProfileUserSocialConnectionsQuery({
    fetchPolicy: 'network-only',
  });

  const isAccurankerUser = useIs2faEligibleUser();
  const [presentMode, setPresentMode] = useState(false);
  const dispatch = useDispatch();

  const [performEditProfile] = useEditUserProfileMutation();

  const { user, defaultCompareTo } = useSelector((state: StoreType) => ({
    user: state.user,
    defaultCompareTo: state.filter.defaultCompareTo,
  }));

  useEffect(() => {
    const currentPresentMode = cookie.load(PRESENT_MODE_COOKIE);
    setPresentMode(!!currentPresentMode);
  }, []);

  const handleSubmit = async ({
    fullName,
    language,
    email,
    defaultKeywordsPage,
    defaultCompareTo: compareTo,
  }) => {
    const lang = language.value || language;
    const updateMyUserInput = {
      fullName,
      email: user.email!,
      language: lang,
      defaultKeywordsPage: defaultKeywordsPage.value || defaultKeywordsPage,
      defaultCompareTo: compareTo.value || compareTo,
      resetApiToken: false,
    };
    if (compareTo.value !== defaultCompareTo) {
      dispatch(updateDefaultCompareTo(compareTo.value));
    }
    return performEditProfile({
      variables: {
        updateMyUserInput,
      },
    }).then(({ data }) => {
      const errors = data?.updateMyUser?.errors;
      if (errors && errors.length) {
        toast.error(t('Something went wrong'));
        Validator.setResponseErrors(Validator.throwSubmissionError, errors as Errors);
      } else {
        toast.success(t('Profile saved'));
        // update user settings store on success
        dispatch(
          updateUserSettings({
            fullName,
            email,
            language: lang,
            defaultKeywordsPage: defaultKeywordsPage.value || defaultKeywordsPage,
            defaultCompareTo: compareTo.value || compareTo,
          }),
        );
      }
    });
  };

  const enableDevelopmentMode = () => {
    cookie.save(ENABLE_NEW_FEATURES_COOKIE, 'true', {
      path: '/',
    });
    window.location.reload();
  };

  const togglePresentMode = () => {
    const currentMode = cookie.load(PRESENT_MODE_COOKIE);
    if (currentMode) {
      cookie.remove(PRESENT_MODE_COOKIE, {
        path: '/',
      });
      setPresentMode(false);
    } else {
      cookie.save(PRESENT_MODE_COOKIE, 'true', {
        path: '/',
      });
      setPresentMode(true);
    }
    window.location.reload();
  };

  const userLabel = getUserTypeLabel(user.userType);
  const userTooltip = getUserTypeDescription(user.userType);
  const isAllGroups = user.workspaces?.some((role) => role?.isAllGroups);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          fullName: user.fullName,
          email: user.email,
          language: user.language,
          defaultKeywordsPage: user.defaultKeywordsPage,
          defaultCompareTo: user.defaultCompareTo,
        }}
      >
        {({ submitting, invalid }) => (
          <Flex direction="column" gap="md">
            <Field.TextInput
              required
              label={t('Full Name')}
              name="fullName"
              placeholder={t('Enter full name')}
              validate={Validator.required}
            />
            <Field.TextInput
              required
              label={t('Email')}
              name="email"
              type="email"
              disabled
              placeholder={t('Enter your email')}
              validate={[Validator.required, Validator.email]}
            />
            <Field.Select
              required
              name="language"
              label={t('Language')}
              placeholder={t('Select language')}
              options={languageOptions}
              searchable={false}
              validate={[Validator.required]}
            />
            <Field.Select
              required
              name="defaultKeywordsPage"
              label={t('Default keywords page')}
              placeholder={t('Select page')}
              options={defaultKeywordsPageOptions}
              searchable={false}
              validate={[Validator.required]}
            />
            <Field.Select
              required
              name="defaultCompareTo"
              label={t('Default "compare to" parameter')}
              placeholder={t('Select default comparer')}
              options={defaultCompareToOptions}
              searchable={false}
              validate={[Validator.required]}
            />

            <AccTitle type="h3">{t('Integrations')}</AccTitle>
            <div>
              <AccessControl withSuperuserPermission>
                <IntegrationsApiToken />
              </AccessControl>
              <GoogleDriveAccount />
            </div>

            {isAccurankerUser && (
              <>
                <AccTitle type="h3" id={'2FA'}>
                  {t('Two-Factor Authentication (2FA)')}
                </AccTitle>
                <TwoFactor has2FA={!!userProfileData?.user?.hasOtpDevice} loading={loading} />
              </>
            )}

            <AccTitle type="h3">{t('Permissions')}</AccTitle>
            <Group gap="lg" mb="md">
              <Group gap="2">
                <AccText size="md">{userLabel}</AccText>
                <HelpTextPopover text={userTooltip} />
              </Group>
              {isAllGroups && (
                <>
                  <Divider orientation="vertical" />
                  <AccText size="md">{t('All Groups')} </AccText>
                </>
              )}
            </Group>
            <AccTitle type="h3">{t('Social Authentication')}</AccTitle>
            <SocialButtons
              isConnectedGoogle={!!userProfileData?.user?.socialAuths?.google}
              isConnectedLinkedIn={!!userProfileData?.user?.socialAuths?.linkedin}
              isConnectedMicrosoft={!!userProfileData?.user?.socialAuths?.microsoft}
              isConnectedFacebook={!!userProfileData?.user?.socialAuths?.facebook}
              loading={loading}
              refetch={refetch}
            />

            <div className="confirmation-button-wrapper text-right">
              <AccButton disabled={invalid || submitting} type="submit" variant="primary">
                {t('Update')}
              </AccButton>
            </div>
          </Flex>
        )}
      </Form>
      {(user as any).salesManager && (
        <Flex gap="sm" direction="column">
          <AccButton variant="secondary" onClick={enableDevelopmentMode}>
            {t('Enable development mode')}
          </AccButton>
          <AccButton
            variant={presentMode ? 'destructive' : 'secondary'}
            onClick={togglePresentMode}
          >
            {presentMode ? t('Disable present mode') : t('Enable present mode')}
          </AccButton>
        </Flex>
      )}
    </>
  );
};

export default EditProfile;
