import { useApolloClient } from '@apollo/client';
import { clearEverything } from 'Actions/ResetAction';
import { AccSelect, SelectItem } from 'Components/AccSelect';
import { LinkedAccountsOrganizationsDocument, LinkedAccountsOrganizationsQuery } from 'Ghql';
import { useActions } from 'Hooks/redux/useActions';
import toast from 'Hooks/useToast';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import styles from './linked-account-select.module.scss';

type LinkedAccountOption = SelectItem<string> & { multiAccountLink?: { id: string } };

export const LinkedAccountSelect = () => {
  const client = useApolloClient();
  const actions = useActions({ clearEverything });

  const trackEvent = useMixpanel();

  const getOptions = (query: string | undefined) => {
    return client
      .query<LinkedAccountsOrganizationsQuery>({
        query: LinkedAccountsOrganizationsDocument,
        variables: {
          searchQuery: query,
        },
      })
      .then(
        ({ data }) =>
          (data?.linkedOrganizations?.map((organization) => ({
            value: organization?.id,
            label: organization?.name,
            ...organization,
          })) as LinkedAccountOption[]) ?? [],
      );
  };

  const onChange = (_, option: LinkedAccountOption) => {
    if (option.multiAccountLink) {
      trackEvent(EventName.ChangeAccountDropdown);
      actions.clearEverything();
      redirectToExternalUrl(`/org/multiaccount/change/${option?.multiAccountLink?.id}/`);
    } else {
      toast.error(t('You are already on the selected account.'));
    }
  };
  return (
    <AccSelect<LinkedAccountOption>
      name="linked-account"
      onChange={onChange}
      loadOptions={getOptions}
      searchable
      clearable={false}
      placeholder={t('Change account')}
      nothingFoundText={t('No results found')}
      searchPromptText={t('Type to search')}
      dropdownPosition="top"
      size="sm"
      minQueryLength={0}
      w="100%"
      bg="snorlax"
      className={styles.linkedAccountSelect}
      clearOptionsOnBlur={false}
      groupHidden
      showResultsForEmptySearch
    />
  );
};
