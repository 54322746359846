import { memo, useMemo } from 'react';
import LinkToKeywordListCell from 'Components/Cell/LinkToKeywordListCell';
import { useFolderOrTagFilter } from 'Components/Filters/LinkWithFolders/linkWithFolders';
import { DomainsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { propsIsEqualComparison } from 'Utilities/compare';
import { getDomainId, getFolderPathByDepth } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { BaseCellProps } from '../../utils/useFoldersTableConfig';

const KeywordsCell = <T extends BaseCellProps>(props: T) => {
  const isDomain = 'is_domain' in props.rootNode && props.rootNode.is_domain;
  const isGroup = 'is_group' in props.rootNode && props.rootNode.is_group;
  const isFolder = props.rootNode.is_folder;
  const isTag = !isFolder && !isGroup && !isDomain;

  const domainId = props.rootNode.isDomainsComparison
    ? getDomainId(props.rootNode.path)
    : undefined;

  const listOfDomains = isGroup
    ? props.rootNode.children?.map((domainNode) => getDomainId(domainNode.path))
    : undefined;

  const domainsFilter: DomainsFilter[] | undefined = domainId
    ? [
        {
          attribute: FilterAttribute.DOMAINS,
          type: FilterValueType.LIST,
          comparison: FilterComparison.CONTAINS,
          value: [domainId],
        },
      ]
    : listOfDomains
    ? [
        {
          attribute: FilterAttribute.DOMAINS,
          type: FilterValueType.LIST,
          comparison: FilterComparison.CONTAINS,
          value: listOfDomains,
        },
      ]
    : [];

  const foldersOrTagFilter = useFolderOrTagFilter({
    isFolder: props.rootNode.is_folder,
    folderOrTag: props.rootNode.is_folder
      ? getFolderPathByDepth(props.rootNode.path, props.rootNode.depth)
      : props.rootNode.name,
  });

  const tooltipText = useMemo(() => {
    switch (true) {
      case isFolder:
        return t('Go to keywords list and filter by folder');
      case isTag:
        return t('Go to keywords list and filter by tag');
      default:
        return t('Go to keywords list');
    }
  }, [isFolder, isTag]);

  return (
    <LinkToKeywordListCell
      props={props}
      filters={[...(isFolder || isTag ? [foldersOrTagFilter] : []), ...domainsFilter]}
      value={props.rootNode.keywords}
      tooltip={tooltipText}
    />
  );
};

export default memo(KeywordsCell, propsIsEqualComparison);
